var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"container"},[_c('h3',{staticClass:"title mb-4"},[_vm._v("Control de accesso")]),_c('h5',{staticClass:"sub-title mb-0"},[_vm._v("Accesos por rol")]),_c('p',{staticClass:"text-primary"},[_vm._v("Selecciona un perfil para editar su acceso al catálogo")]),(_vm.loadingRoles && !_vm.roles.length)?_c('div',{staticClass:"text-center"},[_c('loader',[_c('p',[_vm._v("Cargando roles...")])])],1):(_vm.roles.length)?_c('div',{staticClass:"row mb-4"},_vm._l((_vm.roles),function(role,index){return _c('div',{key:("role_" + index + "_" + (role.id)),staticClass:"col-12 col-md-6 col-lg-4 p-3"},[_c('div',{staticClass:"box"},[_c('div',{staticClass:"role"},[_c('img',{staticClass:"role__img",attrs:{"src":['/img/icon-rol-even.png', '/img/icon-rol-odd.png'][index%2],"alt":""}}),_c('span',{staticClass:"role__title px-2"},[_vm._v(" "+_vm._s(role.name)+" ")]),_c('router-link',{staticClass:"btn btn-sm btn-complementary ms-auto ",attrs:{"to":{name: 'access-management-role', params: {id: role.id} }}},[_c('span',{staticClass:"small"},[_vm._v(" Permisos "),_c('i',{staticClass:"fas fa-angle-right"})])])],1)])])}),0):_vm._e(),_c('h5',{staticClass:"sub-title mb-0"},[_vm._v("Accesos por centro de costos")]),_c('p',{staticClass:"text-primary"},[_vm._v("Selecciona un centro de costos para editar su acceso al catálogo de manera específica")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12  p-3"},[_c('form',{staticClass:"box",on:{"submit":function($event){$event.preventDefault();return _vm.goToEditPermissions.apply(null, arguments)}}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"col-6 flex-grow-1 p-1"},[_c('autocomplete',{staticClass:"mb-0",attrs:{"label":"Centro de costos","name":"ccosto_search","selected-label":function (option) {
									return((option.ccosto) + " - " + (option.name))
								},"options":_vm.ccostos,"filter":function (options, query) {
									var filtered = options.filter(function (o) {
										var ccosto = o.ccosto.toString().toLowerCase()
										var name  = o.name.toLowerCase()
										var match = false
										query = query.toLowerCase()
										
										if(!match) {
											match = ccosto.includes(query)
										}
										if(!match) {
											match = name.includes(query)
										}

										return match
									})

									return filtered
								}},scopedSlots:_vm._u([{key:"option",fn:function(ref){
								var option = ref.option;
return [_vm._v(" "+_vm._s(option.ccosto)+" - "+_vm._s(option.name)+" ")]}}]),model:{value:(_vm.selectedCcosto),callback:function ($$v) {_vm.selectedCcosto=$$v},expression:"selectedCcosto"}})],1),_vm._m(0)])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('button',{staticClass:"btn  btn-complementary",attrs:{"type":"submit"}},[_vm._v("Continuar "),_c('i',{staticClass:"fas fa-angle-right"})])])}]

export { render, staticRenderFns }