<script>
export default {
	props: {
		canClose: {
			type: Boolean,
			default() {
				return true
			}
		}
	},

	data() {
		return {
			show: false,
			closing: false,
		}
	},

	methods: {
		open() {
			this.closing = false
			this.show = true
			this.$emit('open')
		},

		close() {
			
			if(!this.canClose) {
				return
			}
			let vm = this
			vm.closing = true
			setTimeout(() => {
				vm.show = false
				vm.$emit('close')
				
			}, 500)

			
		}
	}
}
</script>

<template>
	<div class="app-modal" :class="{'app-modal--closing': closing}" v-if="show" @click="close()">
		<div class="app-modal__wrapper" @click.stop="">
			<div class="app-modal__header">
				<slot name="header">
					Header
				</slot>
				<button class="app-modal__close btn btn-sm btn-transparent" v-if="canClose" @click="close()">
					<i class="far fa-times-circle"></i>
				</button>
			</div>
			<div class="app-modal__body">
				<slot name="body" :close="close">
					Body
				</slot>
			</div>
		</div>
	</div>
</template>