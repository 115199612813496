

export const user = (state) => {
	return state.user
}

export const roles = (state) => {
	if(state.user) {
		return state.user.roles || []
	}

	return []
}


export const hasPermission = (state) => (roles) => {
	if(!state.user) {
		return false
	}
	if(!roles) {
		return true
	}
	let permission = false
	
	roles.forEach(role => {
		let roles = state.user.roles || []
		
		if(permission) {
			return
		}

		permission = roles.includes(role)
	})

	return permission
}