<script>
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import http from '../http'
import moment from 'moment'
import ModalVue from '../components/ModalVue.vue'
import Loader from '../components/Loader.vue'
import FormControl from '../components/FormControl.vue'
import DateRange from '../components/DateRange.vue';


export default {
    components: {
        DateRange,
        FormControl,
        Loader,
        ModalVue,
        FullCalendar // make the <FullCalendar> tag available
    },
    data() {
        return {
            form: {
                target_id: '',
                order_type: 10,

            },
            errors: {
                target_id: '',
                order_type: '',

            },
            date: [
				moment().toDate(),
				moment().add(1).toDate()
			],
            calendarOptions: {
                lang: 'es',
                locale: 'es',
                firstDay: 1,
                aspectRatio: 1.9,
                plugins: [ dayGridPlugin, interactionPlugin ],
                initialView: 'dayGridMonth',
                eventClick: this.hadlerEvent,
                events: [],
                buttonText: {
                    today:    'Hoy',
                    month:    'month',
                    week:     'week',
                    day:      'day',
                    list:     'list'
                }
            },
            type: {
                type: null,
                id: null
            },
            events: [],
            divisionals: [],
            roles: [],
            savingPeriod: false,
            period: null,
        }
    },
    methods: {
        async fetchPeriords() {
            let mv = this
                let data = await http.orders.periodsList({
                    since: moment().startOf('year').format('Y-MM-DD'),
                    until: moment().endOf('year').format('Y-MM-DD'),
                })

                let events = []
                let backgroundColor = ["#1abc9c", "#2ecc71", "#3498db", "#9b59b6", "#34495e", "#16a085", "#27ae60", "#2980b9", "#8e44ad", "#2c3e50", "#f1c40f", "#e67e22", "#e74c3c", "#95a5a6", "#f39c12", "#d35400", "#c0392b", "#bdc3c7", "#7f8c8d"]
                data.order_periods.forEach((period, index) => {
                        let type = this.getType(period.order_type)

                        if(period.target) {
                            events.push({ 
                                id: period.id,
                                allDay : true,
                                backgroundColor: backgroundColor[index],
                                title: `${type} ${period.target.divisional ? period.target.divisional.name : period.target.name}`, 
                                start: moment(period.since).startOf('day').toDate(), end: moment(period.until).endOf('day').add(1).toDate()
                            })
                        }
                })

                this.$set(this.calendarOptions, 'events', events)
                this.$set(this, 'events', data.order_periods)
        },

        async fetchDivisionals() {
            let vm = this

            try {
                let data = await http.users.divisionals()

                vm.$set(vm, 'divisionals', data)
                vm.$set(vm, 'type', {
                    type: 'divisional',
                    id: data[0].id
                })
            } catch (error) {
                
            }
        },

        async fetchRoles() {
            let vm = this

            try {
                let data = await http.roles.setPeriod()

                vm.$set(vm, 'roles', data)
            } catch (error) {
                
            }
        },

        async createPeriod() {
            let vm = this
            let data = {
                order_type: this.form.order_type,
                since: moment(this.date[0]).format('Y-MM-DD'),
                until: moment(this.date[1]).format('Y-MM-DD') 
            }
            if(vm.type.id) {
                if(vm.type.type == 'divisional') {
                    data.divisional_id = vm.type.id
                } else {
                    data.role_id = vm.type.id
                }
            }

            vm.savingPeriod = true

            try {
                let response = await http.orders.periodsCreate(data)
                let periods = await vm.fetchPeriords()
                vm.savingPeriod = false
                vm.$notify({
					group: 'b_r',
					title: '',
					text: 'Periodo creado',
					type: 'success'
				});
                vm.$refs.newPeriodModal.close()

            } catch (error) {
                vm.savingPeriod = false
                 vm.$notify({
					group: 'b_r',
					title: '',
					text: 'No pudimos crear el periodo',
					type: 'error'
				});
                vm.$refs.newPeriodModal.close()
            }
        },

        async updatePeriod() {
            let vm = this
            let data = {
                order_type: this.form.order_type,
                since: moment(this.date[0]).format('Y-MM-DD'),
                until: moment(this.date[1]).format('Y-MM-DD') 
            }
            if(vm.period) {
                if(vm.period.target_type == 'App\\User') {
                    data.divisional_id = vm.period.target_id
                } else {
                    data.role_id = vm.period.target_id
                }
            }

            vm.savingPeriod = true

            try {
                let response = await http.orders.periodsUpdate(vm.period.id, data)
                let periods = await vm.fetchPeriords()
                vm.savingPeriod = false
                vm.$notify({
					group: 'b_r',
					title: '',
					text: 'Periodo actualizado',
					type: 'success'
				});
                vm.$refs.periodModal.close()

            } catch (error) {
                vm.savingPeriod = false
                 vm.$notify({
					group: 'b_r',
					title: '',
					text: 'No pudimos crear el periodo',
					type: 'error'
				});
                vm.$refs.periodModal.close()
            }
        },

        async deletePeriod() {
            let vm = this
            vm.savingPeriod = true
            try {
                let response = await http.orders.deletePeriod(this.period.id)
                let periods = await vm.fetchPeriords()
                vm.savingPeriod = false
                vm.$notify({
					group: 'b_r',
					title: '',
					text: 'Periodo eliminado',
					type: 'success'
				});
                vm.$refs.periodModal.close()
            } catch (error) {
                vm.savingPeriod = false
                vm.$notify({
					group: 'b_r',
					title: '',
					text: 'No pudimos crear el periodo',
					type: 'error'
				});
                vm.$refs.periodModal.close()
            }
        },

        getType(type) {
            switch (type) {
                case 10:
                    return  'Productos'
                    break;
                default:
                    return 'Tarjetas'
                    break;
            }
        },

        clearError() {

        },

        hadlerEvent(info) {
            let event = this.events.find(e => e.id == info.event.id)
            this.$set(this, 'period', event)
            this.$set(this, 'date', [
                moment(event.since).toDate(),
                moment(event.until).toDate(),
            ])
            this.$refs.periodModal.open()
        },
        openNewPeriod() {
            this.$refs.newPeriodModal.open()
        }

    },
    mounted() {
        this.fetchPeriords()
        this.fetchDivisionals()
        this.fetchRoles()
    }
}
</script>
<template>
    <div class="container">
        <modal-vue ref="periodModal" >
            <template v-slot:header>
                <h5 v-if="period" class="sub-title m-0">
                   Período {{period.order_type == 10 ? 'Productos' : 'Tarjetas'}} {{ period.target.name}}
                </h5>
            </template>
            <template v-slot:body={close}>
                <div v-if="!savingPeriod && period" style="width: 600px">
                    <div class="d-flex flex-wrap">
                        <div class="col-12 py-3">
                            <DateRange v-model="date" label="Desde/Hasta"/>
                        </div>
                    </div>
					<div class="d-flex py-3 mt-3 justify-content-center">
						<button @click="close()" class="ms-auto btn btn-transparent btn-sm text-danger">Cancelar</button>
						<button @click="deletePeriod" class="btn btn-danger btn-sm text-white mx-1">Eliminar</button>
						<button @click="updatePeriod" class="me-auto btn btn-primary btn-sm">Guardar</button>
					</div>
				</div>
				<div v-else-if="savingPeriod" class="d-flex justify-content-center">
					<loader>
						<p class="m-0">Realizando acción...</p>
					</loader>
				</div>

			</template>
		</modal-vue>
        <modal-vue ref="newPeriodModal" >
            <template v-slot:header>
                <h5 class="sub-title m-0">
                   Nuevo Período
                </h5>
            </template>
            <template v-slot:body={close}>
                <div v-if="!savingPeriod" style="width: 600px">
                    <div class="d-flex flex-wrap">
                        <div class="col-12 py-3">
                            <form-control
                                label="Tipo usuario:"
                                name="type"
                                @input="clearError('type')"
                                :error="errors.target_id"
                                v-model="type"
                                type="select"
                            >

                                <option v-for="(divisional, index) in divisionals" :value="{type: 'divisional', id: divisional.id}" :key="`divisional-${index}`">Divisional {{divisional.name}}</option>
                                <option v-for="(role, index) in roles" :value="{type: 'role', id: role.id}" :key="`role-${index}`">Rol {{role.name}}</option>
                            </form-control>
                        </div>
                        <div class="col-12 py-3">
                            <form-control
                                label="Tipo de pedido:"
                                name="type"
                                @input="clearError('type')"
                                :error="errors.order_type"
                                v-model="form.order_type"
                                type="select"
                            >

                                <option value="10">Productos</option>
                                <option value="15">Tarjetas de presentación</option>
                                <!-- <option v-for="(divisional, index) in divisionals" :value="divisional.id" :key="`divisional-${index}`">{{divisional.name}}</option> -->
                            </form-control>
                        </div>
                        <div class="col-12 py-3">
                            <DateRange v-model="date" label="Desde/Hasta"/>
                        </div>
                    </div>
					<div class="d-flex py-3 mt-3">
						<button @click="close()" class="ms-md-auto btn btn-transparent btn-sm text-danger">Cancelar</button>
						<button @click="createPeriod" class="me-auto btn btn-primary btn-sm">Guardar</button>
					</div>
				</div>
				<div v-else-if="savingPeriod" class="d-flex justify-content-center">
					<loader>
						<p class="m-0">Creando periodo...</p>
					</loader>
				</div>

			</template>
		</modal-vue>
        <div class="d-flex align-items-center mb-3">
			<div>
				<h3 class="title mb-0">Agenda</h3>
			</div>
			<div class="ms-auto">
				<button class="btn btn-sm btn-primary btn-sm fw-bold text-white text-uppercase ms-2" @click="openNewPeriod">
					Agregar Nuevo Período <i class="far fa-calendar-alt"></i>
				</button>
			</div>
		</div>
        <div class="box pt-3">
            <FullCalendar locale="es" :options="calendarOptions" />
        </div>
    </div>
</template>
<style lang="scss">
.fc{
    a {
        text-decoration: none !important;
    }
}
</style>