<script>
// TODO: Exportar reporte
import { mapActions, mapGetters } from 'vuex'
import DragDropFile from '../components/DragDropFile.vue'
import Loader from '../components/Loader.vue'
import ModalVue from '../components/ModalVue.vue'
import DateRange from '../components/DateRange.vue';
import Autocomplete from '../components/Autocomplete.vue'
import Multiselect from 'vue-multiselect'
import FormControl from '../components/FormControl.vue'

import moment from 'moment'
import http from '../http'
export default {
	components: {
		Multiselect,
		DragDropFile,
		ModalVue,
		Loader,
		DateRange,
		Autocomplete,
		FormControl
	},
	data() {
		return {
			loading: true,

			paginate: ['users', 'usersTrash'],
			
			pagination: null,
			page: 1,

			productsList: [],
			users: [],
			userSelected: {},

			saving: false,

			date: [
				moment().startOf('month').toDate(),
				moment().endOf('month').toDate()
			],
			
			selectedCcosto: null,
			selectedProduct: {},
            divisionals: [],
            orders: [],
			selectedDivisional: 0,
			selectedOrder: 0,
			selectedRole: 0,
			roles: [
				{
					slug: 'corporativo',
					name: 'Corporativo',
					key: 5,
				},
				{
					slug: 'atusuarios',
					name: 'At. Usuarios',
					key: 8,
				},
				{
					slug: 'reclutadores',
					name: 'Reclutadores',
					key: 7,
				},
				{
					slug: 'mac',
					name: 'MAC',
					key: 4,
				},
			],

			savingNumbersOrder: false,
			dataNumbersOrder: null,
			currentNumbersOrder: [],
			newNumbersOrder: [],
			extraProducts: [],
			with_extra: false,
			invoice: null,
			pathInvoice: null,

			listed: []
		}
	},

	computed: {
		
		...mapGetters({
			ccostos: 'app/ccostos',
			// users: 'app/users',
			products: 'app/products',
		})
	},

	methods: {
        async fetchDivisionals() {
            let vm = this

            try {
                let data = await http.users.divisionals()
                vm.$set(vm, 'divisionals', data)
            } catch (error) {
                console.log(error)
            }
        },
		async fetchUsers() {
			let vm = this
			vm.loading = true
			try {
				let data = await http.users.all()
				// vm.$set(vm, 'pagination', data)
				// vm.loading = false
				vm.$set(vm, 'users', data)
				
				// this.fetchUsersTrash()
			} catch (error) {
				vm.loading = false
			}
		},

		async fetchReport() {
			let vm = this
			let data = {}
			vm.loading = true
// ?since=2021-04-01&until=2021-08-31&page=2ccosto=2&page=1&product_id=2
			data.since = moment(this.date[0]).format('Y-MM-DD')
			data.until = moment(this.date[1]).format('Y-MM-DD')
			data.with_extra = this.with_extra ? 1 : 0
			data.page = this.page
			
			if(this.userSelected.id) {
				data.ccosto = this.userSelected.id
			}

			if(this.selectedProduct.id) {
				data.product_id = this.selectedProduct.id
			}

			try {
				
				let response = await http.products.report(data)
				response.data.forEach(o => {
					
					o.order_numbers = o.order_numbers?  o.order_numbers.split(',') : []
				});
                vm.$set(vm, 'orders', response.data)
				vm.setExtraProducts()
                delete response.data
				vm.$set(vm, 'pagination', response)
				vm.loading = false

			} catch (error) {
				console.log(error)
				vm.loading = false

			}
		},

		async saveExtraProducts() {
			let vm = this
			let products = JSON.stringify(this.extraProducts)
			products = JSON.parse(products)
			let data = {
				products
			}

			this.saving = true

			try {
				let response = await http.products.extra(data)
				
				this.saving = false
				this.$refs.extraProductsModal.close()
				vm.$notify({
					group: 'b_r',
					title: '',
					text: 'Almacenado',
					type: 'success'
				});
				this.fetchReport()
			} catch (error) {
				
				this.saving = false
				this.$refs.extraProductsModal.close()
				vm.$notify({
					group: 'b_r',
					title: '',
					text: 'Algo salio mal',
					type: 'error'
				});

			}
		},

		setExtraProducts() {
			let extra = []
			
			this.orders.forEach(o => {
				
				o.extra_products.forEach(ep => {
					
					let find = extra.find(e => e.id == ep.id)

					if(find) {
						find.pivot.quantity += ep.pivot.quantity
						return						
					}
					let data = JSON.stringify(ep)
					data = JSON.parse(data)

					if(!data.supplier_description) {
						data.supplier_description = data.name
					}
					extra.push(data)
				})
			})

			this.$set(this, 'extraProducts', extra)
		},
		withoutDivisionalRole() {
			let vm = this
			let orders = this.orders.filter(order => {
				let byRole = false
				let byDivisional = false

				vm.roles.forEach(role => {
					if(!byRole) {
						let roles_in = order.order_ccosto.ccosto.roles

						if(roles_in.includes('admin') && role.slug == 'corporativo') {
							byRole = true
							return 
						}


						byRole = roles_in.includes(role.slug)
					}
				})
				
				vm.divisionals.forEach(divisional => {
					if(!byDivisional && order.order_ccosto.divisional) {
						byDivisional = order.order_ccosto.divisional.id == divisional.id
					}
				})



				if(byRole || byDivisional) {
					return false
				}

				return true
			}) 

			return orders

		},
        divisionalOrders(id) {
			let vm = this
            let orders = this.orders.filter(order => {
				let byRole = false

				vm.roles.forEach((role) => {
					if(!byRole) {
						byRole = order.order_ccosto.ccosto.roles.includes(role.slug)
					}
				})

				if(byRole) {
					return false
				}

                if(!order.order_ccosto.divisional) {
                    return false
                } 

                if(id == order.order_ccosto.divisional.id) {
                    return true
                }

                return false
            })

            return orders
        },

        roleOrders(role) {
			let vm = this
            let orders = this.orders.filter(order => {
				if(order.id == 224) {
					console.log(order.order_ccosto.ccosto.roles)
					debugger
				}
				let roles_in = order.order_ccosto.ccosto.roles

				if(roles_in.includes('admin') && role.slug == 'corporativo') {
					return true
				}

                return roles_in.includes(role.slug)
            })

            return orders
        },

        orderNumber(id) {
            return window.pad(id, 6)
        },
		
		selectDivisional(id) {
			this.selectedDivisional = id
			this.selectedRole = 0
			this.selectedOrder = 0

		},

		selectRole(key) {
			this.selectedDivisional = 0
			this.selectedRole = key
		},

		openOrderNumber(group, type) {
			let data = {}
			data.type = type
			data.group = group
			data.title = group.name
			if(type == 'divisional') {
				let orders = this.divisionalOrders(group.id)
				data.ordersID = orders.map(o => {
					return o.id
				})
				if(orders.length) {
					let orderNumbers = JSON.stringify(orders[0].order_numbers)

					this.$set(this, 'currentNumbersOrder', JSON.parse(orderNumbers))
					this.$set(this, 'newNumbersOrder', JSON.parse(orderNumbers))
					this.pathInvoice = orders[0].invoice
				} else {
					this.$set(this, 'currentNumbersOrder', [])
					this.$set(this, 'newNumbersOrder', [])

				}
			} else if(type == 'role') {

				let orders = this.roleOrders(group)
				data.ordersID = orders.map(o => {
					return o.id
				})
				if(orders.length) {
					let orderNumbers = JSON.stringify(orders[0].order_numbers)

					this.$set(this, 'currentNumbersOrder', JSON.parse(orderNumbers))
					this.$set(this, 'newNumbersOrder', JSON.parse(orderNumbers))
				} else {
					this.$set(this, 'currentNumbersOrder', [])
					this.$set(this, 'newNumbersOrder', [])

				}
			}
			this.$set(this, 'dataNumbersOrder', data)
			this.$refs.numbersOrderModal.open()
		},

		addTag (newTag) {
			const tag = newTag
			// this.currentNumbersOrder.push(tag)
			this.newNumbersOrder.push(tag)
			let vm = this
			this.$nextTick(() => {
				vm.$refs.selectorNumber.toggle()
			})
		},

		async setOrderNumbers() {
			let data = {
				orders_numbers: this.newNumbersOrder,
				orders_id: this.dataNumbersOrder.ordersID
			}

			let payload = new FormData()

			payload.append('orders_numbers', JSON.stringify(this.newNumbersOrder))
			payload.append('orders_id', JSON.stringify(this.dataNumbersOrder.ordersID))
			payload.append('file_invoice', this.invoice)


			this.savingNumbersOrder = true

			try {
				let response = await http.orders.setOrderNumbers(payload)
				this.savingNumbersOrder = false
				this.fetchReport()
				this.$refs.numbersOrderModal.close()
			} catch (error) {
				this.savingNumbersOrder = false
				this.$refs.numbersOrderModal.close()
				
			}
		},
		async downloadReport (orders, title) {
			
			let orders_id = orders.map(o => o.id)
			let suppliers_id = []
			

			orders.forEach((o) => {
				o.products.forEach(p => {
					console.log(p)
					if(!suppliers_id.includes(p.supplier_id) && p.supplier_id) {
						suppliers_id.push(p.supplier_id)
					}
				})
			})
			console.log(suppliers_id)
			let vm = this
			let data = {
				since: moment(this.date[0]).format('Y-MM-DD'),
				until: moment(this.date[1]).format('Y-MM-DD'),
				orders_id,
				suppliers_id,
				title: `${title}_${moment(this.date[0]).format('Y-MM-DD')}_${moment(this.date[1]).format('Y-MM-DD')}`
			} 
			if(this.userSelected.id) {
				data.ccosto = this.userSelected.id
			}

			if(this.selectedProduct.id) {
				data.product_id = this.selectedProduct.id
			}
			try {
				let response = await http.suppliers.reportOrders(data)
				
				if(response.data.files.length) {
					response.data.files.forEach(url => {
						vm.downloadFile(url)
					})
				}
			} catch (error) {
				
			}
		},
		downloadFile(url) {
			window.open(url)
		},
		errorLoadImage(event) {
			event.target.src = '/img/missing.png'
		},
		selectOrder(id) {
			if(this.selectedOrder != id) {
				this.selectedOrder = id
			} else {
				this.selectedOrder = 0
			}
		},
		openExtraProductsModal() {
			this.setExtraProducts()
			this.$refs.extraProductsModal.open()
		},
		updateInvoice(event) {
			let file = event.target.files

			if(file.length) {
				this.$set(this, 'invoice', file[0])
			} else {
				this.$set(this, 'invoice', null)
			}
		},
 		...mapActions({
			fetchProducts: 'app/fetchProducts'
		})

		// setPage(page) {
		// 	this.page = page
		// 	this.fetchEmployees()
		// },
		// onChangeFile(file) {
		// 	if(file.length) {
		// 		this.$refs.modalUpload.open()
		// 	}
		// },

		

		// resetFiles() {
		// 	this.$refs.dragDrop.reset()
		// }
	},

	mounted() {
		let vm = this
        this.fetchDivisionals()
		this.fetchUsers()
		this.fetchProducts().then(res => {
			let products = JSON.stringify(vm.products)
				products = JSON.parse(products)
			
			products.unshift({id: 0, name: 'Todos los productos'})
			vm.$set(vm, 'productsList', products)
		})

		this.fetchReport()
	}
	
}
</script>
<template>
	<div class="container">
		<div class="d-flex align-items-center mb-3">
			<div>
				<h3 class="title mb-0">Productos Solicitados</h3>
			</div>
			<div class="ms-auto">
				
			</div>
			<!-- <div class="ms-auto container-header__control">
				<drag-drop-file ref="dragDrop" v-model="files" @change="onChangeFile">
					<p class="m-0 text-center">Actualizar plantilla <i class="fas fa-sync-alt"></i></p>
				</drag-drop-file>
			</div> -->
		</div>

		<modal-vue ref="extraProductsModal">
			<template v-slot:header>
				<h5 class="sub-title m-0">Verificar Información</h5>
			</template>
			<template v-slot:body={close}>
				<div v-if="!saving">
					<!-- <pre>{{selectedProduct}}</pre> -->
					<div class=" py-2">
						
						<div class="alert alert-warning text-center" role="alert">
							Verifica la Información de los productos fuera de catálogo.
						</div>
						<div >

							<table class="table"> 
								<thead>
									<tr>
										<th>Nombre</th>
										<th>Cantidad</th>
										<th>Descripcion proveedor</th>
										<th>Unidad de medida</th>
										<th>Codigo proveedor</th>
										<th>Proveedor</th>
										<th>Precio</th>
									</tr>

								</thead>
								<tbody>
									<tr v-for="(product, index) in extraProducts" :key="`exp_${index}`">
										<td>
											{{  product.name }}
										</td>
										<td>
											{{  product.pivot.quantity }}
										</td>
										<td>
											<FormControl
												label=""
												name="supplier_description"
												v-model="product.supplier_description"
												type="text"
											/>
										</td>
										<td>
											<form-control
											type="select"
											label=""
											v-model="product.supplier_um"
											>
												<option value=""></option>
												<option value="BUL">BUL</option>
												<option value="CAJ">CAJ</option>
												<option value="JGO">JGO</option>
												<option value="KGR">KGR</option>
												<option value="PAQ">PAQ</option>
												<option value="PZA">PZA</option>
												<option value="TUB">TUB</option>
											</form-control>
										</td>
										<td>
											<FormControl
												label=""
												name="supplier_code"
												v-model="product.supplier_code"
												type="text"
											/>
										</td>
										<td>
											<FormControl
												label=""
												name="supplier_code"
												v-model="product.supplier_name"
												type="text"
											/>
										</td>
										<td>
											<FormControl
												label=""
												name="supplier_price"
												v-model="product.supplier_price"
												type="number"
											/>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
					<div class="d-flex py-2 border-top mx-n3 px-3">
						<button @click="close()" class=" btn btn-transparent btn-sm ms-auto">Cancelar</button>
						<button   @click="saveExtraProducts"  class=" btn btn-primary text-white">Guardar</button>
					</div>
					<!-- <h6 class="text-center">¿Seguro desea  inhabilitar a {{userSelected.first_name}}?</h6>
					<div class="d-flex">
						<button @click="close()" class="ms-md-auto btn btn-transparent btn-sm">Cancelar</button>
						<button @click="disableUser" class="me-auto btn btn-danger btn-sm text-white">Si, continuar</button>
					</div> -->
				</div>
				<div v-else-if="saving" class="d-flex justify-content-center">
					<loader>
						<p class="m-0">Agregando...</p>
					</loader>
				</div>
			</template>
		</modal-vue>
		<!-- <pre>{{users}}</pre> -->
		<div v-if="!loading">
			<!-- <pre>{{products}}</pre> -->
			<div class="box p-0" v-if="!loading">
				<div class="d-flex flex-wrap position-relative align-items-center" style="z-index: 3">
					<div class="col-12 col-md-6 col-lg-3 p-3">
						<DateRange v-model="date" label="Desde/Hasta" @input="fetchReport"/>
					</div>
					<div class="col-6 col-md-6 col-lg-4 p-3">
						<autocomplete
							class="mb-0"
							label="Usuarios"
							name="ccosto_search"
							v-model="userSelected"
							@input="fetchReport"
							:selected-label="(option) => {
								return`${option.ccosto} - ${option.name}`
							}"
							:options="users"
							:filter="(options, query) => {
								let filtered = options.filter(o => {
									let ccosto = o.ccosto.toString().toLowerCase()
									let name  = o.name.toLowerCase()
									let match = false
									query = query.toLowerCase()
									
									if(!match) {
										match = ccosto.includes(query)
									}
									if(!match) {
										match = name.includes(query)
									}

									return match
								})

								return filtered
							}"
							>
							<template v-slot:option="{option}">
								{{ option.ccosto }} - {{option.name}}
							</template>
						</autocomplete>
					</div>
					<!-- <div class="col-6 col-md-6 col-lg-4 p-3">
						
					</div> -->
					<div class="col-6 col-md-6 col-lg-3 p-3">
						<autocomplete
							class="mb-0"
							label="Productos"
							name="ccosto_search"
							v-model="selectedProduct"
							@input="fetchReport"
							:selected-label="(option) => {
								return`${option.name}`
							}"
							:options="productsList"
							:filter="(options, query) => {
								let filtered = options.filter(o => {
									let name  = o.name.toLowerCase()
									let match = false
									query = query.toLowerCase()
									
									if(!match) {
										match = name.includes(query)
									}

									return match
								})

								return filtered
							}"
							>
							<template v-slot:option="{option}">
								 {{option.name}}
							</template>
						</autocomplete>
					</div>
					<div class="col-6 col-md-6 col-lg-2 p-3">
						<label>
							<input type="checkbox" name="with_extra" v-model="with_extra" @change="fetchReport">
							Con productos extra
						</label>
					</div>
				</div>


				<div v-if="extraProducts.length" class="p-2">
					<div class="alert alert-warning text-center" role="alert">
						<p class="m-0">
							Estas ordenes contienen productos extras. 
						</p>
						<button class="btn btn-warning fw-bold text-uppercase btn-sm" @click="openExtraProductsModal">
							<span class="small">
								COMPLETAR Información
							</span>
						</button>
					</div>
				</div>

				<div class="table-container" v-if="orders.length">
					<table class="table">
						<thead>
							
						</thead>
						<tbody>
							<template  v-for="(divisional, index) in divisionals" >
								<tr :key="`divisional_${index}_${divisional.id}`"  v-if="divisionalOrders(divisional.id).length">
									<th colspan="7" class="py-2 px-2 border-bottom">
										<div class="d-flex align-items-center cursor-pointer" @click="selectDivisional(divisional.id)">
											<button class="btn btn-primary btn-sm">
												<i class="fas fa-chevron-down th-arrow" :class="{'th-arrow-open': divisional.id == selectedDivisional}"></i>
											</button>
											<span>
												{{divisional.ccosto}} - {{divisional.name}} 
											</span>
											<span @click.stop="" v-if="divisionalOrders(divisional.id)[0].order_numbers.length" class="ms-2">
												| Orden: 
												<a v-if="divisionalOrders(divisional.id)[0].invoice" target="blank" :href="divisionalOrders(divisional.id)[0].invoice" class="badge badge-warning fw-bold">{{divisionalOrders(divisional.id).length ? divisionalOrders(divisional.id)[0].order_numbers[0] : ''}} <i class="fa fa-download" aria-hidden="true"></i></a>
												<span v-else class="badge badge-warning fw-bold">{{divisionalOrders(divisional.id).length ? divisionalOrders(divisional.id)[0].order_numbers[0] : ''}} </span>
											</span>
											<span class="ms-auto">
												{{divisionalOrders(divisional.id).length}} PEDIDOS
											</span>
											<button v-if="!selectedProduct.hasOwnProperty('id') && !userSelected.hasOwnProperty('id')" @click.stop="openOrderNumber(divisional, 'divisional')" class="btn btn-sm btn-complementary text-black fw-bold ms-2">Número  de Orden</button>
											<button @click.stop="downloadReport(divisionalOrders(divisional.id), divisional.ccosto)" class="btn btn-sm btn-success text-white fw-bold ms-2">Descargar reporte</button>
										</div>
									</th>
								</tr>
								<tr v-if="divisionalOrders(divisional.id).length && divisional.id == selectedDivisional" :key="`divisional_h1_${index}_${divisional.id}`">
									<th class="th-second" colspan="2"></th>
									<th class="th-second text-center border-start" colspan="2">Solicitante</th>
									<th class="th-second text-center border-start" colspan="3">Destino</th>
								</tr>
								<tr v-if="divisionalOrders(divisional.id).length && divisional.id == selectedDivisional" :key="`divisional_h2_${index}_${divisional.id}`">
									<th class="th-second">Fecha de Solicitud</th>
									<th class="th-second">Nº de Pedido</th>
									<th class="th-second border-start">CCosto</th>
									<th class="th-second">Nombre</th>
									<th class="th-second border-start">CCosto Asignado</th>
									<th class="th-second">Dirección destino</th>
									<th class="th-second">Responsables</th>
								</tr>
								<template v-for="(order, index) in (divisionalOrders(divisional.id))">
									<tr :class="{'bg-soft-warning': order.extra_products.length}" v-if="divisional.id == selectedDivisional" :key="`order_${order.id}_${index}`" @click="selectOrder(order.id)">
										<td>
											{{ order.ordered_at | moment('DD/MM/Y') }}
										</td>
										<td>
											<router-link
											:to="{name: 'admin-orders-detail', params: {id:  order.id}}"
											>
												{{ orderNumber(order.id) }}
											</router-link>
										</td>
										<td>
											{{ order.user.ccosto }}
										</td>
										<td>
											{{ order.user.name }}
										</td>
										<td>
											{{ order.ccosto_assigned }}
										</td>
										<td>
											{{ order.address }}
										</td>
										<td>
											<p class="m-0" :title="`Telefono: ${order.phone}`">{{ order.responsable }}</p>
											<p class="m-0" :title="`Telefono: ${order.phone_2}`">{{order.responsable_2}}</p>
										</td>
									</tr>
									<tr  v-if="order.id == selectedOrder && divisional.id == selectedDivisional" :key="`order_${order.id}_${index}_p`">
										<td colspan="7">
											<div class="d-flex flex-wrap" >
												<div class="col-12 col-md-6 col-lg-4 col-xl-3 p-1 " v-for="(product, index) in order.products" :key="`product_${order.id}_${index}_${product.id}`">
													<div class="order_product d-flex py-2 h-100">
														
														<div class="order_product_info px-2">
															<h6 class="fw-bold">
																{{ product.name }}
															</h6>
															<p>
																CANTIDAD: {{product.pivot.quantity}}
															</p>
														</div>
													</div>
												</div>
												
											</div>
											
											<div class="d-flex flex-wrap" v-if="order.extra_products.length">
												<div class="col-12 pt-3">
													<h6 class="title m-0">Productos fuera de catálogo</h6>
												</div>
												<div class="col-12 col-md-6 col-lg-4 col-xl-3 p-1 " v-for="(product, index) in order.extra_products" :key="`extra_product_${order.id}_${index}_${product.id}`">
													<div class="order_product d-flex py-2 h-100">
														
														<div class="order_product_info px-2">
															<h6 class="fw-bold">
																{{ product.name }}
															</h6>
															<p>
																CANTIDAD: {{product.pivot.quantity}}
															</p>
														</div>
													</div>
												</div>
												
											</div>
										</td>
									</tr>
								</template>
							</template>
							<template  v-for="(role, index) in roles" >
								<tr :key="`divisional_${index}_${role.key}`"  v-if="roleOrders(role).length">
									<th colspan="7" class="py-2 px-2 border-bottom">
										<div class="d-flex align-items-center cursor-pointer" @click="selectRole(role.key)">
											<button class="btn btn-primary btn-sm">
												<i class="fas fa-chevron-down th-arrow" :class="{'th-arrow-open': role.key == selectedRole}"></i>
											</button>
											<span>
												{{role.name}}
											</span>
											<span @click.stop="" v-if="roleOrders(role)[0].order_numbers.length" class="ms-2">
												| Orden: 
												<a v-if="roleOrders(role)[0].invoice" target="blank" :href="roleOrders(role)[0].invoice" class="badge badge-warning fw-bold">{{roleOrders(role).length ? roleOrders(role)[0].order_numbers[0] : ''}} <i class="fa fa-download" aria-hidden="true"></i></a>
												<span v-else class="badge badge-warning fw-bold">{{roleOrders(role).length ? roleOrders(role)[0].order_numbers[0] : ''}}</span>
											</span>
											<span class="ms-auto">
												{{roleOrders(role).length}} PEDIDOS
											</span>
											<button v-if="!selectedProduct.hasOwnProperty('id') && !userSelected.hasOwnProperty('id')" @click.stop="openOrderNumber(role, 'role')" class="btn btn-sm btn-complementary text-black fw-bold ms-2">Número  de Orden</button>
											<button @click.stop="downloadReport(roleOrders(role), role.name)" class="btn btn-sm btn-success text-white fw-bold ms-2">Descargar reporte</button>
										</div>
									</th>
								</tr>
								<tr v-if="roleOrders(role).length && role.key == selectedRole" :key="`divisional_h1_${index}_${role.key}`">
									<th class="th-second" colspan="2"></th>
									<th class="th-second text-center border-start" colspan="2">Solicitante</th>
									<th class="th-second text-center border-start" colspan="3">Destino</th>
								</tr>
								<tr v-if="roleOrders(role).length && role.key == selectedRole" :key="`divisional_h2_${index}_${role.key}`">
									<th class="th-second">Fecha de Solicitud</th>
									<th class="th-second">Nº de Pedido</th>
									<th class="th-second border-start">CCosto</th>
									<th class="th-second">Nombre</th>
									<th class="th-second border-start">CCosto Asignado</th>
									<th class="th-second">Dirección destino</th>
									<th class="th-second">Responsable</th>
								</tr>
								<template v-for="(order, index) in (roleOrders(role))">
									<tr :class="{'bg-soft-warning': order.extra_products.length}"  v-if="role.key == selectedRole" :key="`order_${order.id}_${index}`" @click="selectOrder(order.id)">
										<td>
											<span v-if="order.extra_products.length" class="badge badge-warning">{{order.extra_products.length}} Extra</span>
											{{ order.ordered_at | moment('DD/MM/Y') }} 
										</td>
										<td>
											<router-link
											:to="{name: 'admin-orders-detail', params: {id:  order.id}}"
											>
												{{ orderNumber(order.id) }}
											</router-link>
										</td>
										<td>
											{{ order.user.ccosto }}
										</td>
										<td>
											{{ order.user.name }}
										</td>
										<td>
											{{ order.ccosto_assigned }}
										</td>
										<td>
											{{ order.address }}
										</td>
										<td>
											<p class="m-0" :title="`Telefono: ${order.phone}`">{{ order.responsable }}</p>
											<p class="m-0" :title="`Telefono: ${order.phone_2}`">{{order.responsable_2}}</p>
										</td>
									</tr>
									<tr v-if="order.id == selectedOrder && role.key == selectedRole" :key="`order_${order.id}_${index}_p`">
										<td colspan="7">
											<div class="d-flex flex-wrap" >
												<div class="col-12 col-md-6 col-lg-4 col-xl-3 p-1" v-for="(product, index) in order.products" :key="`product_${order.id}_${index}_${product.id}`">
													<div class="order_product d-flex py-2 h-100">
														
														<div class="order_product_info px-2 col-9">
															<h6 class="fw-bold">
																{{ product.name }}
															</h6>
															<p>
																CANTIDAD: {{product.pivot.quantity}}
															</p>
														</div>
													</div>
												</div>
												
											</div>
											<div class="d-flex flex-wrap border-top mt-3 " v-if="order.extra_products.length">
												<div class="col-12 pt-3">
													<h6 class="title m-0">Productos fuera de catálogo</h6>
												</div>
												<div class="col-12 col-md-6 col-lg-4 col-xl-3 p-1 " v-for="(product, index) in order.extra_products" :key="`extra_product_${order.id}_${index}_${product.id}`">
													<div class="order_product d-flex py-2 h-100">
														
														<div class="order_product_info px-2">
															<h6 class="fw-bold">
																{{ product.name }}
															</h6>
															<p>
																CANTIDAD: {{product.pivot.quantity}}
															</p>
														</div>
													</div>
												</div>
												
											</div>
										</td>
									</tr>
								</template>
							</template>
							<tr  v-if="withoutDivisionalRole().length">
								<th colspan="7" class="py-2 px-2 border-bottom">
									<div class="d-flex align-items-center cursor-pointer" >
										<!-- <button class="btn btn-primary btn-sm">
											<i class="fas fa-chevron-down th-arrow"></i>
										</button> -->
										<span>
											ORDENES FUERA DE DIVISIONALES Y ROLES
										</span>
									
										<span class="ms-auto">
											{{withoutDivisionalRole().length}} PEDIDOS
										</span>
										<button @click.stop="downloadReport(withoutDivisionalRole(), 'FUERA_DIVISIONAL_ROLES')" class="btn btn-sm btn-success text-white fw-bold ms-2">Descargar reporte</button>
									</div>
								</th>
							</tr>
							<tr v-if="withoutDivisionalRole().length">
								<th class="th-second" colspan="2"></th>
								<th class="th-second text-center border-start" colspan="2">Solicitante</th>
								<th class="th-second text-center border-start" colspan="3">Destino</th>
							</tr>
							<tr v-if="withoutDivisionalRole().length">
								<th class="th-second">Fecha de Solicitud</th>
								<th class="th-second">Nº de Pedido</th>
								<th class="th-second border-start">CCosto</th>
								<th class="th-second">Nombre</th>
								<th class="th-second border-start">CCosto Asignado</th>
								<th class="th-second">Dirección destino</th>
								<th class="th-second">Responsable</th>
							</tr>
							<template v-for="(order, index) in (withoutDivisionalRole())">
								<tr :class="{'bg-soft-warning': order.extra_products.length}"  :key="`order_2_${order.id}_${index}`" @click="selectOrder(order.id)">
									<td>
										<span v-if="order.extra_products.length" class="badge badge-warning">{{order.extra_products.length}} Extra</span>
										{{ order.ordered_at | moment('DD/MM/Y') }} 
									</td>
									<td>
										<router-link
										:to="{name: 'admin-orders-detail', params: {id:  order.id}}"
										>
											{{ orderNumber(order.id) }}
										</router-link>
									</td>
									<td>
										{{ order.user.ccosto }}
									</td>
									<td>
										{{ order.user.name }}
									</td>
									<td>
										{{ order.ccosto_assigned }}
									</td>
									<td>
										{{ order.address }}
									</td>
									<td>
										<p class="m-0" :title="`Telefono: ${order.phone}`">{{ order.responsable }}</p>
										<p class="m-0" :title="`Telefono: ${order.phone_2}`">{{order.responsable_2}}</p>
									</td>
								</tr>
								<tr v-if="order.id == selectedOrder" :key="`order_${order.id}_${index}_p`">
									<td colspan="7">
										<div class="d-flex flex-wrap" >
											<div class="col-12 col-md-6 col-lg-4 col-xl-3 p-1" v-for="(product, index) in order.products" :key="`product_${order.id}_${index}_${product.id}`">
												<div class="order_product d-flex py-2 h-100">
													
													<div class="order_product_info px-2 col-9">
														<h6 class="fw-bold">
															{{ product.name }}
														</h6>
														<p>
															CANTIDAD: {{product.pivot.quantity}}
														</p>
													</div>
												</div>
											</div>
											
										</div>
										<div class="d-flex flex-wrap border-top mt-3 " v-if="order.extra_products.length">
											<div class="col-12 pt-3">
												<h6 class="title m-0">Productos fuera de catálogo</h6>
											</div>
											<div class="col-12 col-md-6 col-lg-4 col-xl-3 p-1 " v-for="(product, index) in order.extra_products" :key="`extra_product_${order.id}_${index}_${product.id}`">
												<div class="order_product d-flex py-2 h-100">
													
													<div class="order_product_info px-2">
														<h6 class="fw-bold">
															{{ product.name }}
														</h6>
														<p>
															CANTIDAD: {{product.pivot.quantity}}
														</p>
													</div>
												</div>
											</div>
											
										</div>
									</td>
								</tr>
							</template>
						</tbody>
					</table>
				</div>
				<div v-else class="pb-3 px-3">
					<h6 class="title m-0">No se han creado ordenes en el rango de fecha selecionado</h6>
				</div>
			</div>
		</div>
		
		<div class="d-flex justify-content-center" v-else-if="loading">
			<loader>
				<p class="m-0">Cargando datos...</p>
			</loader>
		</div>
		<modal-vue ref="numbersOrderModal" >
				<template v-slot:header>
					<h5 class="sub-title m-0">
						Numeros de orden
						<span v-if="dataNumbersOrder">
							{{ dataNumbersOrder.title }}
						</span>
					</h5>
				</template>
				<template v-slot:body={close}>
					<div v-if="!savingNumbersOrder" style="width: 600px">
						
						<h6 v-if="!newNumbersOrder.length" class="text-center">No has agregado número de orden a los pedidos</h6>
						<div class="d-flex align-items-end">

							<div class="flex-grow-1 w-50">
								<label class="typo__label">Números de orden</label>
								<multiselect ref="selectorNumber" :hideSelected="true" :limit="5"   v-model="newNumbersOrder" tag-placeholder="Ingresa numero de orden" placeholder="Ingresar numero de orden"  :options="currentNumbersOrder" :multiple="true" :taggable="true" @tag="addTag">
									<span slot="noOptions">Ingresa numero de orden</span>
									<span slot="maxElements	">Solo se permite 1 numero de orden</span>
								</multiselect>
							</div>
							<div class="py-2 px-3">
								<label class="btn btn-primary btn-sm text-white fw-bold" v-if="!invoice && !pathInvoice">
									<span>Cargar factura</span>
									<input class="d-none" type="file" @change="updateInvoice">
								</label>
								<label class="btn btn-success btn-sm text-white fw-bold" v-else>
									<span>Cambiar factura</span>
									<input class="d-none" type="file" @change="updateInvoice">
								</label>
							</div>
						</div>
					<div class="d-flex py-3 mt-3">
						<button @click="close()" class="ms-md-auto btn btn-transparent btn-sm text-danger">Cancelar</button>
						<button @click="setOrderNumbers" class="me-auto btn btn-primary btn-sm">Guardar</button>
					</div>
				</div>
				<div v-else-if="savingNumbersOrder" class="d-flex justify-content-center">
					<loader>
						<p class="m-0">Enviando orden...</p>
					</loader>
				</div>

			</template>
		</modal-vue>
	</div>
</template>

<style lang="scss">
.order_product{
	border: 1px solid #eee;
	background-color: #fff;
}
</style>