<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import ModalVue from '../components/ModalVue'
import Loader from '../components/Loader.vue'
import FormControl from '../components/FormControl.vue'


export default {
	components: {
		ModalVue,
		Loader,
		FormControl
	},
	data() {
		return {
			selectedCategory: null,
			selectedProduct: null,
			canClose: true,
			deleting: false,
			form: {
				name: ''
			},
			saving: false,
		}
	},

	computed: {
		productsByCategory() {
			let categories = {}

			this.categories.forEach(category => {
				categories[category.id] = this.products.filter(p => p.category_id == category.id)
			});

			return categories
		},
		...mapGetters({
			products: 'app/products',
			categories: 'app/categories',
		})
	},

	watch: {
		categories: {
			handler(nVal) {
				if(nVal.length) {
					this.selectedCategory = nVal[0].id
				}
			},
			deep: true,
			immediate: true
		}
	},

	methods: {
		showModalCategory() {
			this.$refs.newCategory.open()
		},
		confirmDelete(product_id) {
			this.selectedProduct = product_id
			this.$refs.modalDelete.open()
		},
		confirmDesactive(product_id) {
			this.selectedProduct = product_id
			this.$refs.modalDesactive.open()
		},
		confirmActive(product_id) {
			this.selectedProduct = product_id
			this.$refs.modalActive.open()
		},
		async deleteProduct() {
			let vm = this
			vm.deleting = true
			vm.canClose = false
			try {
				let data = await vm.$http.products.delete(vm.selectedProduct)
				vm.deleteProductStore(vm.selectedProduct)
				vm.canClose = true
				vm.$nextTick(() => {
					vm.$refs.modalDelete.close()
					vm.deleting = false
				})
			} catch (error) {
				vm.canClose = true
				vm.$nextTick(() => {
					vm.$refs.modalDelete.close()
					vm.deleting = false
				})
				
			}
		},
		async desactiveProduct() {
			let vm = this
			vm.deleting = true
			vm.canClose = false
			try {
				let data = await vm.$http.products.desactive(vm.selectedProduct)
				vm.canClose = true
				vm.fetchProducts()
				vm.$nextTick(() => {
					vm.$refs.modalDesactive.close()
					vm.deleting = false
				})
			} catch (error) {
				vm.canClose = true
				vm.$nextTick(() => {
					vm.$refs.modalDesactive.close()
					vm.deleting = false
				})
				
			}
		},
		async activeProduct() {
			let vm = this
			vm.deleting = true
			vm.canClose = false
			try {
				let data = await vm.$http.products.active(vm.selectedProduct)
				vm.canClose = true
				vm.fetchProducts()
				vm.$nextTick(() => {
					vm.$refs.modalActive.close()
					vm.deleting = false
				})
			} catch (error) {
				vm.canClose = true
				vm.$nextTick(() => {
					vm.$refs.modalActive.close()
					vm.deleting = false
				})
				
			}
		},

		async saveCategory() {
			let vm = this
			if(!this.form.name)	return
			vm.saving = true
			try {
				let category = await vm.$http.categories.save(vm.form)
				vm.$refs.newCategory.close()
				vm.addCategory(category)
				vm.saving = false
			} catch (error) {
				vm.$refs.newCategory.close()
				vm.saving = false
			}
		},
		...mapMutations({
			deleteProductStore: 'app/deleteProduct',
			addCategory: 'app/addCategory'
		}),
		...mapActions({
			fetchProducts: 'app/fetchProducts'
		})
	},

	mounted()  {
		this.fetchProducts()
	}
}
</script>

<template>
	<div class="container">
		<div class="d-flex align-items-center mb-3">
			<div>
				<h3 class="title m-0">Productos</h3>
				<p class="m-0">Catálogo de productos | editar o agregar </p>
			</div>
			<div class="ms-auto">
				<router-link :to="{name: 'products-add'}" class="btn btn-sm btn-primary">Agregar producto <i class="fas fa-plus"></i></router-link>
				<button class="btn btn-sm btn-primary ms-2" @click="showModalCategory">Nueva categoria <i class="fas fa-plus"></i></button>
			</div>
		</div>
		<ul class="nav nav-pills mb-0" id="pills-tab" role="tablist">
			<li v-for="(category, index) in categories" :key="`category_${index}_${category.id}`" class="nav-item" role="presentation">
				<button class="nav-link" :class="{'active': category.id == selectedCategory}" :id="`pills-${category.id}-tab`" data-bs-toggle="pill" :data-bs-target="`#pills-${category.id}`" type="button" role="tab" :aria-controls="`pills-${category.id}`" aria-selected="true">{{category.name}}</button>
			</li>
			<!-- <li class="nav-item" role="presentation">
				<button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Profile</button>
			</li>
			<li class="nav-item" role="presentation">
				<button class="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Contact</button>
			</li> -->
		</ul>

		<div class="box overflow-hidden p-0">
			<div class="tab-content" id="pills-tabContent">
				<div v-for="(category, index) in categories" :key="`category_p_${index}_${category.id}`" 
					class="tab-pane fade" 
					:class="{
						'show active': category.id == selectedCategory
					}"
					:id="`pills-${category.id}`"
					role="tabpanel" 
					:aria-labelledby="`pills-${category.id}-tab`">
					<div class="table__container">
						<table class="table">
							<thead>
								<tr>
									<th>
										Nombre
									</th>
									<th>
										ID Proveedor
									</th>
									<!-- <th>
										MBA Code	
									</th> -->
									<th>
										Precio	
									</th>
									<th class="text-center">
										<i class="fas fa-cogs"></i>	
									</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(product, index) in productsByCategory[category.id]" :key="`product_c_${index}_${product.id}`">
									<td> <router-link :to="{name: 'products-edit', params: {id: product.id}}">{{product.name}}</router-link></td>
									<td>
										<span v-if="product.supplier">
											{{`${product.supplier.supplier_id || 'No definido'}`}}
										</span>
										<span v-else>
											No definido
										</span>
									</td>
									<!-- <td>{{product.mba_code}}</td> -->
									<td>{{product.price | numeral('$0,0.00') }}</td>
									<td class="text-center d-flex">
										<button v-if="product.active" class="btn btn-sm btn-complementary me-2 w-100" @click="confirmDesactive(product.id)">
											Desactivar 
										</button>
										<button v-else class="btn btn-sm btn-success text-white me-2 w-100" @click="confirmActive(product.id)">
											Activar 
										</button>
										<button class="btn btn-sm btn-danger text-white " @click="confirmDelete(product.id)">
											Eliminar <i class="fas fa-trash-alt"></i>
										</button>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
		<modal-vue ref="modalDelete" :can-close="canClose">
			<template v-slot:header>
				<h5 class="sub-title m-0">Eliminar producto</h5>
			</template>
			<template  v-slot:body>
				<div v-if="!deleting">
					<h6 class="text-center m-0">¿Seguro que desea borrar este producto?</h6>
					<div class="py-3 text-center">
						<button class="btn btn-sm btn-default border" @click="$refs.modalDelete.close">Cancelar</button>
						<button class="btn btn-sm btn-danger text-white ms-2" @click="deleteProduct">Eliminar</button>
					</div>
				</div>
				<div v-else class="d-flex justify-content-center">
					<loader>
						<p class="m-0">Eliminando</p>
					</loader>
				</div>
			</template>
		</modal-vue>
		<modal-vue ref="modalDesactive" :can-close="canClose">
			<template v-slot:header>
				<h5 class="sub-title m-0">Desactivar producto</h5>
			</template>
			<template  v-slot:body>
				<div v-if="!deleting">
					<h6 class="text-center m-0">¿Seguro que desea desactivar este producto?</h6>
					<div class="py-3 text-center">
						<button class="btn btn-sm btn-default border" @click="$refs.modalDesactive.close">Cancelar</button>
						<button class="btn btn-sm btn-complementary  ms-2" @click="desactiveProduct">Desactivar</button>
					</div>
				</div>
				<div v-else class="d-flex justify-content-center">
					<loader>
						<p class="m-0">Desactivando</p>
					</loader>
				</div>
			</template>
		</modal-vue>
		<modal-vue ref="modalActive" :can-close="canClose">
			<template v-slot:header>
				<h5 class="sub-title m-0">Activar producto</h5>
			</template>
			<template  v-slot:body>
				<div v-if="!deleting">
					<h6 class="text-center m-0">¿Seguro que desea activar este producto?</h6>
					<div class="py-3 text-center">
						<button class="btn btn-sm btn-default border" @click="$refs.modalActive.close">Cancelar</button>
						<button class="btn btn-sm btn-success text-white  ms-2" @click="activeProduct">Activar</button>
					</div>
				</div>
				<div v-else class="d-flex justify-content-center">
					<loader>
						<p class="m-0">Desactivando</p>
					</loader>
				</div>
			</template>
		</modal-vue>
		<modal-vue ref="newCategory" :can-close="canClose">
			<template v-slot:header>
				<h5 class="sub-title m-0">Agregar categoría</h5>
			</template>
			<template  v-slot:body>
				<div v-if="!saving">
					<div class="py-3">
						<FormControl
							label="Nombre categoría"
							name="name"
							v-model="form.name"
						/>
					</div>
					<div class="py-3 text-center">
						<button class="btn btn-sm btn-default border" @click="$refs.newCategory.close">Cancelar</button>
						<button class="btn btn-sm btn-primary text-white ms-2" @click="saveCategory">Guardar</button>
					</div>
				</div>
				<div v-else class="d-flex justify-content-center">
					<loader>
						<p class="m-0">Guardando...</p>
					</loader>
				</div>
			</template>
		</modal-vue>
	</div>
</template>

<style lang="scss" scoped>
.btn{
	white-space: nowrap;
}
</style>