<script>
import http from '../http'
import FormControl from '../components/FormControl.vue'
import ModalVue from '../components/ModalVue.vue'
import Loader from '../components/Loader.vue'
import DatePicker from '../components/DatePicker.vue';

import { mapGetters } from 'vuex'

export default {
    components: {
        FormControl,
        ModalVue,
        Loader,
        DatePicker
    },

    data() {
        return {
            order: null,
            form: {
                comment: ''
            },
            formComfirm: {
                date: '',
                comments: '',
                test: [
                    {
                        "id": 1,
                        "text": "¿Cómo calificarías el tiempo de entrega de tus artículos?",
                        "icons": [
                            1,
                            2,
                            3,
                            4,
                            5
                        ],
                        "value": 5
                    },
                    {
                        "id": 2,
                        "text": "¿Qué te pareció el servicio que recibiste?",
                        "icons": [
                            1,
                            2,
                            3,
                            4,
                            5
                        ],
                        "value": 5
                    },
                ] 
            },
            comments: [],
            saving: false,
            formComfirmError: false
        }
    },

    computed: {
        ...mapGetters({
            user: 'auth/user'            
        })
    },

    methods: {
        async fetchOrder() {
            let id = this.$route.params.id
            try {
                let {data} = await http.orders.find(id)

                this.$set(this, 'order', data)
                this.$set(this, 'comments', data.comments)
            } catch (error) {
                
            }
        },

        async fetchComments() {
            let id = this.$route.params.id
            try {
                let data = await http.orders.comments(id)
                this.$set(this, 'comments', data)
            } catch (error) {
                
            }
        },

        async sendComment() {
            if(!this.form.comment) {
                return 
            }
            let payload = {
                comment:  this.form.comment
            }
            this.form.comment = ""
            try {
                let {data} = await http.orders.comment(this.order.id, payload)
                this.$set(this, 'comments', data.comments)
            } catch (error) {
                
            }
        },

        async markCanceledOrder() {
            this.sendComment()
            try {
                this.$refs.markCanceledModal.close()

                let data = await http.orders.markCanceled(this.order.id)
            } catch (error) {
                
            }
        },
        
        async markCompleteOrder() {
            let vm = this
            if(!this.formComfirm.date || !this.formComfirm.comments) {
                this.formComfirmError = true

                return
            }
            this.saving = true
            try {
                vm.$refs.markCanceledModal.close()

                let {data} = await http.orders.survey(vm.order.id, {form: vm.formComfirm})
                
                // vm.$set(vm, 'order', data.order)
                vm.order.survey = data.order.survey
                vm.order.status = data.order.status
                vm.saving = false
                vm.$refs.confirmOrderModal.close()
            } catch (error) {
                vm.saving = false
                vm.$refs.confirmOrderModal.close()
            }
        },

        openMarkImcopleteModal() {
            this.$refs.markCanceledModal.open()
        },

        openViewSurvey() {
            this.$refs.viewSurveyModal.open()
        },

        openConfirmOrder() {
            this.$refs.confirmOrderModal.open()
        },

        errorLoadImage(event) {
			event.target.src = '/img/missing.png'
		},

        orderNumber(id) {
            return window.pad(id, 6)
        },
    },

    mounted() {
        this.fetchOrder()
        setInterval(this.fetchComments, 5000)
    }
}
</script>

<template>
    <div class="container" v-if="order">
        <div class="d-flex align-items-center mb-3">
			<div>
				<h3 class="title m-0">Pedido # {{orderNumber($route.params.id)}}</h3>
			</div>
			<div class="ms-auto" v-if="order">
				<button v-if="user.id != order.user_id && order.survey" class="btn btn-sm btn-primary ms-2 fw-bold" @click="openViewSurvey">VER ENCUESTA</button>
				<button v-else-if="!order.survey && order.status != 60" class="btn btn-sm btn-primary ms-2 fw-bold" @click="openConfirmOrder">CONFIRMAR PEDIDO</button>
				<button v-if="!order.survey && order.status != 60 " class="btn btn-sm btn-danger ms-2 fw-bold text-white" @click="openMarkImcopleteModal">CANCELAR PEDIDO</button>
			</div>

		</div>
        <div class="alert alert-primary" role="alert" v-if="order.survey">
            Pedido confirmado
        </div>
        <div class="alert alert-danger" role="alert" v-if="order.status == 60">
            Pedido cancelado
        </div>
        <div class="d-flex flex-wrap" v-if="order">
            <div class="col-12 col-lg-8 col-xl-7 p-1">
                <div class="d-flex flex-wrap">
                    <div class="col-12 col-md-6 col-lg-12 col-xl-6 p-2" v-for="(product, index) in order.products"
                        :key="`p_${index}_${product.id}`"
                    >
                        <div class="order_product d-flex py-2 h-100">
                            <!-- <div class="order_product_photo col-3 px-2">
                                <img class="w-100" :src="product.image_url" @error="errorLoadImage" :alt="product.name">
                            </div> -->
                            <div class="order_product_info px-2 col-9">
                                <h6 class="fw-bold">
                                    {{ product.name }}
                                </h6>
                                <p>
                                    CANTIDAD: {{product.pivot.quantity}}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex flex-wrap" v-if="order.extra_products.length">
                    <div class="col-12 p-2">
                         <h3 class="title m-0">Productos extra</h3>
                    </div>
                    <div class="col-12 col-md-6 col-lg-12 col-xl-6 p-2" v-for="(product, index) in order.extra_products"
                        :key="`p_${index}_${product.id}`">
                        <div class="order_product d-flex py-2 h-100">
                            <div class="order_product_photo col-3 px-2">
                                <img class="w-100" :src="product.image_url" @error="errorLoadImage" :alt="product.name">
                            </div>
                            <div class="order_product_info px-2 col-9">
                                <h6 class="fw-bold">
                                    {{ product.name }}
                                </h6>
                                <p>
                                    CANTIDAD: {{product.pivot.quantity}}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-4 col-xl-5 p-1">
                <div class="p-2 box px-3">
                    <h3 class="title m-0">Comentarios</h3>
                    <div class="form__group">
                        <div class="form__wrap">
                            <input placeholder="Escribe un comentario" class="form__control" v-model="form.comment" @keydown.enter="sendComment">
                        </div>
                        <p class="small">(Presione enter ↵ para enviar su comentario)</p>
                    </div>
                    

                    <div>

                        <div v-for="(comment, index) in comments" :key="`order_c_${index}`" 
                        class="comment my-2"
                        :class="{
                            'comment-me': user.id == comment.user_id
                        }">
                            <p class="fw-bold m-0">{{comment.user.first_name}} {{comment.user.last_name}}</p>
                            <p class="m-0">{{comment.comment}}</p>
                            <p class="small m-0">
                                {{comment.created_at | moment("DD/MM/YYYY  HH:mm")}}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <modal-vue ref="markCanceledModal">
			<template v-slot:header>
				<h5 class="sub-title m-0">Cancelar pedido</h5>
			</template>
			<template v-slot:body={close}>
				<div v-if="!saving" style="min-width: 500px">
					<!-- <pre>{{selectedProduct}}</pre> -->
					<div class=" py-2">
						
                        <div class="form__group">
                            <div class="form__wrap">
                                <input placeholder="Escribe un comentario sobre la cancelación" class="form__control" v-model="form.comment" @keydown.enter="markCanceledOrder">
                            </div>
                        </div>
					</div>
					<div class="d-flex py-2 border-top mx-n3 px-3">
						<button @click="close()" class=" btn btn-transparent btn-sm ms-auto">Cerrar</button>
						<button   @click="markCanceledOrder"  class=" btn btn-danger text-white">Cancelar pedido</button>
					</div>
					<!-- <h6 class="text-center">¿Seguro desea  inhabilitar a {{userSelected.first_name}}?</h6>
					<div class="d-flex">
						<button @click="close()" class="ms-md-auto btn btn-transparent btn-sm">Cancelar</button>
						<button @click="disableUser" class="me-auto btn btn-danger btn-sm text-white">Si, continuar</button>
					</div> -->
				</div>
				<div v-else-if="saving" class="d-flex justify-content-center">
					<loader>
						<p class="m-0">Agregando...</p>
					</loader>
				</div>
			</template>
		</modal-vue>
        <modal-vue ref="confirmOrderModal">
			<template v-slot:header>
				<h5 class="sub-title m-0">Confirmación de entrega</h5>
			</template>
			<template v-slot:body={close}>
				<div v-if="!saving" style="width: 600px">
                    <div v-if="formComfirmError" class="alert alert-danger" role="alert">
                        Todos los campos son obligatorios
                    </div>
					<!-- <pre>{{selectedProduct}}</pre> -->
					<div class=" py-2 col-6">
                        <div class="form__group">
                            <div class="form__wrap">
                                <label>Fecha de entrega</label>
                                <DatePicker v-model="formComfirm.date" label="" @input="fetchReport"/>
                                <!-- <input class="form__control" type="text" v-mask="'##/##/####'" v-model="formComfirm.date"> -->
                            </div>
                        </div>
					</div>
					<div class=" py-2">
                        <div class="form__group">
                            <div class="form__wrap">
                                <label>Comentarios</label>
                                <textarea  class="form__control" v-model="formComfirm.comments">
                                </textarea>
                            </div>
                        </div>
					</div>
                    <div class="py-2">
                        <div class="" v-for="(question, index) in formComfirm.test" :key="`question_${index}`">
                            <h6 class="text-center bg-primary text-white py-2">
                                {{question.text}}
                            </h6>
                            <div class="d-flex flex-wrap justify-content-center mb-2">
                                <label class="d-block col-2 text-center"  v-for="(option, index) in question.icons" :key="`option_${question.id}_${index}`">
                                    <div class="mx-auto" >
                                        <input class="d-none" type="radio" :name="`question_${question.id}`" :value="option" v-model="question.value">
                                        <img :class="{'border border-primary ': option == question.value} " class="border-3 w-50 p-2 d-block mx-auto" :src="`/img/iconstest/${option}.png`" alt="">

                                    </div>
                                </label>
                            </div>
                        </div>

                    </div>
					<div class="d-flex py-2 border-top mx-n3 px-3">
						<button @click="close()" class=" btn btn-transparent btn-sm ms-auto">Cerrar</button>
						<button   @click="markCompleteOrder"  class=" btn btn-primary text-white">Enviar</button>
					</div>
					<!-- <h6 class="text-center">¿Seguro desea  inhabilitar a {{userSelected.first_name}}?</h6>
					<div class="d-flex">
						<button @click="close()" class="ms-md-auto btn btn-transparent btn-sm">Cancelar</button>
						<button @click="disableUser" class="me-auto btn btn-danger btn-sm text-white">Si, continuar</button>
					</div> -->
				</div>
				<div v-else-if="saving" class="d-flex justify-content-center">
					<loader>
						<p class="m-0">Agregando...</p>
					</loader>
				</div>
			</template>
		</modal-vue>
        <modal-vue ref="viewSurveyModal">
			<template v-slot:header>
				<h5 class="sub-title m-0">Encuesta</h5>
			</template>
			<template v-slot:body={close}>
				<div v-if="!saving" style="width: 600px">
                    <div v-if="formComfirmError" class="alert alert-danger" role="alert">
                        Todos los campos son obligatorios
                    </div>
					<!-- <pre>{{selectedProduct}}</pre> -->
					<div class=" py-2" v-if="order.survey">
                        <div class="form__group">
                            <div class="form__wrap">
                                <label>Fecha de entrega</label>
                                <p>{{order.survey.date}}</p>
                            </div>
                        </div>
					</div>
					<div class=" py-2">
                        <div class="form__group">
                            <div class="form__wrap">
                                <label>Comentarios</label>
                                <p>{{order.survey.comments}}</p>
                            </div>
                        </div>
					</div>
                    <div class="py-2">
                        <div class="" v-for="(question, index) in order.survey.test" :key="`question_${index}`">
                            <h6 class="text-center bg-primary text-white py-2">
                                {{question.text}}
                            </h6>
                            <div class="d-flex flex-wrap justify-content-center mb-2">
                                <label class="d-block col-2 text-center"  v-for="(option, index) in question.icons" :key="`option_${question.id}_${index}`">
                                    <div class="mx-auto" >
                                        <input disabled class="d-none" type="radio" :name="`question_${question.id}`" :value="option" v-model="question.value">
                                        <img :class="{'border border-primary ': option == question.value} " class="border-3 w-50 p-2 d-block mx-auto" :src="`/img/iconstest/${option}.png`" alt="">

                                    </div>
                                </label>
                            </div>
                        </div>

                    </div>
					<div class="d-flex py-2 border-top mx-n3 px-3">
						<button @click="close()" class=" btn btn-transparent btn-sm ms-auto">Cerrar</button>
						<!-- <button   @click="markCompleteOrder"  class=" btn btn-primary text-white">Enviar</button> -->
					</div>
					<!-- <h6 class="text-center">¿Seguro desea  inhabilitar a {{userSelected.first_name}}?</h6>
					<div class="d-flex">
						<button @click="close()" class="ms-md-auto btn btn-transparent btn-sm">Cancelar</button>
						<button @click="disableUser" class="me-auto btn btn-danger btn-sm text-white">Si, continuar</button>
					</div> -->
				</div>
				<div v-else-if="saving" class="d-flex justify-content-center">
					<loader>
						<p class="m-0">Agregando...</p>
					</loader>
				</div>
			</template>
		</modal-vue>
    </div>
</template>

<style lang="scss">
.comment{
    padding: 0.5rem;
    background: #F8F8F9;
    &-me{
        background: #ECF4FF;
    }
}
</style>