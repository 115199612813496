<template>
  <div class="home">
    <h1 class="title text-center">Bienvenido</h1>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import { mapActions } from 'vuex'
export default {
  name: 'Home',
  components: {
    HelloWorld
  },
  methods: {
	 
  },

  mounted() {
	  
  },
}
</script>
