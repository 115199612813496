<script>
import { mapActions, mapGetters } from 'vuex'
import FormControl from '../components/FormControl.vue'
import Loader from '../components/Loader.vue'

import http from '../http'
export default {
	components: {
		FormControl,
		Loader,
	},
	data() {
		return {
			user: null,
			form: {
				name: '',
				ccosto: '',
				email: '',
				employee_number: '',
				first_name: '',
				id: null,
				last_name: '',
				permissions: null,
				phone: '',
				rol: '',
				type: ''
			},
			errors: {
				name: '',
				ccosto: '',
				email: '',
				employee_number: '',
				first_name: '',
				id: '',
				last_name: '',
				permissions: '',
				phone: '',
				rol: '',
				type: ''
			},
			loading: false,
		}
	},
	computed: {
		...mapGetters({
			roles: 'app/roles'
		})
	},
	methods: {
		async fetchUsers() {
			let vm = this
			vm.loading = true
			try {
				let data = await http.users.get(this.$route.params.id)
				// vm.$set(vm, 'pagination', data)
				// vm.loading = false
				vm.$set(vm, 'user', data)
				this.setUser()
			} catch (error) {
				vm.loading = false
			}
		},
		async save() {
			let vm = this
			
			let valid = this.validForm()
			
			if(!valid) {
				return
			}
			
			this.loading = true

			this.form.permissions = Math.pow(2, this.form.rol)

			try {
				if(vm.form.id) {
					let data = await http.users.edit(this.form.id, this.form)
				} else {
					let data = await http.users.save(this.form)
				}
				if(this.form.id) {
					vm.$notify({
						group: 'b_r',
						title: '',
						text: 'Usuario editado',
						type: 'success'
					});
				} else {

					vm.$notify({
						group: 'b_r',
						title: '',
						text: 'Usuario creado',
						type: 'success'
					});
				}
				// this.loading = false
				setTimeout(() => {
					vm.$router.push({name: 'users'})
				}, 3000)

			} catch (error) {
				vm.$notify({
					group: 'b_r',
					title: '',
					text: 'Error al crear usuario',
					type: 'danger'
				});
				this.loading = false
			}
		},

		setUser() {
			let vm = this
			let user = this.user
			vm.form.id = vm.$route.params.id
			vm.form.name = user.name
			vm.form.ccosto = user.ccosto
			vm.form.email = user.email
			vm.form.employee_number = user.employee_number
			vm.form.first_name = user.first_name
			vm.form.last_name = user.last_name
			vm.form.permissions = user.permissions
			vm.form.phone = user.phone
			vm.form.rol = Math.log(user.permissions)/Math.log(2)
			vm.form.type = user.type

			vm.loading = false

		},
		validForm() {
			let valid = true
			let fields = {
				name: 'el nombre del centro de costos',
				ccosto: ' el  centro de costos',
				employee_number: 'el número de empleadoMinimo deben ser 6 caracteres',
				first_name: 'el nombre del ejecutivo',
				last_name: 'el apellido del ejecutivo',
				type: 'el tipo de usuario'
			}
			for (const key in this.form) {
				if(fields.hasOwnProperty(key)) {
					if(this.form[key]==='' ||  this.form[key] === null) {
						
						this.$set(this.errors, key,  `Ingresa ${fields[key]}`)
						if(valid) {
							valid = false
						}

					} else if(key == 'employee_number') {
						if(this.form[key].length < 6) {
							this.$set(this.errors, key,  `Minimo deben ser 6 caracteres`)
							if(valid) {
								valid = false
							}
						}
					}
				} 

			}
			return valid
		},
		clearError(key) {
			if(key == 'employee_number') {
				if(this.form[key].length >= 6) {
					this.$set(this.errors, key, '')
				}
			} else {
				this.$set(this.errors, key, '')
			}
		},
		...mapActions({
			fetchRoles: 'app/fetchRoles',
		})
	},

	mounted() {
		this.fetchRoles()
		if(this.$route.params.hasOwnProperty('id')) {
			this.fetchUsers()
		}
	}
}
</script>

<template>
	<div class="container"> 
		<h2 class="title mb-4">{{$route.params.hasOwnProperty('id') ? 'Editar Usuario' : 'Nuevo Usuario'}}</h2>
		<div></div>
		<div class="box py-4" v-if="!loading">
			<div class="d-flex flex-wrap mx-n3">
				<div class="p-3 col-12 col-md-6 ">
					<FormControl
						label="Nombre CC"
						name="name"
						@input="clearError('name')"
						:error="errors.name"
						v-model="form.name"
					/>
				</div>
				<div class="p-3 col-12 col-md-6 col-lg-3 ">
					<FormControl
						label="Centro de costos"
						name="ccosto"
						@input="clearError('ccosto')"
						:error="errors.ccosto"
						v-model="form.ccosto"
					/>
				</div>
				<div class="p-3 col-12 col-md-6 col-lg-3 ">
					<form-control
						label="Tipo usuario:"
						name="type"
						@input="clearError('type')"
						:error="errors.type"
						v-model="form.type"
						type="select"
					>

						<option value="0">DIVISIONAL</option>
						<option value="1">REGIONAL</option>
						<option value="2">GERENCIA</option>
						<option value="3">USUARIO</option>
					</form-control>
				</div>
				<div class="p-3 col-12 col-md-6 col-lg-3 ">
					<FormControl
						label="Nombre"
						name="first_name"
						@input="clearError('first_name')"
						:error="errors.first_name"
						v-model="form.first_name"
					/>
				</div>
				<div class="p-3 col-12 col-md-6 col-lg-3 ">
					<FormControl
						label="Apellidos"
						name="last_name"
						@input="clearError('last_name')"
						:error="errors.last_name"
						v-model="form.last_name"
					/>
				</div>
				<div class="p-3 col-12 col-md-6 col-lg-3 ">
					<FormControl
						label="Correo electronico"
						name="email"
						@input="clearError('email')"
						:error="errors.email"
						v-model="form.email"
					/>
				</div>
				<div class="p-3 col-12 col-md-6 col-lg-3 ">
					<form-control
						label="Rol"
						name="rol"
						@input="clearError('rol')"
						:error="errors.rol"
						v-model="form.rol"
						type="select"
					>

						<option  :key="`role_01`" :value="1">Admin</option>
						<option v-for="(role, index) in roles" :key="`role_${index}`" :value="role.id">{{role.name}}</option>
					</form-control>
				</div>
				<div class="p-3 col-12 col-md-6 col-lg-3 ">
					<FormControl
						label="Teléfono"
						name="phone"
						@input="clearError('phone')"
						:error="errors.phone"

						v-model="form.phone"
					/>
				</div>
				<div class="p-3 col-12 col-md-6 col-lg-3 ">
					<FormControl
						label="Número de empleado"
						name="employee_number"
						@input="clearError('employee_number')"
						:error="errors.employee_number"

						v-model="form.employee_number"
					/>
				</div>
			</div>

			<div>
				<button class="btn btn-primary fw-bold text-uppercase" @click="save">
					Guardar
				</button>
				<router-link :to="{name: 'users'}" class="btn btn-danger text-white fw-bold text-uppercase ms-2">
					Cancelar
				</router-link>
			</div>
		</div>
		<div class="d-flex justify-content-center" v-else-if="loading">
			<loader>
				<p class="m-0">Cargando datos...</p>
			</loader>
		</div>
	</div>
</template>