<script>
import { mapActions, mapGetters } from 'vuex'

export default {
	computed: {
		layout() {
			return this.$route.meta.layout ? `${this.$route.meta.layout}Layout`  : 'CompactLayout'
		},

		...mapGetters({
			user: 'auth/user'
		})

	},

	watch: {
		user: {
			handler(nVal, oVal) {
				if(nVal) {
					if(oVal) {
						if(oVal.id !== nVal.id) {
							this.fetchAllData()
						}
					} else {
						this.fetchAllData()
					}
				}
			},
			deep: true
		},

		$route: {
			handler(nVal) {
				if(nVal.name == 'access-management') {
					this.fetchAllData()
				}
			},

			deep: true
		}
	},

	methods: {
		fetchAllData() {
			this.fetchProducts()
			this.fetchCategories()
			this.fetchRoles()
			this.fetchCcostos()
		},
		...mapActions({
			fetchProducts: 'app/fetchProducts',
			fetchCategories: 'app/fetchCategories',
			fetchRoles: 'app/fetchRoles',
			fetchCcostos: 'app/fetchCcostos',
		})
	},

	mounted() {
		if(this.user){
			this.fetchAllData()
		}
	},
}
</script>
<template>
  <div id="app">
	<component :is="layout"></component>
	<notifications group="b_r" position="bottom right"/>
  </div>
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
	@import './assets/scss/app.scss';
	.multiselect__tag{
		background: #FFA800;
		color: #000;
		.multiselect__tag-icon{
			color: #000 !important;
			background: #FFA800 !important;
			&::after{
				color: #000;
			}
		}
	}
	.multiselect__option--highlight,
	.multiselect__option--highlight{
		background: #FFA800;
		color: #000;
	}

</style>