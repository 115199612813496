<script>
import { mapGetters } from 'vuex'
import FormControl from '../components/FormControl.vue'
import Loader from '../components/Loader.vue'
export default {
	components: {
		FormControl,
		Loader
	},
	data() {
		return {
			form: {
				name: '',
				id_people: null,
				mba_code: null,
				measure_unit: '',
				category_id: null,
				// price: null,
				permissions: 0,
				role_limits: {
					
				},
			},
			errors: {
				name: '',
				id_people: '',
				mba_code: '',
				measure_unit: '',
				category_id: '',
				price: '',
			},
			saving: false,
			ch_permissions: [],
			image_url: '',
			file: null,
		}
	},

	computed: {
		product() {
			if(this.$route.name != 'products-edit') return null
			return this.productById(Number(this.$route.params.id))
		},
		permissions() {
			let keys = Object.keys(this.form.role_limits)
			let value = 0

			keys.forEach(key => {
				value += Number(key)
			}) 

			return value
		},
		...mapGetters({
			roles: 'app/roles',
			categories: 'app/categories',
			productById: 'app/productById'
		})
	},

	watch: {
		'product': {
			handler(nVal) {
				if(nVal) {
					this.completeForm()
				}
			},
			deep: true,
			immediate: true
		}
	},

	methods: {
		async getUser() {

		},
		getKey(key) {
			return 1 << key
		},

		updatePhoto(event) {
			let vm = this
			if(!event.target.files.length) {
				this.file = null
				this.image_url = null
				return 
			}
			let file = event.target.files[0]
			this.$set(this, 'file', file)
			this.image_url = URL.createObjectURL(file)
		},

		addPermission(event) {
			let value = Number(event.target.value)
			
			if(event.target.checked) {
				this.$set(this.form.role_limits, value, '')
			} else {
				if(this.form.role_limits.hasOwnProperty(value)) {

					delete this.form.role_limits[value]
					let data = JSON.stringify(this.form.role_limits)
					data = JSON.parse(data)
					this.$set(this.form, 'role_limits', data)
				}
			}
		},

		valid() {
			let valid = true
			let fields = {
				name: 'el nombre del producto',
				// id_people: 'el ID People',
				// mba_code: 'el MBA CODE',
				measure_unit: 'la unidad de medida',
				category_id: 'la categoria',
				// price: 'el precio',
			}
			for (const key in this.form) {
				if(fields.hasOwnProperty(key)) {
					if(!this.form[key]) {
						this.$set(this.errors, key,  `Ingresa ${fields[key]}`)
	
						if(valid) {
							console.log(key)
							valid = false
						}
					}
				} 

			}
			return valid
		},

		clearError(key) {
			this.$set(this.errors, key, '')
		},

		resetForm() {
			this.$set(this, 'form', {
				name: '',
				id_people: null,
				mba_code: null,
				measure_unit: '',
				category_id: null,
				price: null,
				permissions: 0,
				role_limits: {
					
				},
			})

			this.$set(this, 'ch_permissions', [])
		},

		async handlerSubmit() {
			let vm = this
			debugger
			if(!vm.valid()) return false
			vm.saving = true

			let form = new FormData()


			for (let key in this.form) {
				if(key == 'role_limits') {
					form.append(key, JSON.stringify(this.form[key]))
				} else if(key == 'permissions') {
					form.append(key, this.permissions)
				} else {
					form.append(key, this.form[key])
				}
			}
			

			if(this.file) {
				form.append('image', this.file)
			}

			try {
				let response = null

				if(vm.product) {
					response = await vm.$http.products.update(vm.product.id, form)
					vm.$router.push({name: 'products'})
				} else {
					response = await vm.$http.products.save(form)
				}
				vm.saving = false
				if(!vm.product) {
					vm.resetForm()
				}
				vm.$notify({
					group: 'b_r',
					title: 'Guardado con exito',
					text: 'El producto se ha guardado.',
					type: 'success'
				});
			} catch (error) {
				vm.saving = false
			}
		},

		completeForm() {
			let product = JSON.parse(
				JSON.stringify(this.product)
			)

			

			this.$set(this, 'form', {
				name: product.name,
				id_people: product.id_people,
				mba_code: product.mba_code,
				measure_unit: product.measure_unit,
				category_id: product.category_id,
				price: product.price,
				permissions: product.permissions,
				role_limits: product.role_limits,
			})

			this.image_url = product.image_url

			// this.$set(this, 'ch_permissions', Object.keys(product.role_limits))
		},

		errorLoadImage(event) {
			event.target.src = '/img/missing.png'
		},
	},

	mounted() {
		
	}
}
</script>
<template>
	<div class="container">
		<h2 class="title mb-4" v-if="product">Editar producto</h2>
		<h2 class="title mb-4" v-else>Agregar producto</h2>
		<div v-if="saving">
			<loader>
				<p class="text-center">Guardando producto..</p>
			</loader>
		</div>
		<div v-else>
			<h5 class="sub-title m-0">Información producto</h5>
			<p class="">Ingrese los datos</p>
			<div class="box p-3 mb-4">
				<div class="d-flex flex-wrap">
					<div class="col-12 col-md-6 col-lg-3 p-3">
						<FormControl
							label="Nombre producto"
							name="name"
							:error="errors.name"
							@input="clearError('name')"
							v-model="form.name"
						/>
					</div>
					<!-- <div class="col-12 col-md-6 col-lg-3 p-3">
						<FormControl
							label="ID People"
							name="id_people"
							type="number"
							:error="errors.id_people"
							@input="clearError('id_people')"
							v-model="form.id_people"
						/>
					</div> -->
					<!-- <div class="col-12 col-md-6 col-lg-3 p-3">
						<FormControl
							type="number"
							label="MBA CODE"
							name="mba_code"
							:error="errors.mba_code"
							@input="clearError('mba_code')"
							v-model="form.mba_code"
						/>
					</div> -->
					<div class="col-12 col-md-6 col-lg-3 p-3">
						<form-control
							type="select"
							label="Unidad de medida"
							name="measure_unit"
							:error="errors.measure_unit"
							@input="clearError('measure_unit')"
							v-model="form.measure_unit"
						>
							<option value=""></option>
							<option value="BUL">BUL</option>
							<option value="CAJ">CAJ</option>
							<option value="JGO">JGO</option>
							<option value="KGR">KGR</option>
							<option value="PAQ">PAQ</option>
							<option value="PZA">PZA</option>
							<option value="TUB">TUB</option>
						</form-control>
					</div>
					<div class="col-12 col-md-6 col-lg-3 p-3">
						<form-control
							type="select"
							label="Categorías"
							name="category_id"
							:error="errors.category_id"
							@input="clearError('category_id')"
							v-model="form.category_id"
						>
							<option value=""></option>
							<option v-for="(category, index) in categories" :key="`c_${index}_${category.id}`"  :value="category.id">{{category.name}}</option>
						</form-control>
					</div>
					<!-- <div class="col-12 col-md-6 col-lg-3 p-3">
						<FormControl
							type="number"
							label="Precio"
							name="price"
							:error="errors.price"
							@input="clearError('price')"
							v-model="form.price"
						/>
					</div> -->
					<!-- <div class="col-12 col-md-6 col-lg-6 p-3">
						<FormControl
							label="Producto proveedor"
							name="mba_code"
						/>
					</div> -->
					<!-- <div class="col-12 col-md-6 p-3">
						<input type="file" @change="updatePhoto">

						<img class="form_product_photo" :src="image_url" @error="errorLoadImage" :alt="form.name">
					</div> -->
				</div>
			</div>
			<h5 class="sub-title m-0">Permisos del producto</h5>
			<p class="">Si para algún Rol este producto no tiene limite, ponga 0 en Límite</p>
			<div class="box p-3">
				<table class="table " style="width: auto">
					<tr>
						<th class="px-2 py-2">Roles</th>
						<th class="px-2 py-2">Permiso</th>
						<th class="px-2 py-2">Límite</th>
					</tr>
					<tr v-for="(role, index) in roles" :key="`role_${role.id}_${index}`">
						<td class="p-2">
							<label :for="`check_${role.id}`" class="p-0">
								{{role.name}}
							</label>
						</td> 
						<td class="p-2">
							<label class="input-check ms-auto">
								<input :id="`check_${role.id}`" :name="`ch_permissions_${role.id}`" @change="addPermission" :value="getKey(role.key)" :checked="form.role_limits.hasOwnProperty(getKey(role.key))" type="checkbox"> 
								<span class="input-check__state"></span>
							</label>
						</td>
						<td class="p-2">
							<div v-if="form.role_limits.hasOwnProperty(getKey(role.key))">
								<FormControl
									style="width: 100px"
									label="Limite"
									:name="`limit_${role.io}`"
									type="number"
									v-model="form.role_limits[getKey(role.key)]"
								/>
							</div>
						</td>
					</tr>
				</table>
			</div>
			<div class="d-flex mt-3">
				<router-link :to="{name: 'products'}" class="btn btn-sm btn-danger text-white ms-auto">Cancelar</router-link>
				<button class="btn btn-sm btn-primary ms-2" @click="handlerSubmit">Guardar</button>
			</div>
		</div>
	</div>
</template>
<style lang="scss" scoped>
.form_product_photo{
	display: inline-block;
	width: 80px;
	height: 80px;
	margin: 5px;
}
</style>