import Vue from 'vue'
import vueNumeralFilterInstaller from 'vue-numeral-filter';
import Notifications from 'vue-notification'
import VuePaginate from 'vue-paginate'
import VCalendar from 'v-calendar';

// Prefered: as a plugin (directive + filter) + custom placeholders support
import { VueMaskDirective } from 'v-mask'





import App from './App.vue'
import router from './router'
import store from './store'
import http from './http'

Vue.use(VCalendar)
Vue.component('pagination', require('laravel-vue-pagination'));
Vue.use(VuePaginate)
Vue.use(Notifications)
Vue.directive('mask', VueMaskDirective);


Vue.use(vueNumeralFilterInstaller, { locale: 'en-au' });
window.Popper = require('popper.js').default;
window.pad = function (n, width, z) {
  z = z || '0';
  n = n + '';
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}
require('bootstrap');


const registeredLayouts = [
    'Full',
    'Compact'
]

registeredLayouts.map((layout) => {
	Vue.component(`${layout}Layout`, () => import(/* webpackChunkName: "[request]-layout" */ `./layouts/${layout}Layout.vue`))
})
Vue.component('pagination', require('laravel-vue-pagination'));
Vue.use(require('vue-moment'));
Vue.config.productionTip = false
Vue.prototype.$http = http
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
