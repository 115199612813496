<script>
import DatePicker from 'vue2-datepicker';

export default {
	components: {
		DatePicker
	},
	props:{

		name: {
			type: String,
			require() {
				return true
			}
		},
		label: {
			type: String,
			require() {
				return true
			}
		},

		error: {
			type: String,
			default() {
				return ''
			}
		},
		autofocus: {
			type: Boolean,
			default() {
				return false
			}
		},
		autocomplete: {
			type: Boolean,
			default() {
				return false
			}
		},
		
	},
	data() {
		return {
			focus: false,
			localValue: this.$attrs.value || [],
			lang: {
				  formatLocale: {
					// MMMM
					months: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Novimbre', 'Diciembre'],
					// MMM
					monthsShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
					// dddd
					weekdays: ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado'],
					// ddd
					weekdaysShort: ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'],
					// dd
					weekdaysMin: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
					// first day of week
					firstDayOfWeek: 1,
					// first week contains January 1st.
					firstWeekContainsDate: 1,
					// format 'a', 'A'
				},
			}
		}
	},

	watch: {
		'$attrs.value': {
			handler(nVal) {

				// if(!this.localValue) {
					this.localValue = nVal
				// }
			},
			deep: true
		}
	},

	methods: {
		handlerFocus() {
			this.focus = true
			this.$emit('focus')
		},

		hanlderBlur(){
			this.focus = false
			this.$emit('blur')

		},

		handlerInput() {
			
			this.$emit('input', this.localValue)
		}
	}
}
</script>
<template>
	<div class="form__group" 
		:class="{
			'form__group--is-focus': focus,
			'form__group--is-valid': localValue.length,
			'form__group--is-invalid': error,
		}">
		<div class="form__wrap">
			<date-picker 
				v-model="localValue" 
				:lang="lang" 
				@open="handlerFocus"
				@close="hanlderBlur"
				@input="handlerInput"
				range></date-picker>
			<span class="form__label">{{label}}</span>
		</div>
	</div>
</template>