import http from '../../http'

export const getNextOrderPeriod = async ({commit}, type) => {
	try {
		let data = await http.orders.periods(type)
		commit('setPeriod', data)	
		return Promise.resolve(data)
	} catch (error) {
		commit('setPeriod', null)	
		return Promise.reject(error)
	}
}

export const getCart = async ({commit}, type) => {
	try {
		let data = await http.orders.cart(type)
		commit('setCart', data)	
		return Promise.resolve(data)
	} catch (error) {
		commit('setCart', null)	
		return Promise.reject(error)
	}
}

export const getCatalog = async ({commit}) => {
	try {
		let data = await http.catalog.list()
		commit('setCatalog', data)	
		let unavailable = await http.catalog.unavailible()
		commit('setUnavailable', unavailable)	
		return Promise.resolve([data, unavailable])
	} catch (error) {
		commit('setCart', null)	
		return Promise.reject(error)
	}
}

export const addToCart = async ({commit}, payload) => {
	let product = null
	if(payload.type_name == "product") {
		product = {
			"type": payload.type_name,
			"quantity": payload.qty,
			"product_id": payload.id
		}
	} else if(payload.type_name == "extra_product") {
		product = {
			"type": payload.type_name,
			"quantity": payload.qty,
			"name": payload.name
		}
	}

	try {
		let data = await http.orders.addToCart(payload.type, product)
		// commit('setCatalog', data)	
		// let unavailable = await http.catalog.unavailible()
		// commit('setUnavailable', unavailable)	
		return Promise.resolve(data)
	} catch (error) {
		return Promise.reject(error)
	}
}

export const removeFromCart = async ({commit}, payload) => {
	let product = payload.data
	commit('removeFromCart', product)
	try {
		let data = await http.orders.removeFromCart(payload.type, product)
		// commit('setCatalog', data)	
		// let unavailable = await http.catalog.unavailible()
		// commit('setUnavailable', unavailable)	
		return Promise.resolve(data)
	} catch (error) {
		return Promise.reject(error)
	}
}

export const checkoutCart = async ({commit}, payload) => {
	let type = payload.type
	try {
		let data = await http.orders.checkout(type, {
			address: payload.address,
			responsable: payload.responsable,
			responsable_2: payload.responsable_2,
			phone: payload.phone,
			phone_2: payload.phone_2,
			ccosto_assigned: payload.ccosto_assigned,
		})
		commit('setCart', null)
		return Promise.resolve(data)
	} catch (error) {
		return Promise.reject(error)
	}
}