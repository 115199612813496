import http from '../../http'


export const fetchProducts = async ({commit}) => {
	try {
		let products = await http.products.list()
		
		commit('setProducts', products)
	} catch (error) {
		commit('setProducts', [])
	}
}

export const fetchCategories = async ({commit}) => {
	try {
		let categories = await http.categories.list()
		commit('setCategories', categories)
	} catch (error) {
		commit('setCategories', [])
	}
}

export const fetchRoles = async ({commit}) => {
	try {
		let roles = await http.roles.list()
		commit('setRoles', roles)
	} catch (error) {
		commit('setRoles', [])
	}
}

export const fetchCcostos = async ({commit}) => {
	try {
		let roles = await http.ccostos.list()
		commit('setCcostos', roles)
	} catch (error) {
		commit('setCcostos', [])
	}
}