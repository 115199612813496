<script>
// TODO: Exportar reporte
import { mapActions, mapGetters } from 'vuex'
import DragDropFile from '../components/DragDropFile.vue'
import Loader from '../components/Loader.vue'
import ModalVue from '../components/ModalVue.vue'
import DateRange from '../components/DateRange.vue';
import Autocomplete from '../components/Autocomplete.vue'

import moment from 'moment'
import http from '../http'
export default {
	components: {
		DragDropFile,
		ModalVue,
		Loader,
		DateRange,
		Autocomplete
	},
	data() {
		return {
			loading: true,

			paginate: ['users', 'usersTrash'],
			
			pagination: null,
			page: 1,

			productsList: [],
			users: [],
			userSelected: {},

			saving: false,

			date: [
				moment().startOf('month').toDate(),
				moment().endOf('month').toDate()
			],
			
			selectedCcosto: null,
			selectedProduct: {id: 0},
			pagination: {
				data: []
			},
			selectedOrder: null,
		}
	},

	computed: {
		...mapGetters({
			ccostos: 'app/ccostos',
			// users: 'app/users',
			products: 'app/products',
		})
	},

	methods: {
		async fetchUsers() {
			
			let vm = this
			vm.loading = true
			try {
				let data = await http.users.all()
				// vm.$set(vm, 'pagination', data)
				// vm.loading = false
				vm.$set(vm, 'users', data)
				this.fetchUsersTrash()
			} catch (error) {
				vm.loading = false
			}
		},

		async fetchReport(page = 1) {
			let vm = this
			let data = {}
// ?since=2021-04-01&until=2021-08-31&page=2ccosto=2&page=1&product_id=2
			data.since = moment(this.date[0]).format('Y-MM-DD')
			data.until = moment(this.date[1]).format('Y-MM-DD')
			data.page = page
			this.loading = true
			if(this.userSelected.id) {
				data.ccosto = this.userSelected.id
			}

			if(this.selectedProduct.id) {
				data.product_id = this.selectedProduct.id
			}

			try {
				
				let response = await http.products.report(data)
				
				vm.$set(vm, 'pagination', response)

				this.loading = false

			} catch (error) {
				console.log(error)
			}
		},
		async downloadReport () {
			
			let vm = this
			let data = {
				since: moment(this.date[0]).format('Y-MM-DD'),
				until: moment(this.date[1]).format('Y-MM-DD'),
				title: `${moment(this.date[0]).format('Y-MM-DD')}_${moment(this.date[1]).format('Y-MM-DD')}`
			} 
			if(this.userSelected.id) {
				data.ccosto = this.userSelected.id
			}

			if(this.selectedProduct.id) {
				data.product_id = this.selectedProduct.id
			}
			try {
				let response = await http.orders.downloadProducts(data)
				if(response.data.files.length) {
					response.data.files.forEach(url => {
						vm.downloadFile(url)
					})
				}
			} catch (error) {
				
			}
		},
		downloadFile(url) {
			window.open(url)
		},
		orderNumber(id) {
            return window.pad(id, 6)
        },
		selectOrder(id) {
			if(this.selectedOrder != id) {
				this.selectedOrder = id
			} else {
				this.selectedOrder = 0
			}
		},
		errorLoadImage(event) {
			event.target.src = '/img/missing.png'
		},
		...mapActions({
			fetchProducts: 'app/fetchProducts'
		})

		// setPage(page) {
		// 	this.page = page
		// 	this.fetchEmployees()
		// },
		// onChangeFile(file) {
		// 	if(file.length) {
		// 		this.$refs.modalUpload.open()
		// 	}
		// },

		

		// resetFiles() {
		// 	this.$refs.dragDrop.reset()
		// }
	},

	mounted() {
		let vm = this
		this.fetchUsers()
		this.fetchProducts().then(res => {
			let products = JSON.stringify(vm.products)
				products = JSON.parse(products)
			
			products.unshift({id: 0, name: 'Todos los productos'})

			vm.$set(vm, 'productsList', products)
		})

		this.fetchReport()
	}
	
}
</script>
<template>
	<div class="container">
		<div class="d-flex align-items-center mb-3">
			<div>
				<h3 class="title mb-0">Productos Solicitados</h3>
			</div>
			<div class="ms-auto">
				<button class="btn btn-sm btn-success btn-sm fw-bold text-white text-uppercase ms-2" @click="downloadReport">
					Descargar reporte <i class="far fa-file-excel"></i>
				</button>
			</div>
			<!-- <div class="ms-auto container-header__control">
				<drag-drop-file ref="dragDrop" v-model="files" @change="onChangeFile">
					<p class="m-0 text-center">Actualizar plantilla <i class="fas fa-sync-alt"></i></p>
				</drag-drop-file>
			</div> -->
		</div>
		<!-- <pre>{{users}}</pre> -->
		<div v-if="!loading">
			<!-- <pre>{{products}}</pre> -->
			<div class="box p-0" v-if="!loading">
				<div class="d-flex flex-wrap">
					<div class="col-12 col-md-6 col-lg-4 p-3">
						<DateRange v-model="date" label="Desde/Hasta" @input="fetchReport"/>
					</div>
					<div class="col-6 col-md-6 col-lg-4 p-3">
						<autocomplete
							class="mb-0"
							label="Usuarios"
							name="ccosto_search"
							v-model="userSelected"
							@input="fetchReport"
							:selected-label="(option) => {
								return`${option.ccosto} - ${option.name}`
							}"
							:options="users"
							:filter="(options, query) => {
								let filtered = options.filter(o => {
									let ccosto = o.ccosto.toString().toLowerCase()
									let name  = o.name.toLowerCase()
									let match = false
									query = query.toLowerCase()
									
									if(!match) {
										match = ccosto.includes(query)
									}
									if(!match) {
										match = name.includes(query)
									}

									return match
								})

								return filtered
							}"
							>
							<template v-slot:option="{option}">
								{{ option.ccosto }} - {{option.name}}
							</template>
						</autocomplete>
					</div>
					<div class="col-6 col-md-6 col-lg-4 p-3">
						<autocomplete
							class="mb-0"
							label="Productos"
							name="ccosto_search"
							v-model="selectedProduct"
							@input="fetchReport"
							:selected-label="(option) => {
								return`${option.name}`
							}"
							:options="productsList"
							:filter="(options, query) => {
								let filtered = options.filter(o => {
									let name  = o.name.toLowerCase()
									let match = false
									query = query.toLowerCase()
									
									if(!match) {
										match = name.includes(query)
									}

									return match
								})

								return filtered
							}"
							>
							<template v-slot:option="{option}">
								 {{option.name}}
							</template>
						</autocomplete>
					</div>
				</div>

				<div class="table-container">
					<table class="table">
						<thead>
							<tr>
								<th colspan="2"></th>
								<th colspan="2">Solicitante</th>
								<th colspan="3">Destino</th>
							</tr>
							<tr>
								<th>Fecha de Solicitud</th>
								<th>Nº de Pedido</th>
								<th>CCosto</th>
								<th>Nombre</th>
								<th>CCosto Asignado	</th>
								<th>Dirección destino</th>
								<th>Responsable</th>
							</tr>
						</thead>
						<tbody>
							<template v-for="(order, index) in pagination.data">
								<tr  :key="`order_${order.id}_${index}`" @click="selectOrder(order.id)">
									<td>
										{{ order.ordered_at | moment('DD/MM/Y') }}
									</td>
									<td>
										<router-link
										:to="{name: 'admin-orders-detail', params: {id:  order.id}}"
										>
											{{ orderNumber(order.id) }}
										</router-link>
									</td>
									<td>
										{{ order.user.ccosto }}
									</td>
									<td>
										{{ order.user.name }}
									</td>
									<td>
										{{ order.ccosto_assigned }}
									</td>
									<td>
										{{ order.address }}
									</td>
									<td>
										{{ order.responsable }}
									</td>
								</tr>
								<tr class="w-100" v-show="order.id == selectedOrder" :key="`order_${order.id}_${index}_p`">
									<td colspan="7" class="w-100">
										<div class="d-flex flex-wrap" >
											<div class="col-12 col-md-6 col-lg-4 col-xl-3 p-1" v-for="(product, index) in order.products" :key="`product_${order.id}_${index}_${product.id}`">
												<div class="order_product d-flex py-2 h-100">
													<div class="order_product_photo col-3 px-2">
														<img class="w-100" :src="product.image_url" @error="errorLoadImage" :alt="product.name">
													</div>
													<div class="order_product_info px-2 col-9">
														<h6 class="fw-bold">
															{{ product.name }}
														</h6>
														<p>
															CANTIDAD: {{product.pivot.quantity}}
														</p>
													</div>
												</div>
											</div>
											
										</div>
									</td>
								</tr>
							</template>
						</tbody>
					</table>
				</div>
			</div>
			<div class="d-flex justify-content-center mt-2">
				<pagination :data="pagination" @pagination-change-page="fetchReport" ></pagination>
			</div>
		</div>
		<div class="d-flex justify-content-center" v-else-if="loading">
			<loader>
				<p class="m-0">Cargando datos...</p>
			</loader>
		</div>
	</div>
</template>