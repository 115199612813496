<script>
import FormControl from './FormControl.vue'
export default {
	props: {
		
		name: {
			type: String,
			require() {
				return true
			}
		},
		label: {
			type: String,
			require() {
				return true
			}
		},
		options: {
			type: Array,
			default() {
				return []
			}
		},
		filter: {
			type: Function,
			default(options, query) {

				if(!query) return []
				let filtered = options.filter(o => {
					let name  = o.name.toLowerCase()
					let match = false
					query = query.toLowerCase()
				
					if(!match) {
						match = name.includes(query)
					}

					return match
				})

				return filtered  || []
			}
		},

		selectedLabel: {
			type: Function,
			default(option) {
				return option.name
			}
		}
	},
	components: {
		FormControl
	},
	data() {
		return {
			form: {
				query: '',
				lastQuery: ''
			},
			focus: false,
			selected: null,
		}
	},

	computed: {
		optionsFiltered() {
			if(!this.form.query) return this.options
			return this.filter(this.options, this.form.query)
		}
	},
	watch: {
		'$attrs.value': {
			handler(nVal) {
				

				// if(!this.localValue) {
				this.setValue(nVal)
				// }
			},
			deep: true,
			immediate: true
		}
	},

	methods: {
		setValue(opt) {
			
			if(!opt) {
				return
			}
			if(!opt.hasOwnProperty('id')) {
				return
			}
			let option = this.options.find(o => o.id == opt.id)
			
			this.$set(this, 'selected', option)
		},
		select(option) {
			this.form.lastQuery = this.form.query
			this.form.query = ""
			
			this.$emit('input', option)
		},
		onFocus() {
			this.focus = true
			if(this.$attrs.value) {
				this.form.query = this.form.lastQuery
			}
		},
		onBlur() {
			let vm = this
			if(this.$attrs.value) {
				if(this.form.query) {
					this.form.lastQuery = this.form.query
				}

			}
			setTimeout(() => {
				if(this.$attrs.value) {
					this.form.query = ''
				}
				vm.focus = false
			}, 100)
		}
	}
}
</script>
<template>
	<div class="autocomplete">
		<div class="autocomplete__control">
			<FormControl
				class="mb-0"
				:class="{
					'form__group--is-valid': $attrs.value
				}"
				:autocomplete="false"
				:label="label"
				:name="name"
				@focus="onFocus"
				@blur="onBlur"
				v-model="form.query" />
			<span v-if="selected &&  !focus && !form.query" class="autocomplete__selected">{{selectedLabel(selected)}}</span>
		</div>
		<div class="autocomplete__list" v-if="optionsFiltered.length && focus">
			<ul>
				<li v-for="(option, index) in optionsFiltered" :key="`option_${index}`" @mousedown="select(option)"
					:class="{selected: $attrs.value ? $attrs.value.id == option.id : false}">
					<slot name="option" :option="option">
						{{ props.option.name}}
					</slot>
				</li>
			</ul>
		</div>
	</div>
</template>