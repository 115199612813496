<script>
import { mapGetters } from 'vuex'
import DragDropFile from '../components/DragDropFile.vue'
import Loader from '../components/Loader.vue'
import ModalVue from '../components/ModalVue.vue'

export default {
	components: {
		DragDropFile,
		ModalVue,
		Loader
	},
	data() {
		return {
			files: [],
			page: 1,
			pagination: null,
			uploading: false,
			uploadingProgress: 0,
			canCloseModal: true,
			loading: true
		}
	},

	computed: {
		...mapGetters({
			ccostos: 'app/ccostos'
		})
	},

	methods: {
		async fetchEmployees() {
			
			let vm = this
			vm.loading = true
			try {
				let data = await vm.$http.employees.list(vm.page)
				vm.$set(vm, 'pagination', data)
				vm.loading = false

			} catch (error) {
				vm.loading = false
				
			}
		},

		async uploadFile() {
			let vm = this
			let formData = new FormData()
			if(!this.files.length) {
				return 
			}
			formData.append('type', 1)
			formData.append('file', this.files[0])
			this.uploading = true
			this.canCloseModal = false
			try {
				let data = await vm.$http.files.upload(formData, vm.calcUpload)
				vm.uploading = false
				vm.uploadingProgress = 0
				vm.canCloseModal = true
				vm.$nextTick(() => {
					vm.$refs.modalUpload.close()
				})
				vm.page = 1
				vm.fetchEmployees()
			} catch (error) {
				
				vm.uploading = false
				vm.uploadingProgress = 0
				vm.canCloseModal = true
				vm.fetchEmployees()
				vm.$nextTick(() => {
					vm.$refs.modalUpload.close()
				})
				vm.page = 1
			}
		},
		
		calcUpload(progressEvent) {
			let p = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ))
			this.uploadingProgress =  p
		},

		setPage(page) {
			this.page = page
			this.fetchEmployees()
		},
		onChangeFile(file) {
			if(file.length) {
				this.$refs.modalUpload.open()
			}
		},

		

		resetFiles() {
			
			this.$refs.dragDrop.reset()
		}
	},

	mounted() {
		this.fetchEmployees()
	}
	
}
</script>
<template>
	<div class="container">
		<modal-vue ref="modalUpload" @close="resetFiles" :can-close="canCloseModal">
			<template v-slot:header>
				<h5 class="sub-title m-0">Actualizar empleados</h5>
			</template>
			<template v-slot:body={close}>
				<div v-if="!uploading">
					<h6 class="text-center">¿Desea continuar?</h6>
					<h6 class="text-center">Se actualizaran los empelados con los datos que contiene el archivo</h6>
					<p class="text-center fw-bold">{{  files[0].name }}</p>
					<div class="d-flex">
						<button @click="close()" class="ms-md-auto btn btn-transparent btn-sm text-danger">Cancelar</button>
						<button @click="uploadFile" class="me-auto btn btn-primary btn-sm">Si, continuar</button>
					</div>
				</div>
				<div v-else class="py-3">
					<div v-if="uploadingProgress < 100" class="">
						<p class="text-success text-center m-0">Subiendo {{uploadingProgress}}%</p>
						<div class="progress">
							<div class="progress-bar bg-complementary" role="progressbar" :style="{width: `${uploadingProgress}%`}" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
						</div>
					</div>
					<div class="text-center" v-else>
						<h5 class="sub-title">Completado <i class="far fa-check-circle text-success"></i></h5>
						<p>Actualizando datos...</p>
					</div>
				</div>

			</template>
		</modal-vue>
		<div class="d-flex align-items-center mb-3">
			<div>
				<h3 class="title mb-0">Empleados</h3>
				<p class="sub-title mb-4">Actualiza esta plantilla subiendo un archivo XLSX</p>
			</div>
			<div class="ms-auto container-header__control">
				<drag-drop-file ref="dragDrop" v-model="files" @change="onChangeFile">
					<p class="m-0 text-center">Actualizar plantilla <i class="fas fa-sync-alt"></i></p>
				</drag-drop-file>
			</div>
		</div>

		<div class="box overflow-hidden p-0" v-if="pagination">
			<div class="table__container" v-if="pagination">
				<table class="table" v-if="pagination.data.length">
					<thead>
						<tr>
							<th>C. Costos</th>
							<th># Empleado</th>
							<th>Nombre</th>
							<th>Puesto</th>
							<th class="text-center">Ingreso</th>
							<th class="text-center">Última Solicitud</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(employee, index)  in pagination.data" :key="`c_${index}_${employee.id}`">
							<td>
								{{employee.ccosto}}
							</td>
							<td>
								{{employee.employee_number}}
							</td>
							<td>
								{{employee.full_name}}
							</td>
							<td>
								{{employee.position}}
							</td>
							<td class="text-center">
								{{employee.enrolled_at |  moment("DD/MM/YYYY")}}
							</td>
							<td class="text-center">
								{{employee.ordered_at || '-' |  moment("DD/MM/YYYY")}}
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div class="p-3 d-flex">
				<pagination class="ms-auto mb-0" :data="pagination" :limit="2"  @pagination-change-page="setPage"></pagination>
			</div>
		</div>
		<div class="d-flex justify-content-center" v-else-if="loading">
			<loader>
				<p class="m-0">Cargando datos...</p>
			</loader>
		</div>
	</div>
</template>