<script>
export default {
	props:{
		type: {
			type: String,
			default() {
				return 'text'
			}
		},
		name: {
			type: String,
			require() {
				return false
			}
		},
		placeholder: {
			type: String,
			require() {
				return false
			},
		},
		label: {
			type: String,
			require() {
				return true
			}
		},

		error: {
			type: String,
			default() {
				return ''
			}
		},
		autofocus: {
			type: Boolean,
			default() {
				return false
			}
		},
		autocomplete: {
			type: Boolean,
			default() {
				return false
			}
		},
		disabled: {
			type: Boolean,
			default() {
				return false
			}
		},
		max: {
			type: Number,
			default() {
				return 100000000000
			}
		},
		badge: {
			type: String,
			default() {
				return ''
			}
		},
		mask: {
			type: String,
			default() {
				return ''
			}
		}
		
	},
	data() {
		return {
			focus: false,
			localValue: this.$attrs.value,
		}
	},

	watch: {
		'$attrs.value': {
			handler(nVal) {

				// if(!this.localValue) {
					this.localValue = nVal
				// }
			},
			deep: true
		}
	},

	methods: {
		handlerFocus() {
			this.focus = true
			this.$emit('focus')
		},

		hanlderBlur(){
			this.focus = false
			this.$emit('blur')

		},

		handlerInput() {
			this.$emit('input', this.localValue)
		}
	}
}
</script>
<template>
	<div class="form__group" 
		:class="{
			'form__group--is-focus': focus,
			'form__group--is-valid': $attrs.value ||  $attrs.value === 0,
			'form__group--is-invalid': error,
		}">
		<div class="form__wrap">
			<input 
			v-if="type != 'select'"
			:placeholder="placeholder"
			:type="type"
			:name="name"
			:autocomplete="autocomplete ? 'on' : 'off'"
			:id="`${_uid}_${name}`"
			class="form__control" 
			:autofocus="autofocus"
			v-model="localValue" 
			@focus="handlerFocus" 
			@blur="hanlderBlur" 
			@input="handlerInput"  
			:disabled="disabled"
			:max="max"
			v-mask="mask"
			>
			<select v-else
			:name="name"
			:autocomplete="autocomplete ? 'on' : 'off'"
			:id="`${_uid}_${name}`"
			class="form__control" 
			:autofocus="autofocus"
			v-model="localValue" 
			@focus="handlerFocus" 
			@blur="hanlderBlur" 
			@change="handlerInput" 
			:disabled="disabled" 
			>
				<slot></slot>
			</select>
			<label :for="`${_uid}_${name}`" class="form__label">{{label}}</label>
			<span class="form__badge" v-if="badge">{{badge}}</span>
		</div>
		<p v-if="error" class="form__error">
			{{error}}
		</p>
	</div>
</template>

<style lang="scss" scoped>
.form__wrap{
	position: relative;
}
.form__badge{
position: absolute;
right: 5px;
z-index: 1;
height: 41px;
padding: 0.5rem;
display: flex;
align-items: center;
text-align: center;
top: 1px;
background: #fff;
border-radius:  0.55rem;
}
</style>