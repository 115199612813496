<script>
import Loader from '../components/Loader.vue'

import http from '../http'
export default {
    components: {
        Loader
    },
    data() {
        return {
            orders:  [],
            pagination: null,
            types: {
                10: 'Productos',
                15: 'Terjetas'
            },
            // const STATUS_CART = 1;
            // const STATUS_PENDING = 10;
            // const STATUS_COMPLETE = 20;
            // const STATUS_INCOMPLETE = 40;
            status: {
                1: 'En carrito',
                10: 'Pendiente',
                20: 'Completado',
                40: 'Rechazada',
                60: 'Cancelada',
                80: 'Recibida',
            },
            loading:  true,
        }
    },

    methods: {
        async fetchOrders(page = 1) {
            let vm = this
            this.loading = true
            try {
                let orders = await http.orders.list(page)
                vm.$set(vm, 'orders', orders.data)
                delete orders.data
                vm.$set(vm, 'pagination', orders)
                this.loading = false

            } catch (error) {
                console.log(error)
                this.loading = false

            }
        },

        orderNumber(id) {
            return window.pad(id, 6)
        }
    },

    mounted() {
        this.fetchOrders()
    }
}
</script>

<template>
    <div class="container">
        <div>
            <h3 class="title mb-0">Pedidos</h3>
        </div>
        <div class="d-flex flex-wrap">
            <div class="col-12">
                <div v-if="!loading" class="box overflow-hidden p-0">
                    <table class="table">
                        <thead>
                            <tr>
                                <th class="text-center">N° de Pedido</th>
                                <th class="text-center">Centro de costo</th>
                                <th class="text-center">Tipo de Pedido	</th>
                                <th class="text-center">Fecha del Pedido	</th>
                                <th class="text-center">Estatus</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody v-if="orders.length">
                            <tr v-for="(order, index) in orders" :key="`order_${index}_${order.id}`">
                                <td class="text-center">{{orderNumber(order.id)}}</td>
                                <td class="text-center">{{order.ccosto_assigned}}</td>
                                <td class="text-center">{{types[order.type]}}</td>
                                <td class="text-center">{{order.ordered_at | moment('D/M/Y')}}</td>
                                <td class="text-center">{{status[order.status]}}</td>
                                <td class="text-end">
                                    <router-link 
                                    :to="{name: 'orders-detail', params: {id:  order.id}}"
                                    class="btn btn-primary btn-sm fw-bold text-uppercase">
                                        Ver detalles
                                        <i class="fas fa-eye ms-2"></i>
                                    </router-link>
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <td colspan="5" class="text-center">
                                    No tienes pedidos
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="d-flex mt-3" v-if="pagination">
                        <pagination class="mx-auto" :limit="2" :data="pagination" @pagination-change-page="fetchOrders"></pagination>
                    </div>
                </div>
                <div class="d-flex justify-content-center" v-else-if="loading">
                    <loader>
                        <p class="m-0">Cargando datos pedidos...</p>
                    </loader>
                </div>
            </div>
        </div>
    </div>
</template>