

export default {
	period: null,
	cart: null,
	order_types: {
		products: 10,
		cards: 15,
		general_request: 20,
	},
	catalog: null,
	unavailable: null,
}