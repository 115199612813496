<script>
import _ from 'lodash'
import Loader from '../components/Loader.vue'
import { mapGetters } from 'vuex'
export default {
	components: {
		Loader
	},
	data() {
		return {
			permissions: {},
			openCategories: [],
			listUpdatedPermission: [],
			loading: false,
			exceptions: [],
			exceptionsCategories: [],
			exceptionsProducts: [],
			exceptionsProductsExclude: [],
		}
	},
	computed: {
		
		role() {
			if(this.$route.name != 'access-management-role') {
				return null
			}
			return this.getRole(this.$route.params.id)
		},
		ccosto() {
			if(this.$route.name != 'access-management-ccosto') {
				return null
			}
			return this.getCcosto(this.$route.params.id)
		},
		calcPermissions() {
			let vm = this 
			let permissionsState = {};
			vm.categories.forEach(category => {
				let categoryPermissions = {}
				let products = vm.getProdutsByCategory(category.id)
				
				products.forEach(product => {
					let hasPermission = false;
					if (vm.role) {
						// eslint-disable-next-line no-bitwise
						hasPermission = ((1 << vm.role.key) & product.permissions) > 0;
					} else if(this.ccosto)  {
					// eslint-disable-next-line no-bitwise
						hasPermission = (vm.ccosto.permissions & product.permissions) > 0;
						const exception = _.find(vm.exceptions, {
							product_id: product.id,
							type: hasPermission ? -1 : 1,
						});
						if (exception) {
							hasPermission = !hasPermission;
						}
					}
					categoryPermissions[product.id] = hasPermission;
				})
				permissionsState[category.id] = categoryPermissions
			});


			return permissionsState
		},

		calcPermissionsProducts() {
			let products = {}

			for (const c in this.calcPermissions) {
				let _products = this.calcPermissions[c]

				for (const p in _products) {
					products[p] = _products[p]
				}
			}

			return products
		},
		...mapGetters({
			getRole: 'app/getRoleById',
			getCcosto: 'app/getCcosto',
			getProdutsByCategory: 'app/getProdutsByCategory',
			categories: 'app/categories',
			products: 'app/products',
		})
	},

	watch: {
		calcPermissions: {
			handler(nVal) {
				this.$set(this, 'permissions', JSON.parse(JSON.stringify(nVal)))
			},
			deep: true,
			immediate: true
		},

		ccosto: {
			handler(nVal) {
				if(nVal) {
					this.fetchExceptions()
				}
			},
			deep: true,
			immediate: true,
		},

		products: {
			handler(nVal) {
				if(nVal.length && this.exceptions.length) {
					this.setException()
				}
			}
		},

		exceptions: {
			handler(nVal) {
				if(nVal.length && this.products.length) {
					this.setException()
				}
			}
		}
	},

	methods: {
		getCategoryPermissions(id) {
			let products = this.permissions[id]

			let size = 0
			let permissions = 0


			for (const key in products) {
				size += 1
				if(products[key]) {
					permissions += 1 
				}
			}

			return {
				size,
				permissions
			}
		},
		
		getProductPermissions(id, category) {
			let products = this.permissions[category]

			return products[id]
		},

		handlerToggleOpen(category) {
			let index = this.openCategories.findIndex(c => c == category)
			
			if(index >= 0) {
				this.openCategories.splice(index, 1)
			}  else {
				this.openCategories.push(category)
			}
		},

		togglePermission(id, category) {
			let products = this.permissions[category]
			products[id] = !products[id]
			
			this.addToUpdateList(id, products[id])
			this.setException()
		},

		togglePermissionCategory(event, id) {
			let status = event.target.checked
			let products = this.permissions[id]
			
			for (const key in products) {
				products[key] = status

				this.addToUpdateList(key, products[key])
			}

			this.setException()
		},

		addToUpdateList(product_id, has_permission) {
			let updated = this.listUpdatedPermission.find(p => p.product_id == product_id)
			if(updated) {
				if(this.originPermissionMatch(product_id, has_permission)) {
					let index = this.listUpdatedPermission.findIndex(p => p.product_id == product_id)
					this.listUpdatedPermission.splice(index, 1)
				} else {
					updated.has_permission = has_permission
				}
			} else {
				this.listUpdatedPermission.push({product_id, has_permission})
			}
		},

		setException() {
			let vm = this

			let exceptionsProducts = []
			let exceptionsProductsExclude = []
			let exceptionsCategories = []
			vm.products.forEach(product => {
				let newException = vm.listUpdatedPermission.find(p => p.product_id ==  product.id)
				if(!newException) {
					let isException = vm.exceptions.find(e => e.product_id == product.id)
					if(!isException) {
						return
					} 

					if(isException.type == 1 ) {
						exceptionsProducts.push(product.id)
						let indexC = exceptionsCategories.findIndex(c => c == product.category_id)
						if(indexC < 0) {
							exceptionsCategories.push(product.category_id)
						}
					} else {
						exceptionsProductsExclude.push(product.id)
						let indexC = exceptionsCategories.findIndex(c => c == product.category_id)
						if(indexC < 0) {
							exceptionsCategories.push(product.category_id)
						}
					}

					return
				}

				
				let isException = vm.exceptions.find(e => e.product_id == product.id)

				if(newException.has_permission && !vm.originPermissionMatch(product.id, newException.has_permission) && !isException) {
					exceptionsProducts.push(product.id)	
					let indexC = exceptionsCategories.findIndex(c => c == product.category_id)
					if(indexC < 0) {
						exceptionsCategories.push(product.category_id)
					}
				} else if(!newException.has_permission && !vm.originPermissionMatch(product.id, newException.has_permission) && !isException) {
					exceptionsProductsExclude.push(product.id)	
					let indexC = exceptionsCategories.findIndex(c => c == product.category_id)
					if(indexC < 0) {
						exceptionsCategories.push(product.category_id)
					}
				}
				
			})

			vm.$set(vm, 'exceptionsProducts', exceptionsProducts)
			vm.$set(vm, 'exceptionsProductsExclude', exceptionsProductsExclude)
			vm.$set(vm, 'exceptionsCategories', exceptionsCategories)

		},

		originPermissionMatch(id, status) {
			return this.calcPermissionsProducts[id] == status
		},

		async savePermissions() {
			let vm = this
			if(!vm.listUpdatedPermission.length) {
				return 
			}
			vm.loading = true
			try {
				if(vm.role) {
					let respose = await vm.$http.permissions.save(vm.role.slug, {products: vm.listUpdatedPermission})
				} else if(vm.ccosto) {
					let respose = await vm.$http.permissions.saveExceptions(vm.ccosto.ccosto, {exceptions: vm.listUpdatedPermission})
				}
				vm.loading = false
				vm.$set(vm, 'listUpdatedPermission', [])
			} catch (error) {
				vm.loading = false
				
			}
		},

		async fetchExceptions() {
			let vm = this
			try {
				let data = await vm.$http.permissions.exceptions(this.ccosto.ccosto)
				vm.$set(vm, 'exceptions', data)

			} catch (error) {
				
			}
		}

		

	},


}
</script>

<template>
	<div class="container">
		<div class="d-flex align-items-center px-3 mb-2">
			<div>
				<h3 v-if="role" class="title m-0">Rol: {{role.name}}</h3>
				<h3 v-if="ccosto" class="title m-0">Ccosto: {{ccosto.name}}</h3>
				<p class="sub-title m-0">Marcar/desmarcar productos para asignar permisos</p>
			</div>
			<div class="ms-auto">
				<router-link :to="{name: 'access-management'}" class="btn btn-danger btn-sm text-white" :disabled="loading">Cancelar</router-link>
				<button class="btn btn-primary btn-sm ms-1" @click="savePermissions" :disabled="loading">Guardar</button>
			</div>
		</div>
		<div v-if="!loading && categories.length" class="box p-0">
			<ul class="permission__categories">
				<li class="permission__category" v-for="(category, index) in categories" :key="`category_${index}_${category.id}`">
					<div class="permission__category-title" @click="handlerToggleOpen(category.id)">
						<span
							:class="{
								'text-success':  exceptionsCategories.includes(category.id)
							}"
						>
							{{category.name}} 
						</span>
						<label class="input-check input-check--multi ms-auto">
							<input type="checkbox" :checked="getCategoryPermissions(category.id).permissions" @change="togglePermissionCategory($event, category.id)">
							<span class="input-check__state" 
								:class="{
									'input-check__state--incomplete': getCategoryPermissions(category.id).permissions != getCategoryPermissions(category.id).size}">
							</span>
						</label>
					</div>
					<ul v-if="openCategories.includes(category.id)" class="permission__products">
						<li class="permission__product d-flex" v-for="(product, index) in getProdutsByCategory(category.id)" :key="`product_${index}_${product.id}`">
							<span :class="{
								'text-success': exceptionsProducts.includes(product.id) ,
								'text-danger': exceptionsProductsExclude.includes(product.id) 
							}">
							{{product.name}}  
							</span>
							
							<span class="ms-auto"> 
								<label class="input-check input-check--multi ms-auto">
									<input type="checkbox" :checked="getProductPermissions(product.id, category.id)" @change="togglePermission(product.id, category.id)"> 
									<span class="input-check__state"></span>
								</label>
							</span>
						</li>
					</ul>
				</li>
			</ul>
		</div>
		<div v-else-if="loading" class="text-center py-5">
			<loader>
				<p class="m-0">Guardando permisos...</p>
			</loader>
		</div>
	</div>
</template>