<script>
import { mapActions, mapGetters } from 'vuex'
import ModalVue from '../components/ModalVue.vue'
import FormControl from '../components/FormControl.vue'
import Loader from '../components/Loader.vue'
import http from '../http'
import Autocomplete from '../components/Autocomplete.vue'

export default {
	components: {
		FormControl,
		ModalVue,
		Loader,
		Autocomplete
	},
	data() {
		return {
			selectedCategory: null,
			query: '',
			saving: false,
			selectedProduct: null,
			qty: 1,
			limit: null,
			form: {
				ccosto_assigned: '',
				address: '',
				responsable: '',
				responsable_2: '',
				phone: '',
				phone_2: '',
			},
			errors: {
				ccosto_assigned: '',
				address: '',
				responsable: '',
				responsable_2: '',
				phone: '',
				phone_2: '',
			},

			extra: {
				name: '',
				qty: ''
			},

			chekout: false,

			pendingOrders: [],

			ccosto_assigned: null,
		}
	},

	computed: {
		hasPedingOrders() {
			if(!this.pendingOrders.length) {
				return false
			}

			return this.pendingOrders.find(o => o.ccosto_assigned == this.form.ccosto_assigned)
		},
		filteredProducts() {
			if(!this.catalog) return []

			

			if(!this.selectedCategory && !this.query) return this.products
			let products = this.products.filter(p => {
				let match = true

				if(this.selectedCategory) {
					if(match) {
						match = p.category_id == this.selectedCategory
					}
				}

				if(this.query) {
					if(match) {
						let name = p.name.toLowerCase()
						let query = this.query.toLowerCase()
						match = name.includes(query)
					}
				}

				return match
			})

			return products
		},
		limitProduct() {
			if(!this.selectedProduct) return null

			if(this.selectedProduct.role_limits.hasOwnProperty(this.user.permissions)) {
				// this.qty = this.selectedProduct.role_limits[this.user.permissions]
				let limit = Number(this.selectedProduct.role_limits[this.user.permissions])

				if(!limit) {
					return {
						limit: limit,
						available: Infinity
					}
				}
				let available = limit
				
				let inCart = this.inCart(this.selectedProduct.id)

				if(inCart) {
					available = available - inCart.pivot.quantity
				}

				return {
					limit,
					available
				}
			}

			return null
		},
		
		...mapGetters({
			orderType: 'order/orderType',
			period: 'order/period',
			cart: 'order/cart',
			inCart: 'order/inCart',
			catalog: 'order/catalog',
			products: 'order/products',
			unavailable: 'order/unavailable',
			user: 'auth/user',
			ccostos: 'app/ccostos',
		})
	},

	methods: {
		openCart() {
			if(!this.cart) return
			if(!(this.cart.products.length+this.cart.extra_products.length )) return 

			this.$refs.cart.open()
		},
		errorLoadImage(event) {
			event.target.src = '/img/missing.png'
		},
		selectProduct(product){
			this.$set(this, 'selectedProduct', product)

			this.$refs.addToCart.open()
		},
		setCategory(id) {
			if(this.selectedCategory == id) {
				this.selectedCategory = null
			} else {
				this.selectedCategory = id
			}
		},
		checkLimit() {
			debugger
			if(this.limitProduct.available !== Infinity) {
				debugger
				if(this.qty > Number(this.limitProduct.available)) {
					this.qty = Number(this.limitProduct.available)
				} else if (this.qty < 1) {
					this.qty = 1
				}
			} else if(this.qty < 1 && this.qty !== '') {
				debugger
				this.qty = 1
			} else if(this.qty >  this.limitProduct.available) {
				debugger
				this.qty = Number(this.limitProduct.available)
			} else if(this.qty == '') {
				this.qty = 1
			}
		},

		limitInCart(product) {
			let inCart = this.inCart(product.id)

			if(!inCart) return false

			let limit = null

			if(product.role_limits.hasOwnProperty(this.user.permissions)) {
				limit = product.role_limits[this.user.permissions]
			}


			if(!limit) return false

			return limit == inCart.pivot.quantity
		},
		validForm() {
			let valid = true
			for (const key in this.form) {
				if(!this.form[key]) {
					this.errors[key] = 'Es requerido'
					if(valid) {
						valid = false
					}
				}
					
			}

			if(!this.cart.products.length && !this.cart.extra_products.length) {
				valid = false
				this.$notify({
					group: 'b_r',
					title: 'El carrito está vacío',
					text: 'No puedes continuar con esta orden.',
					type: 'danger'
				});
			}

			return valid
		},
		clearError(key) {
			this.$set(this.errors, key, '')
		},

		showExtraModal() {
			this.$refs.addExtraModal.open()
		},

		async handlerRemove(product) {
			let vm = this
			let payload = {
				type: vm.orderType('products'),
				data: {
					type: "product",
					product_id: product.id,
					_method: "DELETE",
				}
			}
			try {
				let response = await vm.removeFromCart(payload)
				if(!this.cart.products.length) {
					this.$refs.cart.close()
				}
			} catch (error) {
				
				console.log(error)
			}
		},
		async handlerRemoveExtra(product) {
			let vm = this
			let payload = {
				type: vm.orderType('products'),
				data: {
					type: "extra_product",
					extra_product_id: product.id,
					_method: "DELETE",
				}
			}
			try {
				let response = await vm.removeFromCart(payload)
				if(!this.cart.products.length) {
					this.$refs.cart.close()
				}
			} catch (error) {
				
				console.log(error)
			}
		},
		async handlerAddToCart() {
			let vm = this
			let payload = {
				type: vm.orderType('products'),
				type_name: 'product',
				qty: vm.qty,
				id: vm.selectedProduct.id
			}

			vm.saving = true

			try {
				let data = await vm.addToCart(payload)
				let type = vm.orderType('products')
				vm.$set(vm, 'selectedProduct', null)
				vm.getCart(type).finally(res => {
					
					vm.saving = false
					vm.$refs.addToCart.close()
				})
			} catch (error) {
				vm.saving = false
				vm.$refs.addToCart.close()
			}

		},
		async handlerAddExtraToCart() {
			let vm = this
			let payload = {
				type: vm.orderType('products'),
				type_name: "extra_product",
				name: this.extra.name,
				qty: this.extra.qty,
			}

			vm.saving = true

			try {
				let data = await vm.addToCart(payload)
				let type = vm.orderType('products')
				vm.$set(vm, 'selectedProduct', null)
				vm.getCart(type).finally(res => {
					
					vm.saving = false
					vm.$refs.addExtraModal.close()
				})
				vm.extra.name = ""
				vm.extra.qty = ""
			} catch (error) {
				vm.saving = false
				vm.$refs.addExtraModal.close()
			}

		},

		async handlerCheckout() {
			let vm = this
			let valid = this.validForm()
			if(this.ccosto_assigned) {
				this.form.ccosto_assigned = this.ccosto_assigned.ccosto
			}
			if(!valid) {
				return
			}
			vm.chekout = true
			try {
				let data = await this.checkoutCart({
					type: this.orderType('products'),
					ccosto_assigned: this.form.ccosto_assigned,
					address: this.form.address,
					responsable: this.form.responsable,
					responsable_2: this.form.responsable_2,
					phone: this.form.phone,
					phone_2: this.form.phone_2,
				})
				vm.$notify({
					group: 'b_r',
					title: 'Enviada',
					text: 'Tu orden ha sido enviada con exito.',
					type: 'danger'
				});
				vm.$refs.cart.close()
				vm.$router.push({name: 'orders-store'})
				vm.chekout = false
			} catch (error) {
				vm.$notify({
					group: 'b_r',
					title: 'ERROR',
					text: 'Tu orden no contiene los datos necesarios preciona CTRL R.',
					type: 'danger'
				});
				console.log(error)
				vm.chekout = false
			}
		},

		async fetchPendingOrders() {
			let vm = this

			try {
				let {data} = await http.orders.pedding()

				this.$set(this, 'pendingOrders', data)
			} catch (error) {
				
			}
		},

		orderNumber(id) {
            return window.pad(id, 6)
        },

		...mapActions({
			getNextOrderPeriod: 'order/getNextOrderPeriod',
			getCart: 'order/getCart',
			getCatalog: 'order/getCatalog',
			addToCart: 'order/addToCart',
			checkoutCart: 'order/checkoutCart',
			removeFromCart: 'order/removeFromCart',
		})
	},

	mounted() {
		let type = this.orderType('products')
		this.getCatalog()
		this.getNextOrderPeriod(type)
		this.fetchPendingOrders()
		this.getCart(type)
	},
}
</script>

<template>
	<div class="container">
		<modal-vue ref="addExtraModal">
			<template v-slot:header>
				<h5 class="sub-title m-0">Agregar artículo extra</h5>
			</template>
			<template v-slot:body={close}>
				<div v-if="!saving">
					<!-- <pre>{{selectedProduct}}</pre> -->
					<div class=" py-2">
						
						<div class="alert alert-primary" role="alert">
							Ingresa el nombre del artículo que requieres, que no aparece en el catálogo.
						</div>

						<div class="d-flex flex-wrap">
							<div class="col-12 col-md-6 col-lg-8 px-2 py-3">
								<FormControl
									label="Nombre del artículo"
									name="extra_name"
									v-model="extra.name"
									type="text"
								/>
							</div>
							<div class="col-12 col-md-6 col-lg-4 px-2 py-3">

								<FormControl
									label="Cantidad"
									name="extra_qty"
									v-model="extra.qty"
									type="number"
								/>
							</div>
						</div>
					</div>
					<div class="d-flex py-2 border-top mx-n3 px-3">
						<button @click="close()" class=" btn btn-transparent btn-sm ms-auto">Cancelar</button>
						<button   @click="handlerAddExtraToCart"  class=" btn btn-primary text-white">Agregar</button>
					</div>
					<!-- <h6 class="text-center">¿Seguro desea  inhabilitar a {{userSelected.first_name}}?</h6>
					<div class="d-flex">
						<button @click="close()" class="ms-md-auto btn btn-transparent btn-sm">Cancelar</button>
						<button @click="disableUser" class="me-auto btn btn-danger btn-sm text-white">Si, continuar</button>
					</div> -->
				</div>
				<div v-else-if="saving" class="d-flex justify-content-center">
					<loader>
						<p class="m-0">Agregando...</p>
					</loader>
				</div>
			</template>
		</modal-vue>
		<modal-vue ref="addToCart" @close="qty = 1">
			<template v-slot:header>
				<h5 class="sub-title m-0">Agregar al carrito</h5>
			</template>
			<template v-slot:body={close}>
				<div v-if="!saving && selectedProduct">
					<!-- <pre>{{selectedProduct}}</pre> -->
					<div class="d-flex py-2">
						<!-- <div class="modal__image">
							<img @error="errorLoadImage" :src="selectedProduct.image_url" :alt="selectedProduct.name">
						</div> -->
						<div class="ms-3">
							<h5 class="text-primary">{{selectedProduct.name}}</h5>
							<div v-if="limitProduct">
								<p class="text-danger m-0" v-if="limitProduct.available !== Infinity">
									Solo puedes solicitar {{limitProduct.limit}} {{selectedProduct.measure_unit}}.
								</p>
							</div>
							<div class="boder-top py-3" v-if="limitProduct">
								<div class="d-flex" v-if="limitProduct.available">
									<FormControl
										label="Solicitar"
										name="name"
										:badge="selectedProduct.measure_unit"
										:max="limitProduct ? Number(limitProduct.available) : 100000000"
										v-model="qty"
										@keyup="checkLimit"
										@blur="checkLimit"
										type="number"
									/>
								</div>
								<div v-else>
									<p class="text-danger m-0">Ya has alcanzado el limite de este artículo</p>
								</div>
							</div>
							<div class="boder-top py-3" v-else>
								<FormControl
									label="Solicitar"
									name="name"
									:badge="selectedProduct.measure_unit"
									v-model="qty"
									type="number"
								/>
							</div>
							<div v-if="inCart(selectedProduct.id)">
								<p>Tienes {{inCart(selectedProduct.id).pivot.quantity}} {{selectedProduct.measure_unit}} agregado en el carrito</p>
							</div>
						</div>
					</div>
					<div class="d-flex py-2 border-top mx-n3 px-3">
						<button @click="close()" class=" btn btn-transparent btn-sm ms-auto">Cancelar</button>
						<button  v-if="(limitProduct ? limitProduct.available : true)" @click="handlerAddToCart"  class=" btn btn-primary text-white">Agregar</button>
					</div>
					<!-- <h6 class="text-center">¿Seguro desea  inhabilitar a {{userSelected.first_name}}?</h6>
					<div class="d-flex">
						<button @click="close()" class="ms-md-auto btn btn-transparent btn-sm">Cancelar</button>
						<button @click="disableUser" class="me-auto btn btn-danger btn-sm text-white">Si, continuar</button>
					</div> -->
				</div>
				<div v-else-if="saving" class="d-flex justify-content-center">
					<loader>
						<p class="m-0">Agregando...</p>
					</loader>
				</div>
			</template>
		</modal-vue>
		<modal-vue ref="cart">
			<template v-slot:header>
				<h5 class="sub-title m-0">Mi carrito</h5>
			</template>
			<template v-slot:body={close}>
				<div v-if="!chekout" style="width: 500px">
					<!-- <pre>{{selectedProduct}}</pre> -->
					<div class="py-2" v-if="cart">
						<div v-for="(product, index) in cart.products" :key="`c_${index}`" class="product__item p-3">
							<!-- <div class="product__image">
								<img :src="product.image_url" @error="errorLoadImage" :alt="product.name">
							</div> -->
							<div class="px-2 flex-grow-1 w-50">
								<h3 class="product__name mb-2">{{product.name}}</h3>
								<h3 class="product__name m-0">Cantidad: <span class="text-complementary fw-bold">{{product.pivot.quantity}}</span> 
								<button class="btn btn-sm btn-danger text-white ms-2" @click="handlerRemove(product)"><i class="fas fa-trash-alt"></i></button></h3>
							</div>
						</div>
						<div v-for="(product, index) in cart.extra_products" :key="`ce_${index}`" class="product__item p-3">
							<!-- <div class="product__image">
								<img :src="product.image_url" @error="errorLoadImage" :alt="product.name">
							</div> -->
							<div class="px-2 flex-grow-1 w-50">
								<h3 class="product__name mb-2">{{product.name}}</h3>
								<h3 class="product__name m-0">Cantidad: <span class="text-complementary fw-bold">{{product.pivot.quantity}}</span> 
								<button class="btn btn-sm btn-danger text-white ms-2" @click="handlerRemoveExtra(product)"><i class="fas fa-trash-alt"></i></button></h3>
							</div>
						</div>

					</div>
					<div class="border-top py-2">
						<div class="py-3" >
							<div class="postion-relative" style="z-index: 7"> 
								<autocomplete
								class="mb-0"
								label="Centro de costo"
								name="ccosto_search"
								v-model="ccosto_assigned"
								:selected-label="(option) => {
									return`${option.ccosto} - ${option.name}`
								}"
								:options="ccostos"
								:filter="(options, query) => {
									let filtered = options.filter(o => {
										let ccosto = o.ccosto.toString().toLowerCase()
										let name  = o.name.toLowerCase()
										let match = false
										query = query.toLowerCase()
										
										if(!match) {
											match = ccosto.includes(query)
										}
										if(!match) {
											match = name.includes(query)
										}

										return match
									})

									return filtered
								}"
								>
								<template v-slot:option="{option}">
									{{ option.ccosto }} - {{option.name}}
								</template>
								</autocomplete>
							</div>

							<!-- <FormControl
							v-if="user.ccostos_assigned.length"
							label="Centro de costo"
							name="ccosto"
							v-model="form.ccosto_assigned"
							:error="errors.ccosto_assigned"
							@input="clearError('ccosto_assigned')"
							type="select">
								
								<option v-for="(ccosto, index) in user.ccostos_assigned" :key="`c_${index}_${ccosto}`" :value="ccosto">{{ccosto}}</option>
							</FormControl> -->
							<!-- <FormControl
							v-else
							label="Centro de costo"
							name="ccosto"
							v-model="form.ccosto_assigned"
							:error="errors.ccosto_assigned"
							@input="clearError('ccosto_assigned')"
							type="select">
								
								<option  :value="user.ccosto">{{user.ccosto}}</option>
							</FormControl> -->
							<p class="text-muted mb-0">Seleciona el centro de costo</p>
							<p  v-if="hasPedingOrders" class="m-0 text-danger">
								Para poder realizar un nuevo pedido debes confirmar la orden  <router-link :to="{name: 'orders-detail', params: {id:  hasPedingOrders.id}}">{{orderNumber(hasPedingOrders.id)}}</router-link>  
							</p>
						</div>
						<div class="py-3">
							<FormControl
								label="Dirección"
								name="name"
								v-model="form.address"
								type="textarea"
								:error="errors.address"
								@input="clearError('address')"
							/>
							<p class="text-muted mb-0">Escribe la dirección completa incluyendo el código postal</p>
						</div>
						<div class="py-3">
							<FormControl
								label="Nombre responsable 1"
								name="name"
								v-model="form.responsable"
								type="text"
								:error="errors.responsable"
								@input="clearError('responsable')"
							/>
						</div>
						<div class="py-3">
							<FormControl
								label="Teléfono responsable 1"
								name="name"
								v-model="form.phone"
								type="text"
								mask="### ### #### ####"
								:error="errors.phone"
								@input="clearError('phone')"
							/>
						</div>
						<div class="py-3">
							<FormControl
								label="Nombre responsable 2"
								name="name"
								v-model="form.responsable_2"
								type="text"
								:error="errors.responsable_2"
								@input="clearError('responsable_2')"
							/>
						</div>
						<div class="py-3">
							<FormControl
								label="Teléfono responsable 2"
								name="name"
								v-model="form.phone_2"
								type="text"
								mask="### ### #### ####"
								:error="errors.phone_2"
								@input="clearError('phone_2')"
							/>
						</div>
					</div>
					<div class="d-flex py-2 border-top mx-n3 px-3 align-items-center">
						<p v-if="!period.can_order" class="m-0 text-danger">No es posible realizar pedidos actualmente.</p>
						<p  v-if="hasPedingOrders" class="m-0 text-danger">
							Tienes una orden pendiente de confirmar para el Centro de costo {{form.ccosto_assigned}}
						</p>
						<button @click="close()" class=" btn btn-transparent btn-sm ms-auto">Cancelar</button>
						<button v-if="period.can_order && !hasPedingOrders" @click="handlerCheckout" class=" btn btn-primary text-white">Enviar Orden</button>
					</div>
					<!-- <h6 class="text-center">¿Seguro desea  inhabilitar a {{userSelected.first_name}}?</h6>
					<div class="d-flex">
						<button @click="close()" class="ms-md-auto btn btn-transparent btn-sm">Cancelar</button>
						<button @click="disableUser" class="me-auto btn btn-danger btn-sm text-white">Si, continuar</button>
					</div> -->
				</div>
				<div v-else-if="chekout" class="d-flex justify-content-center">
					<loader>
						<p class="m-0">Enviando orden...</p>
					</loader>
				</div>
			</template>
		</modal-vue>
		<div class="d-flex align-items-center mb-3">
			<div>
				<h3 class="title m-0">Productos</h3>
				<p class="m-0">Catálogo de productos</p>
			</div>
			<div class="ms-auto">
				<button class="btn btn-sm btn-primary ms-2 fw-bold" @click="openCart">VER CARRITO <span v-if="cart">({{cart.products.length + cart.extra_products.length }})</span><i class="fas fa-shopping-cart"></i></button>
			</div>

		</div>
		<!-- <pre>{{catalog }}</pre> -->
		<div v-if="user.roles.includes('corporativo') || user.roles.includes('atusuarios')" class="mb-2">
			<button @click="showExtraModal" class="btn btn-primary btn-sm fw-bold text-white">AGREGAR PRODUCTO EXTRA <i class="fas fa-plus ms-2"></i></button>
		</div>
		<div class="d-flex flex-wrap" v-if="catalog">
			<div class="col-12 col-md-5 px-0 me-auto">
				<h5 class="fw-bold text-primary">Filtrar por categoría</h5>
				<ul class="store__categories">
					<li v-for="(category, index) in catalog.categories" :key="`c_${index}_${category.id}`"
					class="store__category">
						<button class="w-100 btn text-start"
						:class="{'active': category.id == selectedCategory}"
						@click="setCategory(category.id)">
							{{category.name}}
						</button>
					</li>
				</ul>

				<div class="mt-5">
					<h5 class="fw-bold text-primary">Buscar artículos</h5>
					<div class="alert alert-primary" role="alert">
						Ingresa el nombre del artículo que requieres.
					</div>

					<div class="mt-3 box p-3 py-4">
						<FormControl
							label="Nombre del artículo "
							name="name"
							v-model="query"
						/>
					</div>
				</div>

			</div>
			<div class="col-12 col-md-6 border-start px-3">
				<div class="store__products">
					<div class="d-flex flex-wrap">
						<div v-for="(product, index) in filteredProducts" :key="`p_${index}_${product.id}`" class="col-12 p-2">
							<div class="product__item box">
								<!-- <div class="product__image">
									<img :src="product.image_url" @error="errorLoadImage" :alt="product.name">
								</div> -->
								<div class="px-2 flex-grow-1 w-50 d-flex flex-wrap align-items-center">
									<h3 class="product__name m-0">{{product.name}}</h3>
									<div class="ms-auto p-2">

										<button v-if="limitInCart(product)" class="btn btn-danger text-white  text-uppercase btn-sm " @click="selectProduct(product)">
											<span class="fw-bold">{{inCart(product.id).pivot.quantity}} Limite alcanzado </span>
										</button>
										<button v-else-if="inCart(product.id)" class="btn btn-primary  text-uppercase btn-sm " @click="selectProduct(product)">
											<span class="fw-bold">{{inCart(product.id).pivot.quantity}} En carrito</span> <i class="fas fa-pen"></i>
										</button>
										<button v-else class="btn btn-primary  text-uppercase btn-sm " @click="selectProduct(product)"><span class="fw-bold">Agregar Al Carrito</span> <i class="fas fa-cart-plus"></i></button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		
	</div>
</template>