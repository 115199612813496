<script>
import { mapActions, mapGetters } from 'vuex'
import FormControl from '../components/FormControl.vue'
import Loader from '../components/Loader.vue'
export default {
	components: {
		FormControl,
		Loader
	},
	data() {
		return {
			form: {
				first_name: '',
				last_name: '',
				phone: '',
				email: '',
			},
			errors: {
				first_name: '',
				last_name: '',
				phone: '',
				email: '',
			},
			saving: false,
		}
	},

	computed: {
		product() {
			if(this.$route.name != 'products-edit') return null
			return this.productById(Number(this.$route.params.id))
		},
		...mapGetters({
			roles: 'app/roles',
			user: 'auth/user',
			categories: 'app/categories',
			productById: 'app/productById'
		})
	},

	watch: {
		'user': {
			handler(nVal) {
				if(nVal) {
					this.completeForm()
				}
			},
			deep: true,
			immediate: true
		}
	},

	methods: {
		
		getKey(key) {
			return 1 << key
		},

		updatePhoto(event) {
			let vm = this
			if(!event.target.files.length) {
				this.file = null
				this.image_url = null
				return 
			}
			let file = event.target.files[0]
			this.$set(this, 'file', file)
			this.image_url = URL.createObjectURL(file)
		},

		addPermission(event) {
			let value = Number(event.target.value)
			
			if(event.target.checked) {
				this.$set(this.form.role_limits, value, '')
				this.form.permissions += value
			} else {
				this.form.permissions -= value
				if(this.form.role_limits.hasOwnProperty(value)) {

					delete this.form.role_limits[value]
					let data = JSON.stringify(this.form.role_limits)
					data = JSON.parse(data)
					this.$set(this.form, 'role_limits', data)
				}
			}
		},

		valid() {
			let valid = true
			let fields = {
				first_name: 'el nombre',
				last_name: 'los apellidos',
				phone: 'la extensión',
				email: 'el email',
			}
			for (const key in this.form) {
				if(fields.hasOwnProperty(key)) {
					if(!this.form[key]) {
						this.$set(this.errors, key,  `Ingresa ${fields[key]}`)
	
						if(valid) {
							valid = false
						}
					}
				} 

			}
			return valid
		},

		clearError(key) {
			this.$set(this.errors, key, '')
		},

		resetForm() {
			this.$set(this, 'form', {
				name: '',
				id_people: null,
				mba_code: null,
				measure_unit: '',
				category_id: null,
				price: null,
				permissions: 0,
				role_limits: {
					
				},
			})

			this.$set(this, 'ch_permissions', [])
		},

		async handlerSubmit() {
			let vm = this

			if(!vm.valid()) return false
			vm.saving = true

			
			

			
			try {

			    let response = await vm.$http.auth.profile(vm.form)
				vm.saving = false
				
			} catch (error) {
				vm.saving = false
			}
		},

		completeForm() {
			let user = JSON.parse(
				JSON.stringify(this.user)
			)

			

			this.$set(this, 'form', {
				first_name: user.first_name,
				last_name: user.last_name,
				phone: user.phone,
				email: user.email,
			})


			// this.$set(this, 'ch_permissions', Object.keys(product.role_limits))
		},

		errorLoadImage(event) {
			event.target.src = '/img/missing.png'
		},

        ...mapActions({
            'fetchUser': 'auth/fetchUser'
        })
	},

	mounted() {
        this.fetchUser()
		if(this.user) {
            this.completeForm()
        }
	}
}
</script>
<template>
	<div class="container">
        <div class="d-flex">
            <div class="col-12 col-md-10 col-lg-7 mx-auto">
		        <h2 class="title mb-0">Perfil</h2>
                <div v-if="saving">
                    <loader>
                        <p class="text-center">Guardando..</p>
                    </loader>
                </div>
                <div v-else>
                    <p class="">Ingrese los datos</p>
                    <div class="box p-3 mb-4">
                        <div class="d-flex flex-wrap">
                            <div class="col-12 col-md-6  p-3">
                                <FormControl
                                    label="Nombre"
                                    name="first_name"
                                    :error="errors.first_name"
                                    @input="clearError('first_name')"
                                    v-model="form.first_name"
                                />
                            </div>
                            <div class="col-12 col-md-6  p-3">
                                <FormControl
                                    label="Apellidos"
                                    name="last_name"
                                    :error="errors.last_name"
                                    @input="clearError('last_name')"
                                    v-model="form.last_name"
                                />
                            </div>
                            <div class="col-12 col-md-6  p-3">
                                <FormControl
                                    type="number"
                                    label="Extensión"
                                    name="phone"
                                    :error="errors.phone"
                                    @input="clearError('phone')"
                                    v-model="form.phone"
                                />
                            </div>
                            
                            
                            <div class="col-12 col-md-6  p-3">
                                <FormControl
                                    type="email"
                                    label="Email"
                                    name="email"
                                    :error="errors.email"
                                    @input="clearError('email')"
                                    v-model="form.email"
                                />
                            </div>
                            
                        
                            <div class="d-flex mt-3 col-12 p-3">
                                <button class="btn btn-sm btn-primary ms-auto" @click="handlerSubmit">Guardar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
	</div>
</template>
<style lang="scss" scoped>
.form_product_photo{
	display: inline-block;
	width: 80px;
	height: 80px;
	margin: 5px;
}
</style>