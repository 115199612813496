<script>
export default {
	props: {
		label: {
			type: String,
			default() {
				return 'Arrastre o seleccione archivo dando click aqui'
			}
		}
	},
	data() {
		return {
			filelist: [] // Store our uploaded files
		}
	},
	methods: {
		onChange(reset = false) {
			
			if(reset) {
				this.$set(this, 'filelist', [])
			} else {
				this.filelist = [this.$refs.file.files[0]];
			}

			this.$emit('input', this.filelist)
			this.$emit('change', this.filelist)
		},
		remove(i) {
			this.filelist.splice(i, 1);
		},
		dragover(event) {
			event.preventDefault();
			// Add some visual fluff to show the user can drop its files
			if (!event.currentTarget.classList.contains('bg-green-300')) {
				event.currentTarget.classList.remove('bg-gray-100');
				event.currentTarget.classList.add('bg-green-300');
			}
		},
		dragleave(event) {
			// Clean up
			event.currentTarget.classList.add('bg-gray-100');
			event.currentTarget.classList.remove('bg-green-300');
		},
		drop(event) {
			event.preventDefault();
			this.$refs.file.files = event.dataTransfer.files;
			this.onChange(); // Trigger the onChange event manually
			// Clean up
			event.currentTarget.classList.add('bg-gray-100');
			event.currentTarget.classList.remove('bg-green-300');
		},
		reset() {
			this.$refs.file.value = ""
			this.onChange(true)
		}
  	}
}
</script>
<template>
	<div>
		<div class="drag-drop-file" @dragover="dragover" @dragleave="dragleave" @drop="drop">
			<input type="file" name="fields" id="assetsFieldHandle" 
			class="d-none" @change="onChange()" ref="file" accept=".xlsx" />
		
			<label  v-if="!filelist.length" for="assetsFieldHandle" class="block cursor-pointer">
				
			</label>
			<div v-if="!filelist.length">
				<slot>
					<p class="m-0 text-center">
						{{label}}
					</p>
				</slot>
			</div>
			<ul class="drag-drop-file__list" v-if="filelist.length" v-cloak>
				<li class="drag-drop-file__item"  v-for="file in filelist">
					<span class="drag-drop-file__name">
						{{ file.name }}
					</span>
					<button class="btn btn-sm btn-transparente" type="button" @click="remove(filelist.indexOf(file))" title="Eliminar archivo"><i class="text-danger fas fa-minus-circle"></i></button>
				</li>
			</ul>
		</div>
	</div>
</template>