// import moment from "moment"
import Vue from 'vue'
import store from '../store'


export default async ({to, form, router, next}) => {
	const vuex = store
    const failAuth = () => {
        vuex.commit('auth/reset')
        return  router.push({ name: 'Login' });
    }
	let token = localStorage.getItem('token') || null

    if (!token) {
        return failAuth()
    }

    let user = JSON.parse(sessionStorage.getItem('user')) || null
	
    if(!user) {
        try {
            // user = await Vue.prototype.$http.auth.user()
            user = await vuex.dispatch('auth/fetchUser')

        } catch (error) {
            return failAuth()
        }
    } else {
		// vuex.commit('auth/setSession', {token, user})
        vuex.commit('auth/setUser', user)
    }

	vuex.commit('auth/setToken', token)
    // vuex.commit('auth/setUser', user)
    return next();
}

