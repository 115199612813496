

export const period = (state) => {
	return state.period
}

export const cart = (state) => {
	return state.cart
}

export const inCart = (state) => (id) => {
	if(!state.cart) return null

	let product = state.cart.products.find(p => p.id == id)
	return product
}

export const orderType = (state) => (key) => {
	return state.order_types[key] || 10
}

export const unavailable = (state) => state.unavailable

export const catalog = (state) => state.catalog

export const products = (state) => {
	if(!state.catalog) return []
	return state.catalog.products
	let products = state.catalog.products.filter(p => {
		
		if(p.limit === null) return true

		return p.limit !== 0
	})

	return products
}