import Vue from 'vue'

export const setSession = (state, payload) => {
	var date = new Date();
    date.setTime(date.getTime() + (1 * 24 * 60 * 60 *1000));
	if(!payload) {
		localStorage.removeItem('token')
		sessionStorage.removeItem('user')
		Vue.set(state, 'token', null)
		Vue.set(state, 'user', null)
		document.cookie = `token=; expires=${date.toGMTString()}; path=/;`
		return
	} 

	localStorage.setItem('token', payload.token)
	sessionStorage.setItem('user', JSON.stringify(payload.user))
    document.cookie = `token=${payload.token}; expires=${date.toGMTString()}; path=/;`

	Vue.set(state, 'token', payload.token)
	Vue.set(state, 'user', payload.user)

}
export const setToken = (state, payload) => {
	if(!payload) {
		localStorage.removeItem('token')
		Vue.set(state, 'user', null)
		return
	} 

	localStorage.setItem('token', payload)
	Vue.set(state, 'token', payload)

}

export const setUser = (state, payload) => {
	if(!payload) {
		sessionStorage.removeItem('user')
		Vue.set(state, 'user', null)
		return
	} 

	sessionStorage.setItem('user', JSON.stringify(payload))
	localStorage.setItem('user', JSON.stringify(payload))
	Vue.set(state, 'user', payload)

}

export const reset = (state) => {
	localStorage.removeItem('token')
	sessionStorage.removeItem('user')
	Vue.set(state, 'token', null)
	Vue.set(state, 'user', null)
}