<script>
import http from '../http'
import Loader from '../components/Loader.vue'
import { mapGetters } from 'vuex'
import ModalVue from '../components/ModalVue.vue'
import DatePicker from 'vue2-datepicker';
import DateRange from '../components/DateRange.vue';
import Multiselect from 'vue-multiselect'
import moment from 'moment'

export default {
    components: {
        Loader, 
        ModalVue,  
        DatePicker,
        DateRange,
        Multiselect
    },
    data() {
        return {
            supplier: null,
            loadingSupplier: false,
            selectedCategory: null,
            form: {
                id: null,
				name: '',
				type: 'productos',
				categories: [],
				start_date: '',
				end_date: '',
				contract: '',
            },
            errors: {
				name: '',
				type: '',
				categories: '',
				start_date: '',
				end_date: '',
				contract: '',
			},
            date: [
				moment().startOf('month').toDate(),
				moment().endOf('month').toDate()
			],
            saving: false,
            approveCategory: null,
        }
    },

    computed: {
        supplierCategories() {
            if(!this.supplier || !this.categories.length) {
                return []
            }
            let categories_id = JSON.stringify(this.supplier.categories)
            categories_id = JSON.parse(categories_id)
            this.supplier.products.forEach(p => {
                let category_id = p.product.category_id

                if(!categories_id.includes(category_id)) {
                    categories_id.push(category_id)
                }
            }); 

            let categories = this.categories.filter(c => categories_id.includes(c.id))
            this.selectedCategory = categories[0].id
            return categories
        },
        ...mapGetters({
			categories: 'app/categories',
        })
    },

    methods: {
        async fetchSupplier() {
            let vm = this
            this.loadingSupplier = true
            try {
                let {data} = await http.suppliers.detail(this.$route.params.id)
                
                this.$set(this, 'supplier', data)
                this.loadingSupplier = false 
            } catch (error) {
                this.loadingSupplier = false 
            }
        },
        async getTemplate(id) {
			let vm = this
			try {
				let {data} = await http.suppliers.template(id)
				
				data.forEach(url => {
					vm.downloadFile(url)
				})
			} catch (error) {
				
			}
		},

        async saveSupplier() {
			let vm = this
			if(!this.validForm()) {
				console.log('Invalido')
				return 
			}

			let payload = new FormData()
			
			payload.append('name', this.form.name)
			payload.append('type', this.form.type)
			payload.append('start_date', moment(this.date[0]).format('YYYY-MM-DD'))
			payload.append('end_date', moment(this.date[1]).format('YYYY-MM-DD'))
			payload.append('categories', JSON.stringify(this.form.categories.map(c => c.id)))
			payload.append('contract',  this.form.contract)

			console.log(payload)

			try {
				let vm = await http.suppliers.form(this.form.id, payload)
				this.$refs.addSupplierModal.close()
				this.fetchSupplier()
			} catch (error) {
				this.$refs.addSupplierModal.close()
				this.fetchSupplier()
			}
		},

        async approveCatalog() {
            let payload = {}

            if(this.approveCategory) {
                payload.category_id = this.approveCategory.id
            }


            try {
                let {data} = await http.suppliers.approveCatalog(this.supplier.id, payload)
                this.fetchSupplier()
                this.$refs.approveCatalogModal.close()
                this.$refs.approveCategoryCatalogModal.close()
            } catch (error) {
                
            }
        },

		downloadFile(url) {
			window.open(url)
		},
        getProductsCategory(id) {
            return this.supplier.products.filter(p => p.product.category_id == id)
        },
        openAddSupplierModal() {
			this.$set(this, 'form', {
				id: null,
				name: '',
				type: 'productos',
				categories: [],
				start_date: '',
				end_date: '',
				contract: '',
			})

			this.date =  [
				moment().startOf('month').toDate(),
				moment().endOf('month').toDate()
			]
			this.$refs.addSupplierModal.open()
		},

        openEditSupplier() {
            let supplier = this.supplier
			this.form.id = supplier.id
			this.form.name = supplier.name
			this.form.type = supplier.type
			this.date = [
				moment(supplier.start_date).toDate(),
				moment(supplier.end_date).toDate(),
			]	

			let categories = JSON.stringify(this.categories.filter(c =>  supplier.categories.includes(c.id)))

			this.$set(this.form, 'categories', JSON.parse(categories))
			
			this.$refs.addSupplierModal.open()
		},

        updateFile(event) {
			this.clearError('contract')
			if(event.target.files.length) {
				this.form.contract  = event.target.files[0]
			} else {
				this.$set(this.form, 'contract', null)
			}
		},

        clearError(name) {
			this.errors[name] = ""
		},

        validForm() {
			let valid = true
			if(!this.form.name) {
				valid = false
				this.errors.name = "El nombre es requerido"
			}
			if(this.form.type == 'productos') {
				if(!this.form.categories.length) {
					valid = false
					this.errors.categories = "Seleccione categorias"
				}
			}

			if(!this.form.id) {
				if(!this.form.contract) {
					valid = false
					this.errors.contract = "Seleccione contrato"
				}
			}
			

			return valid
		},

        approveCatalogModalOpen() {
            this.$set(this, 'approveCategory', null) 
            this.$refs.approveCatalogModal.open()
        },
        
        approveCategoryCatalogModalOpen(category) {
            this.$set(this, 'approveCategory', category) 
            this.$refs.approveCategoryCatalogModal.open()
        }
    },

    mounted() {
        this.fetchSupplier()
    }

}
</script>

<template>
    <div class="container">
        <modal-vue ref="addSupplierModal">
			<template v-slot:header>
				<h5 class="sub-title m-0"> {{form.id ? 'Editar' : 'Agregar'}} proveedor</h5>
			</template>
			<template v-slot:body={close}>
				<div v-if="!saving" style="width: 500px">
					<!-- <pre>{{selectedProduct}}</pre> -->
					<div class=" py-2">
                        <div class="form__group">
                            <div class="form__wrap">
                                <label>Nombre</label>
                                <input class="form__control" type="text" v-model="form.name" @input="clearError('name')">
                            </div>
							<span class="text-danger" v-if="errors.name">{{errors.name}}</span>
                        </div>
					</div>
					<div class=" py-2">
                        <div class="form__group">
                            <div class="form__wrap">
                                <label>Tipo</label>
								<select class="form__control" v-model="form.type">
									<option value="productos">Productos</option>
									<option value="tarjetas">Tarjetas</option>
								</select>
                            </div>
                        </div>
					</div>
					<div v-if="form.type == 'productos'" class=" py-2">
                        <div class="form__group">
                            <div class="form__wrap">
                                <label>Categoria</label>

								<multiselect ref="selectorNumber" :hideSelected="true" :limit="5" v-model="form.categories" tag-placeholder="Ingresa numero de orden" placeholder="Ingresar numero de orden"  :options="categories" track-by="id" :multiple="true" :taggable="true" label="name" @input="clearError('categories')">
									<span slot="noOptions">Selecione categorias</span>
								</multiselect>
                            </div>
							<span class="text-danger" v-if="errors.categories">{{errors.categories}}</span>
                        </div>
					</div>

					
					<div class="py-2">
						<label>Desde/Hasta</label>
						<DateRange v-model="date" label=""/>
					</div>

					<div class="py-2">
						<label class="d-block">Seleccione archivo de contrato</label>
						<label class="btn btn-success text-white fw-bold">
							<span v-if="!form.contract">
								Seleccione Contrato
							</span>
							<span v-else>
								Cambiar Contrato
							</span>
							<input  @change="updateFile" class="d-none" type="file">
						</label>
						<span v-if="form.contract" class="ms-2">{{form.contract.name}}</span>
						<span class="text-danger d-block" v-if="errors.contract">{{errors.contract}}</span>

					</div>

					<div class="d-flex py-2 border-top mx-n3 px-3">
						<button @click="close()" class=" btn btn-transparent btn-sm ms-auto">Cerrar</button>
						<button  @click="saveSupplier"   class=" btn btn-primary text-white">
                            <span v-if="form.id">Editar</span>
							<span v-else>
								Agregar
							</span>	
                             proveedor
                        </button>
					</div>
					<!-- <h6 class="text-center">¿Seguro desea  inhabilitar a {{userSelected.first_name}}?</h6>
					<div class="d-flex">
						<button @click="close()" class="ms-md-auto btn btn-transparent btn-sm">Cancelar</button>
						<button @click="disableUser" class="me-auto btn btn-danger btn-sm text-white">Si, continuar</button>
					</div> -->
				</div>
				<div v-else-if="saving" class="d-flex justify-content-center">
					<loader>
						<p class="m-0">Agregando...</p>
					</loader>
				</div>
			</template>
		</modal-vue>
        <modal-vue ref="approveCatalogModal">
			<template v-slot:header>
				<h5 class="sub-title m-0" v-if="supplier"> Aprobar todo el catálogo de {{supplier.name}}</h5>
			</template>
			<template v-slot:body={close}>
				<div v-if="!saving" style="width: 500px">
                    <div class="text-center" v-if="supplier">
                        <h6>Esta acción afectara a todos los productos listados asignadolo como proveedor</h6>
                        <h5>¿Seguro quiere aprobar todo el catálogo de {{supplier.name}}?</h5>
                    </div>

					<div class="d-flex py-2 border-top mx-n3 px-3">
						<button @click="close()" class=" btn btn-transparent btn-sm ms-auto">Cancelar</button>
						<button  @click="approveCatalog"   class=" btn btn-primary text-white">
                            <span>Si Aprobar</span> <i class="fa fa-check-circle-o" aria-hidden="true"></i>
                        </button>
					</div>
					<!-- <h6 class="text-center">¿Seguro desea  inhabilitar a {{userSelected.first_name}}?</h6>
					<div class="d-flex">
						<button @click="close()" class="ms-md-auto btn btn-transparent btn-sm">Cancelar</button>
						<button @click="disableUser" class="me-auto btn btn-danger btn-sm text-white">Si, continuar</button>
					</div> -->
				</div>
				<div v-else-if="saving" class="d-flex justify-content-center">
					<loader>
						<p class="m-0">Agregando...</p>
					</loader>
				</div>
			</template>
		</modal-vue>
        <modal-vue ref="approveCategoryCatalogModal">
			<template v-slot:header>
				<h5 class="sub-title m-0" v-if="supplier"> Aprobar  el catálogo de {{approveCategory.name}}</h5>
			</template>
			<template v-slot:body={close}>
				<div v-if="!saving" style="width: 500px">
                    <div class="text-center" v-if="supplier">
                        <h6>Esta acción afectara a todos los productos de esta categoria listados asignadolo como proveedor</h6>
                        <h5>¿Seguro quiere aprobar  el catálogo de {{approveCategory.name}}?</h5>
                    </div>

					<div class="d-flex py-2 border-top mx-n3 px-3">
						<button @click="close()" class=" btn btn-transparent btn-sm ms-auto">Cancelar</button>
						<button  @click="approveCatalog"   class=" btn btn-primary text-white">
                            <span>Si Aprobar</span> <i class="fa fa-check-circle-o" aria-hidden="true"></i>
                        </button>
					</div>
					<!-- <h6 class="text-center">¿Seguro desea  inhabilitar a {{userSelected.first_name}}?</h6>
					<div class="d-flex">
						<button @click="close()" class="ms-md-auto btn btn-transparent btn-sm">Cancelar</button>
						<button @click="disableUser" class="me-auto btn btn-danger btn-sm text-white">Si, continuar</button>
					</div> -->
				</div>
				<div v-else-if="saving" class="d-flex justify-content-center">
					<loader>
						<p class="m-0">Agregando...</p>
					</loader>
				</div>
			</template>
		</modal-vue>
        <div class="text-center" v-if="loadingSupplier && !supplier">
            <loader>
                <p>Cargando proveedor...</p>
            </loader>
        </div>
        <div v-else-if="supplier">
            <div class="d-flex flex-wrap align-items center">
				<div>
					<h3 class="title mb-0">
                        {{supplier.name}} 
                        <button @click="openEditSupplier" class="btn btn-sm text-primary ms-1 p-1">
                                <i class="fa fa-pen"></i>
                        </button>
                    </h3>
					<p class="text-primary">Catálogo proveedor</p>
				</div>
                <div class="ms-auto">
                    <button @click="getTemplate(supplier.id)" class="btn btn-sm btn-success text-white fw-bold">
                        Plantilla  <i class="fas fa-download"></i>
                    </button>
                    <button @click="approveCatalogModalOpen" class="btn btn-primary fw-bold text-white btn-sm ms-1">Aprobar todo el catálogo <i class="fa fa-check-circle-o" aria-hidden="true"></i></button>
                </div>
            </div>

            <div class="box overflow-hidden p-0">
                
				<ul class="nav nav-pills mb-0" id="pills-tab" role="tablist">
                    <li v-for="(category, index) in supplierCategories" :key="`category_${index}_${category.id}`" class="nav-item" role="presentation">
                        <button class="nav-link" :class="{'active': category.id == selectedCategory}" :id="`pills-${category.id}-tab`" data-bs-toggle="pill" :data-bs-target="`#pills-${category.id}`" type="button" role="tab" :aria-controls="`pills-${category.id}`" aria-selected="true">{{category.name}}</button>
                    </li>
                    <!-- <li class="nav-item" role="presentation">
                        <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Profile</button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Contact</button>
                    </li> -->
                </ul>


                <div class="tab-content" id="pills-tabContent">
                    <div v-for="(category, index) in supplierCategories" :key="`category_p_${index}_${category.id}`" 
                        class="tab-pane fade" 
                        :class="{
                            'show active': category.id == selectedCategory
                        }"
                        :id="`pills-${category.id}`"
                        role="tabpanel" 
                        :aria-labelledby="`pills-${category.id}-tab`">
                        <div class="table__container">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th class="text-center" colspan="5">
                                            PROFUTURO
                                        </th>
                                        <th class="text-center bg-warning" colspan="6">
                                            <div class="d-flex alig-items-center h-100">
                                                {{supplier.name}} 
                                                <button v-if="getProductsCategory(category.id).length"  @click="approveCategoryCatalogModalOpen(category)"  class="btn btn-primary fw-bold text-white btn-sm ms-auto">Aprobar {{category.name}} <i class="fa fa-check-circle-o" aria-hidden="true"></i></button>
                                            </div>
                                        </th>
                                    </tr>
                                    <tr>
                                        <th>
                                            ID
                                        </th>
                                        <th>
                                            Nombre 
                                        </th>
                                        <th>
                                            Unidad de medida  	
                                        </th>
                                        <th>
                                            Precio	
                                        </th>
                                        <th>
                                            Precio Actual
                                        </th>
                                        <th class="bg-warning text-dark">
                                            Codigo
                                        </th>
                                        <th class="bg-warning text-dark">
                                            Descripcion 
                                        </th>
                                        <th class="bg-warning text-dark">
                                            Tipo
                                        </th>
                                        <th class="bg-warning text-dark">
                                            Marca	
                                        </th>
                                        <th class="bg-warning text-dark">
                                            Unidad de medida	
                                        </th>
                                        <th class="bg-warning text-dark">
                                            Precio
                                        </th>
                                        
                                    </tr>
                                </thead>
                                <tbody v-if="getProductsCategory(category.id).length">
                                    <tr v-for="(product, index) in getProductsCategory(category.id)" :key="`product_c_${index}_${product.id}`">
                                        <td>
                                           {{product.id}}
                                        </td>
                                        <td> <router-link :to="{name: 'products-edit', params: {id: product.product.id}}">{{product.product.name}}</router-link></td>
                                        <td>{{product.product.measure_unit}}</td>
                                        <td>{{product.product.price  | numeral('$0,0.00') }}</td>
                                        <td>{{product.product.supplier_price  | numeral('$0,0.00') }}</td>
                                        <td>{{product.code}}</td>
                                        <td>{{product.description}}</td>
                                        <td>{{product.type}}</td>
                                        <td>{{product.brand}}</td>
                                        <td>{{product.measure_unit}}</td>
                                        <td>{{product.price  | numeral('$0,0.00') }}</td>
                                    </tr>
                                </tbody>
                                <tbody v-else>
                                    <td colspan="12">
                                        <h5 class="text-center">
                                            Aun no se ha cargado catálogo
                                        </h5>
                                    </td>
                                </tbody>

                            </table>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<style lang="scss" scoped>
.bg-red{
    background: #C00000 !important;
}
</style>