import http from '../../http/index'

export const fetchUser = async ({commit}, payload) => {
	try {
		let data = await http.auth.user()
		

		commit('setUser', data.user)

		return data
	} catch (error) {
		return Promise.rejected(error)
	}
}