import auth from './auth'
import guest from './guest'

// export default {
// 	auth,
// 	guest
// }

export default {
    auth,
    guest,
}
