<script>
// TODO: Exportar reporte
import { mapGetters } from 'vuex'
import DragDropFile from '../components/DragDropFile.vue'
import FormControl from '../components/FormControl.vue'
import Loader from '../components/Loader.vue'
import ModalVue from '../components/ModalVue.vue'
import http from '../http'
export default {
	components: {
		FormControl,
		DragDropFile,
		ModalVue,
		Loader
	},
	data() {
		return {
			files: [],
			page: 1,
			pagination: null,
			uploading: false,
			uploadingProgress: 0,
			canCloseModal: true,
			loading: true,

			paginations: {
				users: null,
				usersTrash: null,
			},
			users: [],
			usersTrash: [],
			paginate: ['users', 'usersTrash'],
			userSelected: null,

			saving: false,
			form: {
				query: '',
			},
			errors: {
				query: '',
			},
			

		}
	},

	computed: {
		...mapGetters({
			ccostos: 'app/ccostos'
		})
	},

	methods: {

		searchUsers: _.debounce(
			async function() {
				let vm = this
				if(!this.form.query) {
					this.fetchUsers(1)
					return
				}
 				if(this.form.query.length <= 3) {
					return
				}
				try {
					let results = await http.users.search(this.form.query)
					vm.$set(vm, 'users', results)
					vm.$set(vm.paginations, 'users', {})
				} catch (error) {
					
				}
			}, 300),  
		
		async downloadReport () {
			let vm = this
			
			try {
				let response = await http.reports.users()
				if(response.files.length) {
					response.files.forEach(url => {
						vm.downloadFile(url)
					})
				}
			} catch (error) {
				
			}
		},
		
		clearError(key) {
			this.$set(this.errors, key, '')
		},
		downloadFile(url) {
			window.open(url)
		},
		async fetchUsers(page) {
			
			let vm = this
			vm.loading = true
			try {
				let data = await http.users.list(page || 1)
				// vm.$set(vm, 'pagination', data)
				// vm.loading = false
				vm.$set(vm, 'users', data.data)
				delete data.data
				vm.$set(vm.paginations, 'users', data)
				if(!page) {
					this.fetchUsersTrash()
				} else {
					vm.loading = false
				}
			} catch (error) {
				vm.loading = false
			}
		},


		

		async fetchUsersTrash() {
			let vm = this
			vm.loading = true
			try {
				let data = await http.users.trash()
				// vm.$set(vm, 'pagination', data)
				// vm.loading = false
				vm.$set(vm, 'usersTrash', data)
				vm.loading = false
			} catch (error) {
				vm.loading = false
			}
		},

		async disableUser() {
			let vm = this
			vm.loading = true
			vm.saving = true
			vm.$refs.modalUserDisable.close()
			try {
				let res = await http.users.disable(vm.userSelected.id)
				vm.fetchUsers()
				vm.$notify({
					group: 'b_r',
					title: 'Usuario inhabilitado',
					text: '',
					type: 'success'
				});
				vm.$set(vm, 'userSelected', null)
				vm.saving = false

			} catch (error) {
				vm.loading = false
				vm.$set(vm, 'userSelected', null)
				vm.saving = false
				vm.$notify({
					group: 'b_r',
					title: 'Hubo un problema',
					text: 'No se pudimos inhabilitar el usuario',
					type: 'danger'
				});
			}
		},
		async activeUser() {
			let vm = this
			vm.loading = true
			vm.saving = true
			// vm.$refs.modalUserDisable.close()
			try {
				let res = await http.users.active(vm.userSelected.id)
				vm.fetchUsers()
				vm.$notify({
					group: 'b_r',
					title: 'Usuario habilitado',
					text: '',
					type: 'success'
				});
				vm.$set(vm, 'userSelected', null)
				vm.saving = false
				vm.$refs.modalUserActive.close()

			} catch (error) {
				vm.loading = false
				vm.$set(vm, 'userSelected', null)
				vm.saving = false
				vm.$notify({
					group: 'b_r',
					title: 'Hubo un problema',
					text: 'No se pudimos habilitar el usuario',
					type: 'danger'
				});
				vm.$refs.modalUserActive.close()
			}
		},

		getResults(page) {
			this.fetchUsers(page)
		},

	
		
		calcUpload(progressEvent) {
			let p = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ))
			this.uploadingProgress =  p
		},

		openModalDisable(user) {
			this.$set(this, 'userSelected', user)
			this.$refs.modalUserDisable.open()
		},

		openModalActive(user) {
			this.$set(this, 'userSelected', user)
			this.$refs.modalUserActive.open()
		},

		// setPage(page) {
		// 	this.page = page
		// 	this.fetchEmployees()
		// },
		// onChangeFile(file) {
		// 	if(file.length) {
		// 		this.$refs.modalUpload.open()
		// 	}
		// },

		

		// resetFiles() {
		// 	this.$refs.dragDrop.reset()
		// }
	},

	mounted() {
		this.fetchUsers()
	}
	
}
</script>
<template>
	<div class="container">
		<modal-vue ref="modalUserDisable" >
			<template v-slot:header>
				<h5 class="sub-title m-0">Inhabilitar usuario</h5>
			</template>
			<template v-slot:body={close}>
				<div v-if="!saving">
					<h6 class="text-center">¿Seguro desea  inhabilitar a {{userSelected.first_name}}?</h6>
					<div class="d-flex">
						<button @click="close()" class="ms-md-auto btn btn-transparent btn-sm">Cancelar</button>
						<button @click="disableUser" class="me-auto btn btn-danger btn-sm text-white">Si, continuar</button>
					</div>
				</div>
				<div v-else-if="saving  && userSelected" class="d-flex justify-content-center">
					<loader>
						<p class="m-0">Inhabilitando a {{userSelected.first_name}}...</p>
					</loader>
				</div>
			</template>
		</modal-vue>
		<modal-vue ref="modalUserActive">
			<template v-slot:header>
				<h5 class="sub-title m-0">Habilitar usuario</h5>
			</template>
			<template v-slot:body={close}>
				<div v-if="!saving">
					<h6 class="text-center">¿Seguro desea  habilitar a {{userSelected.first_name}}?</h6>
					<div class="d-flex">
						<button @click="close()" class="ms-md-auto btn btn-transparent btn-sm">Cancelar</button>
						<button @click="activeUser" class="me-auto btn btn-primary btn-sm text-white">Si, habilitar</button>
					</div>
				</div>
				<div v-else-if="saving  && userSelected" class="d-flex justify-content-center">
					<loader>
						<p class="m-0">Habilitando a {{userSelected.first_name}}...</p>
					</loader>
				</div>
			</template>
		</modal-vue>
		<div class="d-flex align-items-center mb-3">
			<div class="col-6">
				<h3 class="title mb-0">Usuarios</h3>
				<div>
					<FormControl
						label=""
						placeholder="Buscar usuario"
						name="name"
						:error="errors.query"
						@input="searchUsers"
						v-model="form.query"
					/>	
				</div>
			</div>
			<div class="ms-auto">
				<router-link :to="{name: 'users-add'}" class="btn btn-primary fw-bold text-uppercase btn-sm">
					Añadir usuario <i class="fas fa-plus-circle"></i>
				</router-link>
				<button class="btn btn-sm btn-success btn-sm fw-bold text-white text-uppercase ms-2" @click="downloadReport">
					Exportar usuarios <i class="far fa-file-excel"></i>
				</button>
			</div>

			<!-- <div class="ms-auto container-header__control">
				<drag-drop-file ref="dragDrop" v-model="files" @change="onChangeFile">
					<p class="m-0 text-center">Actualizar plantilla <i class="fas fa-sync-alt"></i></p>
				</drag-drop-file>
			</div> -->
		</div>
		<!-- <pre>{{users}}</pre> -->
		<div v-if="!loading">
			<ul class="nav nav-pills mb-0" id="pills-tab" role="tablist">
				<li class="nav-item" role="presentation">
					<button class="nav-link active"  id="`pills-active-tab`" data-bs-toggle="pill" data-bs-target="#pills-active" type="button" role="tab" aria-controls="pills-active" aria-selected="true">Habilitados</button>
				</li>
				<li class="nav-item" role="presentation">
					<button class="nav-link"  id="`pills-inactive-tab`" data-bs-toggle="pill" data-bs-target="#pills-inactive" type="button" role="tab" aria-controls="pills-inactive" aria-selected="true">Inhabilitados</button>
				</li>
				<!-- <li class="nav-item" role="presentation">
					<button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Profile</button>
				</li>
				<li class="nav-item" role="presentation">
					<button class="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Contact</button>
				</li> -->
			</ul>
			<div class="box overflow-hidden p-0" v-if="!loading">
				<div class="table__container">
					<div class="tab-content" id="pills-tabContent">
						<div 
							v-if="users.length"
							class="tab-pane fade show active" 
							id="pills-active"
							role="tabpanel" 
							aria-labelledby="pills-active-tab">
							<table class="table" >
								<thead>
									<tr>
										<th>C. Costos</th>
										<th>Email</th>
										<th>Celular</th>
										<th>Teléfono</th>
										<th>Nombre</th>
										<th># Empleado</th>
										<th></th>
										<th></th>
									</tr>
								</thead>
								<tbody>
								
									<tr v-for="(user, index)  in  users" :key="`c_${index}_${user.id}`">
										<td>
											{{user.ccosto}}
										</td>
										<td>
											{{user.email}}
										</td>
										<td>
											
										</td>
										<td>
											{{user.phone}}
										</td>
										<td>
											{{user.first_name}}
										</td>
										<td>
											{{user.employee_number}}
										</td>
										<td>
											<router-link :to="{name: 'users-edit', params: {id: user.id}}" class="btn btn-primary btn-sm btn-block w-100">
												<span class="small">
													Editar <i class="fas fa-pen"></i>
												</span>
											</router-link>
										</td>
										<td>
											<button class="btn btn-danger btn-sm btn-block w-100" @click="openModalDisable(user)">
												<span class="small text-white">
													Inhabilitar <i class="fas fa-user-slash"></i>
												</span>
											</button>
										</td>
									</tr>
								</tbody>
							</table>
							<div class="d-flex mt-3">
								<pagination class="mx-auto" :limit="2" :data="paginations.users" @pagination-change-page="fetchUsers"></pagination>
							</div>

							<!-- <div class="p-3 d-flex">
								<paginate-links
								class="pagination ms-auto"
								for="users"
								:limit="4"
								></paginate-links>
							</div> -->
						</div>

						<div 
							
							class="tab-pane fade" 
							id="pills-inactive"
							role="tabpanel" 
							aria-labelledby="pills-inactive-tab"
							>
							<div v-if="usersTrash.length">
								<paginate 
									name="usersTrash"
									:list="usersTrash"
									:per="10"
									class="p-0 m-0"
									>
									<table class="table" >
										<thead>
											<tr>
												<th>C. Costos</th>
												<th>Email</th>
												<th>Celular</th>
												<th>Teléfono</th>
												<th>Nombre</th>
												<th># Empleado</th>
												<th></th>
											</tr>
										</thead>
										<tbody>
										
											<tr v-for="(user, index)  in  paginated('usersTrash')" :key="`c_${index}_${user.id}`">
												<td>
													{{user.ccosto}}
												</td>
												<td>
													{{user.email}}
												</td>
												<td>
													
												</td>
												<td>
													{{user.phone}}
												</td>
												<td>
													{{user.first_name}}
												</td>
												<td>
													{{user.employee_number}}
												</td>
												
												<td>
													<button class="btn btn-primary btn-sm btn-block w-100" @click="openModalActive(user)">
														<span class="small">
															Habilitar <i class="fas fa-check"></i>
														</span>
													</button>
												</td>
											</tr>
										</tbody>
									</table>
								</paginate>
								<div class="p-3 d-flex">
									<paginate-links
									class="pagination ms-auto"
									for="usersTrash"
									:limit="4"
									></paginate-links>
								</div>
							</div>
							<div v-else>
								<table class="table" >
									<thead>
										<tr>
											<th>C. Costos</th>
											<th>Email</th>
											<th>Celular</th>
											<th>Teléfono</th>
											<th>Nombre</th>
											<th># Empleado</th>
											<th></th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td colspan="7">
												<h5 class="text-center">No econtramos registros</h5>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="d-flex justify-content-center" v-else-if="loading">
			<loader>
				<p class="m-0">Cargando datos...</p>
			</loader>
		</div>
	</div>
</template>