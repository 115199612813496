<script>
import Loader from '../components/Loader.vue'
import Autocomplete from '../components/Autocomplete.vue'
import { mapGetters } from 'vuex'

export default {
	components: {
		Loader,
		Autocomplete
	},
	data() {
		return {
			loadingRoles: true,
			form: {
				ccosto: ''
			},
			selectedCcosto: null,
		}
	},

	computed: {
		...mapGetters({
			roles: 'app/roles',
			ccostos: 'app/ccostos',
		})
	},

	methods: {
		goToEditPermissions() {
			if(!this.selectedCcosto) return

			this.$router.push({name: 'access-management-ccosto', params: {id: this.selectedCcosto.id}})
		}
	}

}
</script>

<template>
	<div>
		<div class="container">
			<h3 class="title mb-4">Control de accesso</h3>
			
			<h5 class="sub-title mb-0">Accesos por rol</h5>
			<p class="text-primary">Selecciona un perfil para editar su acceso al catálogo</p>
			<div class="text-center" v-if="loadingRoles && !roles.length">
				<loader>
					<p>Cargando roles...</p>
				</loader>
			</div>
			<div v-else-if="roles.length" class="row mb-4">
				
				<div class="col-12 col-md-6 col-lg-4 p-3" v-for="(role, index) in roles" :key="`role_${index}_${role.id}`">
					<div class="box">
						<div class="role">
							<!-- '/img/icon-rol-even.png', '/img/icon-rol-odd.png' -->
							<img class="role__img" :src="['/img/icon-rol-even.png', '/img/icon-rol-odd.png'][index%2]" alt="">
							<span class="role__title px-2">
								{{role.name }}
							</span>
							<router-link :to="{name: 'access-management-role', params: {id: role.id} }" class="btn btn-sm btn-complementary ms-auto ">
								<span class="small">
									Permisos <i class="fas fa-angle-right"></i>
								</span>
							</router-link>
						</div>
					</div>
				</div>
			</div>

			<h5 class="sub-title mb-0">Accesos por centro de costos</h5>
			<p class="text-primary">Selecciona un centro de costos para editar su acceso al catálogo de manera específica</p>
			<div class="row">
				<div class="col-12  p-3">
					<form class="box" @submit.prevent="goToEditPermissions">
						<div class="d-flex align-items-center">
							<div class="col-6 flex-grow-1 p-1">
								<autocomplete
									class="mb-0"
									label="Centro de costos"
									name="ccosto_search"
									v-model="selectedCcosto"
									:selected-label="(option) => {
										return`${option.ccosto} - ${option.name}`
									}"
									:options="ccostos"
									:filter="(options, query) => {
										let filtered = options.filter(o => {
											let ccosto = o.ccosto.toString().toLowerCase()
											let name  = o.name.toLowerCase()
											let match = false
											query = query.toLowerCase()
											
											if(!match) {
												match = ccosto.includes(query)
											}
											if(!match) {
												match = name.includes(query)
											}

											return match
										})

										return filtered
									}"
									>
									<template v-slot:option="{option}">
										{{ option.ccosto }} - {{option.name}}
									</template>
								</autocomplete>
							</div>
							<div>
								<button type="submit" class="btn  btn-complementary">Continuar <i class="fas fa-angle-right"></i></button>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>