import Vue from 'vue'


export const setProducts = (state, payload) => {
	
	if(payload) {
		Vue.set(state, 'products', payload)
	} else {
		Vue.set(state, 'products', [])
	}
}

export const setCategories = (state, payload) => {
	if(payload) {
		Vue.set(state, 'categories', payload)
	} else {
		Vue.set(state, 'categories', [])
	}
}

export const setRoles = (state, payload) => {
	if(payload) {
		Vue.set(state, 'roles', payload)
	} else {
		Vue.set(state, 'roles', [])
	}
}

export const setCcostos = (state, payload) => {
	if(payload) {
		Vue.set(state, 'ccostos', payload)
	} else {
		Vue.set(state, 'ccostos', [])
	}
}

export const deleteProduct = (state, product_id) => {
	if(product_id) {
		let index = state.products.findIndex(p => p.id == product_id)
		if(index >= 0) {
			state.products.splice(index, 1)
		}
	} 
}

export const addCategory = (state, category) => {
	if(category) {
		state.categories.push(category)
	}
}