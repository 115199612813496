import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import AccessManagement from '../views/AccessManagement.vue'
import AccessManagementEdit from '../views/AccessManagementEdit.vue'
import Employees from '../views/Employees.vue'
import Products from '../views/Products.vue'
import ProductsStore from '../views/ProductsStore.vue'
import ProductsAdd from '../views/ProductsAdd.vue'
import Suppliers from '../views/Suppliers.vue'
import SupplierProducts from '../views/SupplierProducts.vue'
import Users from '../views/Users.vue'
import ReportProducts from '../views/ReportProducts.vue'
import AgendaView from '../views/AgendaView.vue'
import AdminOrders from '../views/AdminOrders.vue'
import FormUser from '../views/FormUser.vue'
import Orders from '../views/Orders.vue'
import OrderDetail from '../views/OrderDetail.vue'
import Profile from '../views/Profile.vue'
import Empty from '../components/Empty.vue'
import middlewares from '../middlewares'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
	meta: {
		middleware: middlewares.auth,
		layout: 'full'
	}
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
	meta: {
		middleware: middlewares.auth,
		layout: 'full'
	}
  },
  {
    path: '/admin/access-management',
    component: Empty,
	meta: {
		middleware: middlewares.auth,
		layout: 'full'
	},
	children: [
		{
			path: '/admin/access-management/role/:id',
    		name: 'access-management-role',
			component: AccessManagementEdit,
			meta: {
				middleware: middlewares.auth,
				layout: 'full'
			},
		},
		{
			path: '/admin/access-management/ccosto/:id',
    		name: 'access-management-ccosto',
			component: AccessManagementEdit,
			meta: {
				middleware: middlewares.auth,
				layout: 'full'
			},
		},
		{
			path: '/admin/access-management',
    		name: 'access-management',
			component: AccessManagement,
			meta: {
				middleware: middlewares.auth,
				layout: 'full'
			},
		},
		
	]
  },
  {
    path: '/admin/employees',
    component: Empty,
	meta: {
		middleware: middlewares.auth,
		layout: 'full'
	},
	children: [
		{
			path: '/admin/employees',
    		name: 'employees',
			component: Employees,
			meta: {
				middleware: middlewares.auth,
				layout: 'full'
			},
		},
	]
  },
  {
    path: '/admin/users',
    component: Empty,
	meta: {
		middleware: middlewares.auth,
		layout: 'full'
	},
	children: [
		{
			path: '/admin/users',
    		name: 'users',
			component: Users,
			meta: {
				middleware: middlewares.auth,
				layout: 'full'
			},
		},
		{
			path: '/admin/users/add',
    		name: 'users-add',
			component: FormUser,
			meta: {
				middleware: middlewares.auth,
				layout: 'full'
			},
		},
		{
			path: '/admin/users/edit/:id',
    		name: 'users-edit',
			component: FormUser,
			meta: {
				middleware: middlewares.auth,
				layout: 'full'
			},
		},
	]
  },
  {
    path: '/admin/suppliers',
    component: Empty,
	meta: {
		middleware: middlewares.auth,
		layout: 'full'
	},
	children: [
		{
			path: '/admin/suppliers',
    		name: 'suppliers',
			component: Suppliers,
			meta: {
				middleware: middlewares.auth,
				layout: 'full'
			},
		},
		{
			path: '/admin/suppliers/:id',
    		name: 'suppliers-products',
			component: SupplierProducts,
			meta: {
				middleware: middlewares.auth,
				layout: 'full'
			},
		},
	]
  },
  {
    path: '/admin/agenda',
    component: Empty,
	meta: {
		middleware: middlewares.auth,
		layout: 'full'
	},
	children: [
		{
			path: '/admin/agenda',
    		name: 'admin-agenda',
			component: AgendaView,
			meta: {
				middleware: middlewares.auth,
				layout: 'full'
			},
		},
	]
  },
  {
    path: '/admin/orders/:id',
	meta: {
		middleware: middlewares.auth,
		layout: 'full'
	},
	name: 'admin-orders-detail',
	component: OrderDetail,
  },
  {
    path: '/orders/:id',
	meta: {
		middleware: middlewares.auth,
		layout: 'full'
	},
	name: 'orders-detail',
	component: OrderDetail,
  },
  {
    path: '/admin/products',
    component: Empty,
	meta: {
		middleware: middlewares.auth,
		layout: 'full'
	},
	children: [
		{
			path: '/admin/products',
    		name: 'products',
			component: Products,
			meta: {
				middleware: middlewares.auth,
				layout: 'full'
			},
		},
		{
			path: '/admin/products/add',
    		name: 'products-add',
			component: ProductsAdd,
			meta: {
				middleware: middlewares.auth,
				layout: 'full'
			},
		},
		{
			path: '/admin/products/edit/:id',
    		name: 'products-edit',
			component: ProductsAdd,
			meta: {
				middleware: middlewares.auth,
				layout: 'full'
			},
		},
		
	]
  },
  {
		path: '/catalog',
		name: 'products-store',
		component: ProductsStore,
		meta: {
			middleware: middlewares.auth,
			layout: 'full'
		},
  },	
  {
		path: '/orders',
		name: 'orders-store',
		component: Orders,
		meta: {
			middleware: middlewares.auth,
			layout: 'full'
		},
	},
	{
		path: '/admin/report/products',
		component: Empty,
		meta: {
			middleware: middlewares.auth,
			layout: 'full'
		},
		children: [
			{
				path: '/admin/report/products',
				name: 'report-products',
				component: ReportProducts,
				meta: {
					middleware: middlewares.auth,
					layout: 'full'
				},
			},
		]
	},
	{
		path: '/admin/report/orders',
		component: Empty,
		meta: {
			middleware: middlewares.auth,
			layout: 'full'
		},
		children: [
			{
				path: '/admin/report/orders',
				name: 'admin-orders',
				component: AdminOrders,
				meta: {
					middleware: middlewares.auth,
					layout: 'full'
				},
			},
		]
	},
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "Login" */ '../views/LoginView.vue'),
	meta: {
		middleware: middlewares.guest
	}
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {

    if(to.name != from.name) {
        // sto're.commit('status/showLoadingScreen')
    }

    if (to.meta.middleware) {
        const middlewareArr = Array.isArray(to.meta.middleware)
            ? to.meta.middleware
            : [to.meta.middleware]

        const context = {
            from,
            next,
            router,
            to,
        };

        const nextMiddleware = nextFactory(context, middlewareArr, 1)
        return middlewareArr[0]({ ...context, next: nextMiddleware })
    }

    return next();

});

function nextFactory (context, middlewareArr, index) {
    const subsequentMiddleware = middlewareArr[index]

    if (!subsequentMiddleware) {
      return context.next
    }

    return (param) => {
        if (param !== undefined) {
            return context.next(param)
        }
        const nextMiddleware = nextFactory(context, middlewareArr, index + 1)
        subsequentMiddleware({ ...context, next: nextMiddleware })
    }

}

export default router
