

export const products = (state) => state.products
export const categories = (state) => state.categories
export const roles = (state) => state.roles
export const ccostos = (state) => state.ccostos
export const getRoleById = (state) => (id) => {
	if(!state.roles.length) return null

	return state.roles.find(r => r.id == id)
}

export const getCcosto = (state) => (id) => {
	if(!state.ccostos.length) return null

	return state.ccostos.find(c => c.id == id)
}
export const getProdutsByCategory = (state) => (id) => {
	if(!state.products.length) return []

	return state.products.filter(p => p.category_id == id)
}

export const productById = (state) => (id) => {
	return state.products.find(p => p.id == id)
}
