<script>
import Loader from '../components/Loader.vue'
import DragDropFile from '../components/DragDropFile.vue'
import ModalVue from '../components/ModalVue.vue'
import DatePicker from 'vue2-datepicker';
import DateRange from '../components/DateRange.vue';
import Multiselect from 'vue-multiselect'

import moment from 'moment'

import { mapGetters } from 'vuex';
import http from '../http';

export default {
	components: {
		Loader,
		DragDropFile,
		ModalVue,
		DateRange,
		Multiselect
	},
	data() {
		return {
			loadingSuppliers: true,
			suppliers: [],
			supplierId: null, 
			supplier: null, 
			time3: null,
			saving: false,
			form: {
				id: null,
				name: '',
				type: 'productos',
				categories: [],
				start_date: '',
				end_date: '',
				contract: '',
				supplier_id: '',
			},

			errors: {
				name: '',
				type: '',
				categories: '',
				start_date: '',
				end_date: '',
				contract: '',
				supplier_id: ''
			},

			date: [
				moment().startOf('month').toDate(),
				moment().endOf('month').toDate()
			],

			catalog: null,
		}
	},

	computed: {
		...mapGetters({
			categories: 'app/categories'
		})
	},

	methods: {
		alert(texto){
			alert(texto)
		},

		async saveSupplier() {
			let vm = this
			if(!this.validForm()) {
				console.log('Invalido')
				return 
			}

			let payload = new FormData()
			debugger
			payload.append('name', this.form.name)
			payload.append('type', this.form.type)
			payload.append('supplier_id', this.form.supplier_id)
			payload.append('start_date', this.date[0] ? moment(this.date[0]).format('YYYY-MM-DD') : '')
			payload.append('end_date', this.date[1] ? moment(this.date[1]).format('YYYY-MM-DD') : '')
			payload.append('categories', JSON.stringify(this.form.categories.map(c => c.id)))
			payload.append('contract',  this.form.contract)

			console.log(payload)

			try {
				let vm = await http.suppliers.form(this.form.id, payload)
				this.$refs.addSupplierModal.close()
				this.fetchSuppliers()
			} catch (error) {
				this.$refs.addSupplierModal.close()
				this.fetchSuppliers()
			}
		},

		async saveCatalog() {
			let payload = new FormData()

			payload.append('catalog', this.catalog) 
			this.saving = true
			try {
				let {data} = await http.suppliers.imports(this.supplier.id, payload)
				this.$refs.uploadModal.close()
				this.saving = false

			} catch (error) {
				this.$refs.uploadModal.close()
				this.saving = false
				
			}
		},

		async fetchSuppliers() {
			let vm = this
			// try {
				let suppliers = await vm.$http.suppliers.list()
				if(suppliers) {
					suppliers = suppliers.filter(p => {
						return p.type == 'productos'
					})
					vm.$set(vm, 'suppliers', suppliers)
	
					vm.loadingSuppliers = false
				}
			// } catch (error) {
			// 	vm.loadingSuppliers = false
			// }
		},
		async download() {
			let vm = this
			let report = await vm.$http.suppliers.downloadProducts()

			
		},

		async getTemplate(id) {
			let vm = this
			try {
				let {data} = await http.suppliers.template(id)
				
				data.forEach(url => {
					vm.downloadFile(url)
				})
			} catch (error) {
				
			}
		},

		downloadFile(url) {
			window.open(url)
		},


		getCategory(id) {
			return this.categories.find(c => c.id == id)
		},

		validForm() {
			let valid = true
			if(!this.form.name) {
				valid = false
				this.errors.name = "El nombre es requerido"
			}
			// if(!this.form.supplier_id) {
			// 	valid = false
			// 	this.errors.supplier_id = "El id proveedor es requerido"
			// }
			if(this.form.type == 'productos') {
				if(!this.form.categories.length) {
					valid = false
					this.errors.categories = "Seleccione categorias"
				}
			}

			if(!this.form.id) {
				if(!this.form.contract) {
					valid = false
					this.errors.contract = "Seleccione contrato"
				}
			}

			

			return valid
		},

		openUploadModal(supplier) {
			this.$set(this, 'supplier', supplier)
			this.$refs.uploadModal.open()
		},

		openAddSupplierModal() {
			this.$set(this, 'form', {
				id: null,
				name: '',
				type: 'productos',
				categories: [],
				start_date: '',
				end_date: '',
				contract: '',
			})

			this.$set(this.form, 'contract', '')

			this.date =  [
				moment().startOf('month').toDate(),
				moment().endOf('month').toDate()
			]
			this.$refs.addSupplierModal.open()
		},

		openEditSupplier(supplier) {
			this.form.id = supplier.id
			this.form.supplier_id = supplier.supplier_id
			this.form.name = supplier.name
			this.form.type = supplier.type
			this.date = [
				supplier.start_date ? moment(supplier.start_date).toDate() : '',
				supplier.end_date ? moment(supplier.end_date).toDate() : '',
			]	

			this.$set(this.form, 'contract', '')

			let categories = JSON.stringify(this.categories.filter(c =>  supplier.categories.includes(c.id)))

			this.$set(this.form, 'categories', JSON.parse(categories))
			
			this.$refs.addSupplierModal.open()
		},

		updateFile(event) {
			this.clearError('contract')
			if(event.target.files.length) {
				this.form.contract  = event.target.files[0]
			} else {
				this.$set(this.form, 'contract', null)
			}
		},

		updateCatalogFile(event) {
			if(event.target.files.length) {
				this.catalog  = event.target.files[0]
			} else {
				this.$set(this, 'catalog', null)
			}
		},

		clearError(name) {
			this.errors[name] = ""
		}
	},


	mounted() {
		this.fetchSuppliers()
	}
}
</script>
<template>
	<div>
		<modal-vue ref="uploadModal" title="Cargar catálogo">
			<template v-slot:header>
				<h5 class="m-0">Cargar catálogo</h5>
			</template>
			<template v-slot:body>
				<div v-if="!saving" style="min-width: 400px">
					<div>
						<div class="d-flex flex-wrap">
							<div class="py-2 mx-auto text-center">
								<h5 v-if="supplier">{{supplier.name}}</h5>
								<label class="btn btn-success text-white fw-bold mb-2">
									<span v-if="!catalog">
										Seleccione catálogo
									</span>
									<span v-else>
										Cambiar catálogo
									</span>
									<input  @change="updateCatalogFile" class="d-none" type="file">
								</label>
								<span v-if="catalog" class="ms-2 d-block">{{catalog.name}}</span>
								<p>Puedes cargar el catálogo de cualquier categoria.</p>
							</div>
						</div>
						<div class="d-flex py-2 border-top mx-n3 px-3">
							<button @click="close()" class=" btn btn-transparent btn-sm ms-auto">Cerrar</button>
							<button  @click="saveCatalog"   class=" btn btn-primary text-white me-auto">Cargar catálogo</button>
						</div>
					</div>
				</div>
				<div v-else>
					<loader>
						<p class="m-0">Guardando...</p>
					</loader>
				</div>
			</template>
		</modal-vue>
		<modal-vue ref="addSupplierModal">
			<template v-slot:header>
				<h5 class="sub-title m-0"> {{form.id ? 'Editar' : 'Agregar'}} proveedor</h5>
			</template>
			<template v-slot:body={close}>
				<div v-if="!saving" style="width: 500px">
					<!-- <pre>{{selectedProduct}}</pre> -->
					<div class=" py-2">
                        <div class="form__group">
                            <div class="form__wrap">
                                <label>Nombre</label>
                                <input class="form__control" type="text" v-model="form.name" @input="clearError('name')">
                            </div>
							<span class="text-danger" v-if="errors.name">{{errors.name}}</span>
                        </div>
					</div>
					<div class=" py-2">
                        <div class="form__group">
                            <div class="form__wrap">
                                <label>ID proveedor</label>
                                <input class="form__control" type="text" v-model="form.supplier_id" @input="clearError('name')">
                            </div>
							<span class="text-danger" v-if="errors.supplier_id">{{errors.supplier_id}}</span>
                        </div>
					</div>
					<div class=" py-2">
                        <div class="form__group">
                            <div class="form__wrap">
                                <label>Tipo</label>
								<select class="form__control" v-model="form.type">
									<option value="productos">Productos</option>
									<option value="tarjetas">Tarjetas</option>
								</select>
                            </div>
                        </div>
					</div>
					<div v-if="form.type == 'productos'" class=" py-2">
                        <div class="form__group">
                            <div class="form__wrap">
                                <label>Categoria</label>

								<multiselect ref="selectorNumber" :hideSelected="true" :limit="5" v-model="form.categories" tag-placeholder="Ingresa numero de orden" placeholder="Ingresar numero de orden"  :options="categories" track-by="id" :multiple="true" :taggable="true" label="name" @input="clearError('categories')">
									<span slot="noOptions">Selecione categorias</span>
								</multiselect>
                            </div>
							<span class="text-danger" v-if="errors.categories">{{errors.categories}}</span>
                        </div>
					</div>

					
					<div class="py-2">
						<label>Desde/Hasta</label>
						<DateRange v-model="date" label=""/>
					</div>

					<div class="py-2">
						<label class="d-block">Seleccione archivo de contrato</label>
						<label class="btn btn-success text-white fw-bold">
							<span v-if="!form.contract">
								Seleccione Contrato
							</span>
							<span v-else>
								Cambiar Contrato
							</span>
							<input  @change="updateFile" class="d-none" type="file">
						</label>
						<span v-if="form.contract" class="ms-2">{{form.contract.name}}</span>
						<span class="text-danger d-block" v-if="errors.contract">{{errors.contract}}</span>

					</div>

					<div class="d-flex py-2 border-top mx-n3 px-3">
						<button @click="close()" class=" btn btn-transparent btn-sm ms-auto">Cerrar</button>
						<button  @click="saveSupplier"   class=" btn btn-primary text-white">
							<span v-if="form.id">Editar</span>
							<span v-else>
								Agregar
							</span>	
							 proveedor
						</button>
					</div>
					<!-- <h6 class="text-center">¿Seguro desea  inhabilitar a {{userSelected.first_name}}?</h6>
					<div class="d-flex">
						<button @click="close()" class="ms-md-auto btn btn-transparent btn-sm">Cancelar</button>
						<button @click="disableUser" class="me-auto btn btn-danger btn-sm text-white">Si, continuar</button>
					</div> -->
				</div>
				<div v-else-if="saving" class="d-flex justify-content-center">
					<loader>
						<p class="m-0">Agregando...</p>
					</loader>
				</div>
			</template>
		</modal-vue>
		<div class="container">
			<div class="d-flex flex-wrap align-items center">
				<div>
					<h3 class="title mb-0">Proveedores</h3>
					<p class="text-primary">Carga catálogos por proveedor | Descargar plantilla de carga masiva</p>
				</div>
				<div class="ms-auto">
					<!-- <a :href="`${$http.base}/suppliers/products/download`" download class="btn btn-success btn-sm text-white">Descargar plantilla <i class="fas fa-download"></i></a> -->
					<!-- <a href="https://insumos-api.profuturocompras.com.mx/files/productos_provedor.xlsx" download class="btn btn-success btn-sm text-white">Descargar plantilla <i class="fas fa-download"></i></a> -->
					<button @click="openAddSupplierModal" class="btn btn-primary fw-bold btn-sm ms-2">Agregar proveedor <i class="fa fa-plus" aria-hidden="true"></i></button>
				</div>
			</div>
			<div class="text-center" v-if="loadingSuppliers && !suppliers.length">
				<loader>
					<p>Cargando proveedores...</p>
				</loader>
			</div>
			<div v-else class="row">
				<div class="box p-0 overflow-hidden">
					<table class="table">
						<thead>
							<tr>
								<th>Nombre proveedor</th>
								<th>Categoria</th>
								<th>Fecha</th>
								<th>Contrato</th>
								<th class="text-center">
									<i class="fa fa-cogs"></i>
								</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(supplier, index) in suppliers" :key="`supplier__${index}`">
								<td style="white-space: nowrap">
									<button @click="openEditSupplier(supplier)" class="btn btn-sm text-primary ms-1 p-1">
										 <i class="fa fa-pen"></i>
									</button>
									<router-link :to="{name: 'suppliers-products', params: {id: supplier.id}}">
										{{ supplier.name }}
									</router-link>
								</td>
								<td>
									<span class="badge bg-warning  ms-1" style="color: #000" v-for="(category, index) in supplier.categories" :key="`c_${category}`">
										{{getCategory(category).name}}
									</span>

								</td>
								<td>
									<span class="d-block">
										{{ supplier.start_date }}
									</span>
									<span class="d-block">
										{{ supplier.end_date }}
									</span>
								</td>
								<td class="text-center">
									<a v-if="supplier.contract_file" :href="supplier.contract_file" class="btn btn-light text-primary">Descargar <i class="fa fa-download" aria-hidden="true"></i></a>
									<span v-else>No disponible</span>
								</td>
								

								<td style="white-space: nowrap">
									<button @click="getTemplate(supplier.id)" class="btn btn-sm btn-success text-white">
										Plantilla  <i class="fas fa-download"></i>
									</button>
									<button @click="openUploadModal(supplier)" class="btn btn-sm btn-primary text-white ms-1">
										Catálogo  <i class="fa fa-upload" aria-hidden="true"></i>
									</button>
<!-- 									
									<button @click="alert('Proveedor rechazado')" class="btn btn-sm btn-danger text-white">
										<i class="fa fa-times"></i>
									</button> -->
								</td>
							</tr>
						</tbody>
					</table>
				</div>


				<!-- <div v-for="(supplier, index) in suppliers" :key="`supplier__${index}`" class="col-12 col-md-6 col-lg-4 p-2">
					<div class="box" style="height: 150px;">
						<div class="d-flex flex-wrap align-items-center">
							<div style="width:50%">
								<p class="m-0">
									{{ supplier.name }}
								</p>
								<p class="small m-0">
									{{ supplier.categorie.toUpperCase() }}
								</p>
							</div>
							<button style="width:50%" @click="openUploadModal(supplier.id)" class="btn btn-sm btn-complementary ms-auto">Cargar catálogo</button>
							<button class="btn btn-xs btn-success">
								<i class="fa fa-check"></i>
							</button>
						</div>
					</div>
				</div> -->
			</div>
		</div>	
	</div>	
</template>