import Vue from 'vue'

export const setPeriod = (state, payload) => {
	Vue.set(state, 'period', payload)
}

export const setCart = (state, payload) => {
	Vue.set(state, 'cart', payload)
}

export const setCatalog = (state, payload) => {
	Vue.set(state, 'catalog', payload)
}

export const setUnavailable = (state, payload) => {
	Vue.set(state, 'unavailable', payload)
}

export const removeFromCart = (state, payload) => {
	
	if(payload.type == 'extra_product') {
		let product = state.cart.extra_products.findIndex(p => p.id == payload.extra_product_id)
		if(product >= 0) {
			state.cart.extra_products.splice(product, 1)
		}
	} else {
		
		let product = state.cart.products.findIndex(p => p.id ==  payload.product_id)
		if(product >= 0) {
			state.cart.products.splice(product, 1)
		}
	}

}

