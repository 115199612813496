import axios from 'axios'

const api = 'https://api-prod-insumos.profuturocompras.com.mx/api'
// const api = 'https://api-test-compras.profuturocompras.com.mx/api'
// const api = 'http://localho/st:8888/api'

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.interceptors.request.use(function (config) {
	
	
    let token = localStorage.getItem('token') || null
    let auth = {}
    if(token) {
        auth = { Authorization: 'Bearer ' + token }
    }

    // Do something before request is sent
    config.headers.common = {
        ...config.headers.common,
        ...auth
    }
    return config
}, function (error) {
    // Do something with request error
    return Promise.reject(error)
})

axios.interceptors.response.use(function (response) {
    
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
	
    return response;
  }, function (error) {
	  console.log(error)
	  
    if(!error.hasOwnProperty('response')) {
        return Promise.reject(error);
    }
    if(error.response.status == 401) {
        localStorage.removeItem('token')
        sessionStorage.removeItem('user')
        window.location.href = '/login'
    }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
});




const http = {
	base: api,
	auth: {
		base: `${api}`,

		login(payload) {
			return axios.post(`${this.base}/login`, payload)
						.then(({data}) => Promise.resolve(data))
						.catch(error => Promise.reject(error))
		},
		user() {
			return axios.post(`${this.base}/auth/session`)
						.then(({data}) => Promise.resolve(data))
						.catch(error => Promise.reject(error))
		},

		profile(payload) {
			return axios.put(`${this.base}/profile`, payload)
						.then(({data}) => Promise.resolve(data))
						.catch(error => Promise.reject(error))
		}
	},

	reports: {
		base: `${api}/admin/reports`,
		users() {
			return axios.get(`${this.base}/users`)
						.then(({data}) => Promise.resolve(data))
						.catch(error => Promise.reject(error))
		},

	},

	roles: {
		base: `${api}/roles`,
		list(params = {}) {
			return axios.get(`${this.base}`, {params})
						.then(({data}) => Promise.resolve(data))
						.catch(error => Promise.reject(error))
		},
		setPeriod() {
			return axios.get(`${this.base}/period`)
						.then(({data}) => Promise.resolve(data))
						.catch(error => Promise.reject(error))
		}
	},
	suppliers: {
		base: `${api}`,
		list() {
			return axios.get(`${this.base}/suppliers`)
						.then(({data}) => Promise.resolve(data))
						.catch(error => Promise.reject(error))
		},

		downloadProducts() {
			return axios.get(`${this.base}/suppliers/products/download`)
						.then((response) => Promise.resolve(response))
						.catch(error => Promise.reject(error))
		},

		reportOrders(payload) {
			return axios.post(`${this.base}/admin/orders/download`, payload)
						.then((response) => Promise.resolve(response))
						.catch(error => Promise.reject(error))
		},

		form(id, payload) {
			return axios.post(`${this.base}/admin/suppliers/form${id ? `/${id}` : ''}`, payload)
						.then((response) => Promise.resolve(response))
						.catch(error => Promise.reject(error))
		},

		template(id) {
			return axios.get(`${this.base}/admin/suppliers/template${id ? `/${id}` : ''}`)
						.then((response) => Promise.resolve(response))
						.catch(error => Promise.reject(error))
		},
		
		imports(id, payload) {
			return axios.post(`${this.base}/admin/suppliers/import${id ? `/${id}` : ''}`, payload)
						.then((response) => Promise.resolve(response))
						.catch(error => Promise.reject(error))
		},

		detail(id) {
			return axios.get(`${this.base}/admin/suppliers${id ? `/${id}` : ''}`)
						.then((response) => Promise.resolve(response))
						.catch(error => Promise.reject(error))
		},

		approveCatalog(id, payload = null) {
			return axios.post(`${this.base}/admin/suppliers/approve/catalog${id ? `/${id}` : ''}`, payload)
					.then((response) => Promise.resolve(response))
					.catch(error => Promise.reject(error))
		}
	},

	products: {
		base: `${api}`,
		list(params = {}) {
			return axios.get(`${this.base}/admin/products`, {params})
						.then(({data}) => Promise.resolve(data))
						.catch(error => Promise.reject(error))
		},
		extra(payload) {
			return axios.post(`${this.base}/admin/products/extra`, payload)
						.then(({data}) => Promise.resolve(data))
						.catch(error => Promise.reject(error))
		},
		delete(product_id) {
			return axios.delete(`${this.base}/admin/products/${product_id}`)
						.then(({data}) => Promise.resolve(data))
						.catch(error => Promise.reject(error))
		},
		desactive(product_id) {
			return axios.put(`${this.base}/admin/products/${product_id}/desactive`)
						.then(({data}) => Promise.resolve(data))
						.catch(error => Promise.reject(error))
		},
		active(product_id) {
			return axios.put(`${this.base}/admin/products/${product_id}/active`)
						.then(({data}) => Promise.resolve(data))
						.catch(error => Promise.reject(error))
		},


		save(payload) {
			return axios.post(`${this.base}/admin/products/form`, payload)
						.then(({data}) => Promise.resolve(data))
						.catch(error => Promise.reject(error))
		},

		update(id, payload) {
			return axios.post(`${this.base}/admin/products/form/${id}`, payload)
						.then(({data}) => Promise.resolve(data))
						.catch(error => Promise.reject(error))
		},

		report(payload) {
			
			return axios.get(`${this.base}/admin/reports/orders/1?since=${payload.since}&until=${payload.until}${payload.hasOwnProperty('with_extra') ?  `&with_extra=${payload.with_extra}` : ''}${payload.hasOwnProperty('ccosto') ?  `&ccosto=${payload.ccosto}` : ''}&page=${payload.page}&${payload.hasOwnProperty('product_id') ?  `&product_id=${payload.product_id}` : ''}`)
						.then(({data}) => Promise.resolve(data))
						.catch(error => Promise.reject(error))
		}
	
	},
	
	categories: {
		base: `${api}`,
		list(params = {}) {
			return axios.get(`${this.base}/admin/categories`, {params})
						.then(({data}) => Promise.resolve(data))
						.catch(error => Promise.reject(error))
		},
		save(payload) {
			return axios.post(`${this.base}/admin/categories`, payload)
						.then(({data}) => Promise.resolve(data))
						.catch(error => Promise.reject(error))
		},
		
	},

	permissions: {
		base: `${api}`,
		save(role, payload) {
			return axios.put(`${this.base}/admin/access-management/permissions/${role}`, payload)
						.then(({data}) => Promise.resolve(data))
						.catch(error => Promise.reject(error))
		},

		
		exceptions(ccosto, payload) {
			// admin/access-management/exceptions/50016444
			return axios.get(`${this.base}/admin/access-management/exceptions/${ccosto}`)
						.then(({data}) => Promise.resolve(data))
						.catch(error => Promise.reject(error))
		},
		
		saveExceptions(ccosto, payload) {
			// admin/access-management/exceptions/50016444
			return axios.put(`${this.base}/admin/access-management/exceptions/${ccosto}`, payload)
						.then(({data}) => Promise.resolve(data))
						.catch(error => Promise.reject(error))
		}
	},

	ccostos: {
		base: `${api}`,
		list() {
			return axios.get(`${this.base}/ccostos`)
						.then(({data}) => Promise.resolve(data))
						.catch(error => Promise.reject(error))
		}
	},

	employees: {
		base: `${api}`,
		list(page) {
			return axios.get(`${this.base}/employees?paginate=10&page=${page}`)
						.then(({data}) => Promise.resolve(data))
						.catch(error => Promise.reject(error))
		}
	},

	users: {
		base: `${api}`,
		list(page) {
			return axios.get(`${this.base}/admin/users`,  {
							params: {
								page: page || 1
							}
						})
						.then(({data}) => Promise.resolve(data))
						.catch(error => Promise.reject(error))
		},
		
		get(id) {
			return axios.get(`${this.base}/admin/users/${id}`)
						.then(({data}) => Promise.resolve(data))
						.catch(error => Promise.reject(error))
		},
		all() {
			return axios.get(`${this.base}/admin/users/all`)
						.then(({data}) => Promise.resolve(data))
						.catch(error => Promise.reject(error))
		},
		search(query) {
			return axios.get(`${this.base}/admin/users/search?q=${query}`)
						.then(({data}) => Promise.resolve(data))
						.catch(error => Promise.reject(error))
		},
		trash(page) {
			return axios.get(`${this.base}/admin/users/trash`)
						.then(({data}) => Promise.resolve(data))
						.catch(error => Promise.reject(error))
		},
		disable(user_id) {
			return axios.delete(`${this.base}/admin/users/${user_id}`)
						.then(({data}) => Promise.resolve(data))
						.catch(error => Promise.reject(error))
		},
		active(user_id) {
			return axios.get(`${this.base}/admin/users/active/${user_id}`)
						.then(({data}) => Promise.resolve(data))
						.catch(error => Promise.reject(error))
		},
		save(payload) {
			return axios.post(`${this.base}/admin/users`, payload)
						.then(({data}) => Promise.resolve(data))
						.catch(error => Promise.reject(error))
		},
		edit(user_id, payload) {
			return axios.put(`${this.base}/admin/users/${user_id}`, payload)
						.then(({data}) => Promise.resolve(data))
						.catch(error => Promise.reject(error))
		},

		divisionals() {
			return axios.get(`${this.base}/admin/users/divisionals`)
						.then(({data}) => Promise.resolve(data))
						.catch(error => Promise.reject(error))

		}
		
	},

	files: {
		base: `${api}`,
		upload(payload, onUploadProgress) {
			return axios.post(`${this.base}/uploads`, payload, {
							onUploadProgress,
							headers: {
								'Content-Type': 'multipart/form-data'
							}
						})
						.then(({data}) => Promise.resolve(data))
						.catch(error => Promise.reject(error))
		}
	},

	orders: {
		base: `${api}`,
		markIncomplete(order) {
			return axios.put(`${this.base}/orders/${order}/incomplete`)
				.then(({data}) => Promise.resolve(data))
				.catch(error => Promise.reject(error))
		},
		markCanceled(order) {
			return axios.put(`${this.base}/orders/${order}/canceled`)
				.then(({data}) => Promise.resolve(data))
				.catch(error => Promise.reject(error))
		},
		comment(order, payload) {
			return axios.post(`${this.base}/orders/${order}/comment`, payload)
					.then(({data}) => Promise.resolve(data))
					.catch(error => Promise.reject(error))
		},
		comments(order, payload) {
			return axios.get(`${this.base}/orders/comments/${order}`, payload)
					.then(({data}) => Promise.resolve(data))
					.catch(error => Promise.reject(error))
		},
		periods(type) {
			return axios.get(`${this.base}/order-periods/next`, {
				params: {
					order_type: type
				}
			})
			.then(({data}) => Promise.resolve(data))
			.catch(error => Promise.reject(error))
		},

		cart(type) {
			return axios.get(`${this.base}/cart/${type}`,)
			.then(({data}) => Promise.resolve(data))
			.catch(error => Promise.reject(error))
		},

		addToCart(type, payload) {
			return axios.put(`${this.base}/cart/${type}`,payload)
			.then(({data}) => Promise.resolve(data))
			.catch(error => Promise.reject(error))
		},
		removeFromCart(type, payload) {
			return axios.delete(`${this.base}/cart/${type}`, {
					data: payload
				})
				.then(({data}) => Promise.resolve(data))
				.catch(error => Promise.reject(error))
		},

		checkout(type, payload) {
			return axios.post(`${this.base}/cart/${type}/checkout`,payload)
						.then(({data}) => Promise.resolve(data))
						.catch(error => Promise.reject(error))
		},

		list(page = 1) {
			return axios.get(`${this.base}/orders?page=${page}`)
						.then(({data}) => Promise.resolve(data))
						.catch(error => Promise.reject(error))
		},

		setOrderNumbers(payload) {
			debugger
			return axios.post(`${this.base}/admin/orders/numbers`, payload)
						.then(({data}) => Promise.resolve(data))
						.catch(error => Promise.reject(error))
		},

		periodsList(params) {
			return axios.get(`${this.base}/admin/order-periods`, {
				params
			})
			.then(({data}) => Promise.resolve(data))
			.catch(error => Promise.reject(error))
		},
		periodsCreate(payload) {
			return axios.post(`${this.base}/admin/order-periods`, payload)
			.then(({data}) => Promise.resolve(data))
			.catch(error => Promise.reject(error))
		},
		periodsUpdate(period_id, payload) {
			return axios.put(`${this.base}/admin/order-periods/${period_id}`, payload)
			.then(({data}) => Promise.resolve(data))
			.catch(error => Promise.reject(error))
		},
		deletePeriod(period_id) {
			return axios.delete(`${this.base}/admin/order-periods/${period_id}`)
			.then(({data}) => Promise.resolve(data))
			.catch(error => Promise.reject(error))
		},

		downloadProducts(payload) {
			return axios.post(`${this.base}/admin/orders/products/download`, payload)
						.then((response) => Promise.resolve(response))
						.catch(error => Promise.reject(error))
		},
		find(id) {
			return axios.get(`${this.base}/orders/${id}`)
						.then((response) => Promise.resolve(response))
						.catch(error => Promise.reject(error))
		},
		survey(id, payload) {
			// orders/save-survey/17
			return axios.post(`${this.base}/orders/save-survey/${id}`, payload)
						.then((response) => Promise.resolve(response))
						.catch(error => Promise.reject(error))
		},

		pedding() {
			return axios.get(`${this.base}/orders/pending`)
						.then((response) => Promise.resolve(response))
						.catch(error => Promise.reject(error))
		}
	},

	catalog: {
		base: `${api}`,
		list(type) {
			return axios.get(`${this.base}/catalog`)
			.then(({data}) => Promise.resolve(data))
			.catch(error => Promise.reject(error))
		},
		unavailible(type) {
			return axios.get(`${this.base}/catalog/unavailable`)
			.then(({data}) => Promise.resolve(data))
			.catch(error => Promise.reject(error))
		},
	}

}


export default http